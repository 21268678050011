import { useEffect, useState } from 'react';
// import editIcon from '/src/assets/edit.svg';
import deleteIcon from '/src/assets/delete.svg';
import upArrow from '../../assets/sort-arrow.svg';
import { useNavigate } from 'react-router-dom';
import { TargetsData } from '../../services/types';
import { generatePageNumbers } from '../../utils/helpers.ts';

interface TargetsTableProps {
    data: TargetsData[];
}

function TargetsTable({ data }: TargetsTableProps) {
    const navigate = useNavigate();
    const [targetsData, setTargetsData] = useState<TargetsData[]>([]);
    const [currentPage, setCurrentPage] = useState(1);
    const [sortConfig, setSortConfig] = useState<{ key: string, direction: 'asc' | 'desc' | null }>({ key: '', direction: null });
    const rowsPerPage = 10;

    useEffect(() => {
        if (Array.isArray(data)) {
            setTargetsData(data);
            setSortConfig({ key: 'partner_name', direction: 'asc' });
        } else {
            console.error('interactionData is not an array:', data);
        }
    }, [data]);

    const handleEditClick = (id: number) => {
        const target = data.find(item => item.id === id);
        console.log('target:', target);
        navigate(`/commerce-targets/edit/${id}`, {state: {target}});
    };

    const handleDeleteClick = async (id: number) => {
        const target = data.find(item => item.id === id);
        const partnerId = target?.partner_id;

        navigate(`/commerce-targets/delete/${id}`, { state: {id, partnerId } });
    };

    const handlePageClick = (page: number) => {
        setCurrentPage(page);
    };

    // Handle sorting
    const handleSortClick = (key: string) => {
        let direction: 'asc' | 'desc' = 'asc';
        if (sortConfig.key === key && sortConfig.direction === 'asc') {
            direction = 'desc';
        }
        setSortConfig({ key, direction });
    };

    // Sort data based on sortConfig
    useEffect(() => {
        if (sortConfig.key) {
            const sortedData = [...data].sort((a, b) => {
                let aVal, bVal;

                aVal = a[sortConfig.key as keyof TargetsData];
                bVal = b[sortConfig.key as keyof TargetsData];
    
                // Ensure that empty or null values are pushed to the end
                if (aVal === null && bVal === null) return 0;
                if (aVal === null) return sortConfig.direction === 'asc' ? 1 : -1;
                if (bVal === null) return sortConfig.direction === 'asc' ? -1 : 1;

                // Standard comparison logic
                if (aVal < bVal) return sortConfig.direction === 'asc' ? -1 : 1;
                if (aVal > bVal) return sortConfig.direction === 'asc' ? 1 : -1;
                return 0;
            });
            setTargetsData(sortedData);
        }
    }, [sortConfig, data]);

    // Pagination Logic
    const totalPages = Math.ceil(targetsData.length / rowsPerPage);
    const pageNumbersToShow = generatePageNumbers(totalPages, currentPage);

    const startIndex = (currentPage - 1) * rowsPerPage;
    const endIndex = Math.min(startIndex + rowsPerPage, targetsData.length);
    const paginatedData = targetsData.slice(startIndex, endIndex);

    return (
        <div>
            <div className="overflow-x-auto border border-solid border-color-at-white rounded-lg">
                <table className="min-w-full">
                    <thead className="text-white bg-spanish-gray rounded-t-lg">
                        <tr>
                            <th 
                                className="border-b border-titanium-white text-xs font-bold px-4 py-2 text-left min-w-72 max-w-72"
                                onClick={() => handleSortClick('partner_name')}
                            >
                                <div className='flex items-center hover:cursor-pointer'>
                                ADO
                                {sortConfig.key === 'partner_name' && (
                                    <img 
                                        src={upArrow} 
                                        alt='sort arrow' 
                                        className={`transform ${sortConfig.direction === 'desc' ? 'rotate-180' : ''}`} 
                                    />
                                )}
                                </div>
                            </th>
                            <th className="border-b border-color-at-white px-4 py-2 text-left text-xs font-bold w-28">Year</th>
                            <th 
                                className="border-b border-color-at-white px-4 py-2 text-end text-xs font-bold w-28"
                                onClick={() => handleSortClick('bre')}
                            >
                                <div className='flex items-center justify-end hover:cursor-pointer'>
                                {sortConfig.key === 'bre' && (
                                    <img 
                                        src={upArrow} 
                                        alt='sort arrow' 
                                        className={`transform ${sortConfig.direction === 'desc' ? 'rotate-180' : ''}`} 
                                    />
                                )}
                                BRE
                                </div>
                            </th>
                            <th 
                                className="border-b border-color-at-white px-4 py-2 text-end text-xs text-nowrap font-bold"
                                onClick={() => handleSortClick('startup')}
                            >
                                <div className='flex items-center justify-end hover:cursor-pointer'>
                                {sortConfig.key === 'startup' && (
                                    <img 
                                        src={upArrow} 
                                        alt='sort arrow' 
                                        className={`transform ${sortConfig.direction === 'desc' ? 'rotate-180' : ''}`} 
                                    />
                                )}
                                Startup Support
                                </div>
                            </th>
                            <th 
                                className="border-b border-color-at-white px-4 py-2 text-end text-xs font-bold"
                                onClick={() => handleSortClick('training')}
                            >
                                <div className='flex items-center justify-end hover:cursor-pointer'>
                                {sortConfig.key === 'training' && (
                                    <img 
                                        src={upArrow} 
                                        alt='sort arrow' 
                                        className={`transform ${sortConfig.direction === 'desc' ? 'rotate-180' : ''}`} 
                                    />
                                )}
                                Training
                                </div>
                            </th>
                            <th 
                                className="border-b border-color-at-white px-4 py-2 text-end text-xs font-bold"
                                onClick={() => handleSortClick('recruitment')}
                            >
                                <div className='flex items-center justify-end hover:cursor-pointer'>
                                {sortConfig.key === 'recruitment' && (
                                    <img 
                                        src={upArrow} 
                                        alt='sort arrow' 
                                        className={`transform ${sortConfig.direction === 'desc' ? 'rotate-180' : ''}`} 
                                    />
                                )}
                                Recruitment
                                </div>
                            </th>
                            <th 
                                className="border-b border-color-at-white px-4 py-2 text-end text-xs text-nowrap font-bold"
                                onClick={() => handleSortClick('export')}
                            >
                                <div className='flex items-center justify-end hover:cursor-pointer'>
                                {sortConfig.key === 'export' && (
                                    <img 
                                        src={upArrow} 
                                        alt='sort arrow' 
                                        className={`transform ${sortConfig.direction === 'desc' ? 'rotate-180' : ''}`} 
                                    />
                                )}
                                Export Assistance
                                </div>
                            </th>
                            <th 
                                className="border-b border-color-at-white px-4 py-2 text-end text-xs text-nowrap font-bold"
                                onClick={() => handleSortClick('marketing_plan_required')}
                            >
                                <div className='flex items-center justify-end hover:cursor-pointer'>
                                {sortConfig.key === 'marketing_plan_required' && (
                                    <img 
                                        src={upArrow} 
                                        alt='sort arrow' 
                                        className={`transform ${sortConfig.direction === 'desc' ? 'rotate-180' : ''}`} 
                                    />
                                )}
                                Marketing Plan
                                </div>
                            </th>
                            <th className="border-b border-color-at-white px-4 py-2 text-center text-xs font-bold">Manage</th>
                        </tr>
                    </thead>
                    <tbody>
                        {paginatedData.map((row) => (
                            <tr key={row.id} onClick={(e) => { e.stopPropagation(); handleEditClick(row.id) }} className="border-b border-solid border-at-white last:border-none h-10 hover:text-black hover:cursor-pointer hover:bg-at-white">
                                <td className="px-4 py-2 text-gotham-gray text-sm text-left min-w-72 max-w-72">{row.partner_name}</td>
                                <td className="px-4 py-2 text-gotham-gray text-sm text-left">{row.year}</td>
                                <td className="px-4 py-2 text-gotham-gray text-sm text-end">{row.bre}</td>
                                <td className="px-4 py-2 text-gotham-gray text-sm text-end">{row.startup}</td>
                                <td className="px-4 py-2 text-gotham-gray text-sm text-end">{row.training}</td>
                                <td className="px-4 py-2 text-gotham-gray text-sm text-end">{row.recruitment}</td>
                                <td className="px-4 py-2 text-gotham-gray text-sm text-end">{row.export}</td>
                                <td className="px-4 py-2 text-gotham-gray text-sm text-end">{row.marketing_plan_required ? 'required' : 'optional'}</td>
                                <td className="w-24 py-2">
                                    <div className='w-24 flex items-center justify-center'>
                                        {/* <button onClick={(e) => { e.stopPropagation(); handleEditClick(row.id) }} className="mr-3">
                                            <img className="border border-solid border-button-blue h-6 w-6 p-1 rounded-md" src={editIcon} alt="Edit" />
                                        </button> */}
                                        <button onClick={(e) => { e.stopPropagation(); handleDeleteClick(row.id) }}
                                            className="flex items-center">
                                            <img className="border border-solid border-button-blue h-6 w-6 p-1 rounded-md" src={deleteIcon} alt="Delete" />
                                        </button>
                                    </div>
                                </td>
                            </tr>
                        ))}
                    </tbody>
                </table>
            </div>
            {pageNumbersToShow.length > 1 && 
            <div className="flex justify-center mt-3">
                <div className="border border-solid border-at-white rounded-md flex">
                    <button
                        className="text-gotham-gray font-bold text-xs border-r border-solid border-at-white px-2 py-1"
                        onClick={() => handlePageClick(Math.max(currentPage - 1, 1))}
                        disabled={currentPage === 1}
                    >
                        « Previous
                    </button>
                    {pageNumbersToShow.map((pageNumber, index) => (
                        <button
                            key={index}
                            className={`text-gotham-gray font-bold text-xs border-r border-solid border-at-white px-3 py-1 ${pageNumber === currentPage ? 'bg-titanium-white' : ''}`}
                            onClick={() => handlePageClick(typeof pageNumber === 'number' ? pageNumber : currentPage)}
                        >
                            {pageNumber}
                        </button>
                    ))}
                    <button
                        className="text-gotham-gray font-bold text-xs px-2 py-1"
                        onClick={() => handlePageClick(Math.min(currentPage + 1, totalPages))}
                        disabled={currentPage === totalPages}
                    >
                        Next »
                    </button>
                </div>
            </div>
            }
            
        </div>
    );
}

export default TargetsTable;