import { createContext, useContext, useState, useEffect, useRef, useCallback } from 'react';
import { OrganizationContextType, AdminProviderProps } from '../services/types';
import AdoDataContext from './AdoDataContext';
import { useAuth0 } from '@auth0/auth0-react';
import { useLocation } from 'react-router-dom';
import * as Sentry from '@sentry/react';

const defaultOrganizationContext: OrganizationContextType = {
    partnerId: null,
    setPartnerId: () => {},
    partnerName: "",
    setPartnerName: () => {},
    admin: false,
    setAdmin: () => {},
};

const OrganizationContext = createContext<OrganizationContextType>(defaultOrganizationContext);

export const OrganizationProvider = ({ children }: AdminProviderProps) => {
    const [partnerId, setPartnerId] = useState<number | null>(null);
    const [partnerName, setPartnerName] = useState<string>("");
    const [admin, setAdmin] = useState<boolean>(false);
    const [loading, setLoading] = useState<boolean>(true);
    const { partnerData } = useContext(AdoDataContext);
    const { user, isAuthenticated, isLoading } = useAuth0();
    const tokenHandled = useRef<boolean>(false);
    const pendingToken = useRef<string | null>(null);
    const retryCount = useRef<number>(0); // Track retry attempts
    const maxRetries = 3; // Set a maximum number of retry attempts

    const isIframe = window.self !== window.top;
    const location = useLocation();

    console.log('admin:', admin);

    // Utility function to set partnerId and partnerName, and store them in localStorage
    const setPartnerData = useCallback((id: number, name: string) => {
        setPartnerId(id);
        setPartnerName(name);
        localStorage.setItem('partnerId', id.toString());
        localStorage.setItem('partnerName', name);
    }, []);

    // handleToken - process token and use returned data to set partnerId and partnerName
    const handleToken = useCallback(async (token: string) => {
        try {
            console.log('handle token called...');
            const response = await fetch(`${import.meta.env.VITE_COMMERCE_BACKEND_URL}/api/gettoken`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({ token }),
            });

            const responseData = await response.json();

            if (!response.ok) {
                throw new Error(responseData.error || 'Failed to retrieve token');
            }

            const { commerce_ado_code } = responseData;
            if (commerce_ado_code && partnerData.length > 0) {
                if (commerce_ado_code === 'All_ADOs') {
                    setPartnerData(-1, 'Admin');
                    tokenHandled.current = true;
                } else if (commerce_ado_code === 'cai_admin') {
                    setPartnerData(-2, 'CAI-Admin');
                    setAdmin(true);
                    tokenHandled.current = true;
                } else {
                    const matchedPartner = partnerData.find(
                        partner => partner.short_name.trim() === commerce_ado_code.trim()
                    );

                    if (matchedPartner) {
                        setPartnerData(matchedPartner.id, matchedPartner.name);
                        tokenHandled.current = true;
                    } else {
                        // Retry the request if no matching partner is found
                        retryTokenFetch(token);
                    }
                }
            }
        } catch (error) {
            Sentry.captureException(error);
            console.error('Error sending data to backend:', error);

            // Retry the request on failure
            retryTokenFetch(token);
        } finally {
            setLoading(false); // Set loading to false when done
        }
    }, [partnerData, setPartnerData]);

    // Retry fetching token if conditions are met
    const retryTokenFetch = (token: string) => {
        if (retryCount.current < maxRetries) {
            retryCount.current += 1;
            console.warn(`Retrying to fetch token... Attempt: ${retryCount.current}`);
            handleToken(token);
        } else {
            console.error('Max retry attempts reached. Could not fetch partner data.');
        }
    };

    // Handle receiving messages in the iframe context
    const receiveMessage = useCallback(async (event: MessageEvent) => {
        event.preventDefault();
        if (event.data.message === "update" && !tokenHandled.current) {
            const token = event.data.params?.token || null;
            pendingToken.current = token;

            // If token is received and partnerData is available, handle the token immediately
            if (token && partnerData.length > 0) {
                await handleToken(token);
                pendingToken.current = null; // Set to null only after `handleToken` completes successfully
            }
        }
    }, [partnerData, handleToken]);

    // Main useEffect for handling iframe and URL parameter data
    useEffect(() => {
        // We need to first check if the partner id is passed through url params (in the context of Iframed-Edit page)
        const queryParams = new URLSearchParams(location.search);
        const queryPartnerId = queryParams.get('partnerId');

        if (queryPartnerId) {
            setPartnerData(parseInt(queryPartnerId, 10), '');
            setLoading(false);
            return;
        }

        if (partnerId) {
            // If a partnerId is already set (from localStorage), don't wait for the parent app
            setLoading(false);
            return;
        }

        if (isIframe) {
            //Ask parent for token
            window.parent.postMessage({ message: "auth" }, "*");

            window.addEventListener("message", receiveMessage, false);
            return () => {
                window.removeEventListener("message", receiveMessage);
            };
        } else if (!isLoading && isAuthenticated && partnerData.length > 0 && user?.commerce_ado_code) {
            if (user.commerce_ado_code === 'All_ADOs') {
                setPartnerData(-1, 'Admin');
            } else if (user.commerce_ado_code === 'cai_admin') {
                setPartnerData(-2, 'CAI-Admin');
                setAdmin(true);
            } else {
                const matchedPartner = partnerData.find(
                    partner => partner.short_name.trim() === user.commerce_ado_code.trim()
                );

                if (matchedPartner) {
                    setPartnerData(matchedPartner.id, matchedPartner.name);
                }
            }
            setLoading(false); // Set loading to false when done
        }
    }, [isLoading, isAuthenticated, user, partnerData, isIframe, receiveMessage, location.search, setPartnerData]);

    // Retry pending token processing if partner data becomes available
    useEffect(() => {
        const processPendingToken = async () => {
            if (pendingToken.current && partnerData.length > 0) {
                await handleToken(pendingToken.current);
                pendingToken.current = null; // Set to null only after `handleToken` completes successfully.
            }
        };
    
        processPendingToken();
    }, [partnerData, handleToken]);

    return (
        <OrganizationContext.Provider value={{ partnerId, setPartnerId, partnerName, setPartnerName, admin, setAdmin }}>
            {!loading ? children : (
                <div className="loading-container">
                    <div className="loading-spinner"></div>
                </div>
            )}
        </OrganizationContext.Provider>
    );
};

export const useOrganizationContext = () => useContext(OrganizationContext);