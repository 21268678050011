import React, { useState, useEffect } from 'react';
import Select from 'react-select';

// Define the type for the filter options
interface FilterOption {
    value: string;
    label: string;
}

interface UploadErrorSortProps {
    filterByMissingFields: (filter: string) => void;
    currentFilter: string;
}

const UploadErrorSort: React.FC<UploadErrorSortProps> = ({ filterByMissingFields, currentFilter }) => {
    // Define the options for the dropdown
    const filterOptions: FilterOption[] = [
        { value: 'all', label: 'All Records' },
        { value: 'missing', label: 'Records with Errors' },
    ];

    const [selectedFilter, setSelectedFilter] = useState<string>(currentFilter);

    useEffect(() => {
        setSelectedFilter(currentFilter);
    }, [currentFilter]);

    const handleSelectChange = (selectedOption: { value: string; label: string } | null) => {
        const selectedValue = selectedOption ? selectedOption.value : 'all';
        setSelectedFilter(selectedValue);
        filterByMissingFields(selectedValue);
    };

    return (
        <div className='sm:mt-0 mt-2'>
            <Select
                options={filterOptions}
                value={filterOptions.find(option => option.value === selectedFilter)}
                onChange={handleSelectChange}
                placeholder="Filter"
                isClearable={false}
                isSearchable={false}
                styles={{
                    control: (base) => ({
                        ...base,
                        height: '2rem',
                        minHeight: '2rem',
                        width: '10rem',
                        borderColor: '#E5E7EB',
                        borderRadius: '4px',
                        marginRight: '1rem',
                        boxShadow: 'none',
                        cursor: 'pointer',
                    }),
                    valueContainer: (base) => ({
                        ...base,
                        padding: '0 0.5rem',
                        minHeight: '2rem',
                        height: '2rem',
                    }),
                    placeholder: (base) => ({
                        ...base,
                        color: '#3A3C41', // 'cai-color-dashboard'
                        fontSize: '0.875rem',
                        fontWeight: 'bold',
                    }),
                    singleValue: (base) => ({
                        ...base,
                        color: '#3A3C41',
                        fontSize: '0.875rem',
                        fontWeight: 'bold'
                    }),
                    dropdownIndicator: (base) => ({
                        ...base,
                        padding: '0',
                    }),
                    clearIndicator: (base) => ({
                        ...base,
                        padding: '0',
                    }),
                    indicatorsContainer: (base) => ({
                        ...base,
                        padding: '0 0.25rem',
                    }),
                    // indicatorSeparator: (base) => ({
                    //     ...base,
                    //     display: 'none'
                    // }),
                    option: (base, state) => ({
                        ...base,
                        fontSize: '0.875rem',
                        backgroundColor: state.isSelected ? '#626262' : state.isFocused ? '#CCCCCC' : 'white',
                        borderRadius: '4px',
                        borderBottom: '1px solid #E2E8F0',
                        color: state.isSelected ? 'white' : '#3A3C41',
                        '&:hover': {
                            backgroundColor: '#CCCCCC', // cai-lightGray
                        },
                    }),
                    menu: (base) => ({
                        ...base,
                        position: 'absolute',
                        left: '0',
                        top: '1.5rem',
                        width: '8rem',
                        zIndex: 9999,
                        boxShadow: '0 0 10px rgba(0, 0, 0, 0.1)',
                    }),
                    menuList: (base) => ({
                        ...base,
                        padding: '4px',
                        maxHeight: '200px', // Adjust height as needed
                        overflowY: 'auto',
                        '::-webkit-scrollbar': {
                            width: '8px',
                        },
                        '::-webkit-scrollbar-track': {
                            background: '#ffffff',
                            borderRadius: '4px',
                        },
                        '::-webkit-scrollbar-thumb': {
                            background: '#E8E8E8',
                            borderRadius: '4px',
                            height: '50px',
                            padding: '10px'
                        },
                    }),
                }}
            />
        </div>
    );
};

export default UploadErrorSort;