import { useContext, useEffect, useState } from 'react';
import SearchInput from "../../components/Filters/SearchInput";
import ReportingDropdown from "../../components/Navigation/BreDropdown";
import SelectADO from "../../components/Filters/SelectADO";
import FiscalYear from "../../components/Filters/FiscalYear";
import NewTargetButton from "../../components/Targets/NewTargetButton";
import TargetsTable from '../../components/Targets/TargetsTable';
import { calculateAvailableYears, calculateFiscalYear } from '../../utils/helpers';
import TargetsDataContext from '../../context/TargetDataContext';
import useFilteredData from '../../hooks/useFilteredData';
import Expand from '../../components/Navigation/Expand';
import { useIframeContext } from '../../context/IframeContext';
import HomeButton from '../../components/Navigation/HomeButton';

function Targets() {
    const { isIframe } = useIframeContext();
    const [ado, setAdo] = useState<string>('');
    const [year, setYear] = useState<string>('');
    const [searchTerm, setSearchTerm] = useState<string>('');
    const [sortType, setSortType] = useState<string>('');
    const { targets } = useContext(TargetsDataContext);
    const availableYears = calculateAvailableYears();
    const currFisYear = calculateFiscalYear(year); 

    const filterState = {
        ado,
        year,
        searchTerm,
    };
    
    // Save the filter state to localStorage when filters change
    const saveFiltersToLocalStorage = () => {
        localStorage.setItem('targetFilters', JSON.stringify(filterState));
    };

    // Load filters from localStorage when the component mounts
    useEffect(() => {
        const savedFilters = JSON.parse(localStorage.getItem('targetFilters') || '{}');
        setYear(savedFilters.year || currFisYear.toString());
        setSearchTerm('');
        setAdo(savedFilters.ado || '');
        setSortType(savedFilters.sortType || '');
    }, []);

    const { filteredTargets } = useFilteredData(targets, undefined, undefined, undefined, searchTerm, sortType, year, undefined, ado);
    
    const filterByYear = (year: string) => {
        setYear(year);
        filterState.year = year;
        saveFiltersToLocalStorage();
    };
    
    const filterByAdo = (partner_id: string) => {
        setAdo(partner_id);
        filterState.ado = partner_id;
        saveFiltersToLocalStorage();
    };
    
    const handleSearch = (searchTerm: string) => {
        setSearchTerm(searchTerm);
        filterState.searchTerm = searchTerm;
        saveFiltersToLocalStorage();
    };
    
    return ( 
        <>
            <ReportingDropdown/>
            <div className="px-4 py-0 sm:py-4 md:py-4 lg:py-4">
                <div className="mx-2 my-2 relative">
                    <div className="flex flex-wrap items-end w-full justify-start">
                        <HomeButton/>
                        {isIframe && 
                        <div className="flex sm:mt-0 mt-1 mb-1 absolute md:relative top-0 right-0 md:top-auto md:right-auto md:ml-auto transform md:transform-none">
                            <Expand/>
                        </div>
                        }
                    </div>
                </div>
                <div className="mx-2 my-4 flex flex-wrap relative">
                    <div className="w-full sm:w-auto">
                        <SearchInput onSearch={handleSearch} isLarge={true}/>
                    </div>
                    <SelectADO currAdo={ado} filterByAdo={filterByAdo} isSummary={true}/>
                    <FiscalYear filterByYear={filterByYear} availableYears={availableYears} currYear={year}/>
                    <NewTargetButton/>
                </div>
                {filteredTargets.length > 0 && 
                <div className="mx-2 my-4">
                    <TargetsTable 
                        data={filteredTargets}
                    />    
                </div>
                }
            </div>
        </>
    );
}

export default Targets;
