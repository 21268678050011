import { createContext, useState, useEffect, ReactNode } from 'react';
import axios from 'axios';
import { PartnerData } from '../services/types';
import * as Sentry from '@sentry/react';

interface AdoDataContextType {
    partnerData: PartnerData[];
    adoNames: { value: string, label: string }[];
    fetchAdoNames: () => void;
}


const AdoDataContext = createContext<AdoDataContextType>({
    partnerData: [],
    adoNames: [],
    fetchAdoNames: () => {},
});

interface AdminProviderProps {
    children: ReactNode;
}

export const AdoDataProvider = ({ children }: AdminProviderProps) => {
    const [partnerData, setPartnerData] = useState<PartnerData[]>([]);
    const [adoNames, setAdoNames] = useState<{ value: string, label: string }[]>([]);
    const apiUrl = `${import.meta.env.VITE_COMMERCE_BACKEND_URL}/api/getpartners`;

    const fetchAdoNames = async () => {
        try {
            const response = await axios.get(apiUrl);
            if (response.status === 200) {
                const data: PartnerData[] = response.data.data;
                const names = Array.from(new Set(data.map((item: { name: string }) => item.name.trim())));
                names.sort();
                const options = names.map(name => ({ value: name, label: name }));
                
                setPartnerData(data);
                setAdoNames(options);
            } else {
                // Log the error response to Sentry
                Sentry.captureMessage(`Error fetching ADO names: ${response.data.message}`, 'warning');
                console.error('Error fetching ADO names:', response.data);
            }
        } catch (error) {
            // Log the caught error to Sentry
            Sentry.captureException(error);
            console.error('Error fetching ADO names:', error);
        }
    };

    useEffect(() => {
        fetchAdoNames();
    }, []);

    return (
        <AdoDataContext.Provider value={{ partnerData, adoNames, fetchAdoNames }}>
            {children}
        </AdoDataContext.Provider>
    );
};

export default AdoDataContext;
