import { useNavigate } from "react-router-dom";
import homeSvg from '../../assets/home.svg';

function HomeButton() {
    const navigate = useNavigate();
    const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
        event.preventDefault();
        navigate('/');
    };

    return ( 
        <span onClick={handleClick} className="flex items-center justify-center lg:mt-0 mt-2 mr-4 px-4 bg-petrol border border-solid border-petrol rounded-md focus:outline-none focus:border-blue-500 hover:cursor-pointer hover:bg-lime-green hover:border-lime-green transition duration-300">
            <img src={homeSvg} alt="Left arrow" className="mr-1 w-4 h-4"/>
            <button
                id="home"
                className="w-full text-white font-bold text-sm h-[36px] appearance-none text-center"
            > Home
            </button>
        </span>
    );
}

export default HomeButton;