import expandIcon from '../../assets/frame-expand.svg';

function Expand() {
    const commerceOrigin = '*';
    const handleExpandClick = (e: React.MouseEvent<HTMLButtonElement>) => {
        e.preventDefault();

        // Send message to expand
        window.parent.postMessage({ message: "expand" }, commerceOrigin);
        console.log('expand message sent');
    }
    
    return ( 
        <button onClick={handleExpandClick} className="mt-0">
            <img src={expandIcon} alt="expand icon"></img>
        </button>   
    );
}

export default Expand;