import { useRef } from "react";
import { Chart } from "chart.js/auto";
import useDeepCompareEffect from "../../hooks/useDeepCompareEffect";
import ChartDataLabels from 'chartjs-plugin-datalabels';
import { backgroundColorPlugin, noDataPlugin } from "../../utils/chartPlugins";

interface Classification {
    id: number;
    name: string;
    value: string;
}

interface InteractionSummaryData {
    total_interactions: string;
    classifications: Classification[];
}

interface InteractionsByCategoryChartProps {
    interactionSummary: InteractionSummaryData | null;
}

function InteractionsByCategoryChart({ interactionSummary }: InteractionsByCategoryChartProps) {
    // Register the plugins to all charts:
    Chart.register(ChartDataLabels, noDataPlugin, backgroundColorPlugin);
    const chartRef = useRef<HTMLCanvasElement>(null);
    const chartInstance = useRef<Chart<"bar"> | null>(null);

    const total_interactions = interactionSummary?.total_interactions || '0';

  useDeepCompareEffect(() => {
    if (!interactionSummary) return;

    const { classifications } = interactionSummary;

    // Initialize classification totals
    const classificationTotals: { [key: string]: number } = {
        'BIPOC Owned': 0,
        'Woman Owned': 0,
        'Veteran Owned': 0,
        'Tribal Owned': 0,
        'WA Small Business': 0,
        'OWMBE Certified': 0,
        'Other / Unclassified': 0
    };

    // X axis labels (using nested structure to display labels on two lines)
    const testLabel = [['BIPOC', 'Owned'], ['Woman', 'Owned'], ['Veteran', 'Owned'], ['Tribal', 'Owned'], ['WA Small', 'Business'], ['OWMBE', 'Certified'], ['Other /', 'Unclassified']];

    let classifiedSum = 0;

    // Update classification totals based on the classifications array
    classifications.forEach(classification => {
        const count = parseInt(classification.value);
        classifiedSum += count;

        switch (classification.name) {
            case 'BIPOC-owned Business':
                classificationTotals['BIPOC Owned'] = count;
                break;
            case 'Woman-owned Business':
                classificationTotals['Woman Owned'] = count;
                break;
            case 'Veteran-owned Business':
                classificationTotals['Veteran Owned'] = count;
                break;
            case 'Tribal-owned Business':
                classificationTotals['Tribal Owned'] = count;
                break;
            case 'Washington Small Business':
                classificationTotals['WA Small Business'] = count;
                break;
            case 'Washington OWMBE Certified':
                classificationTotals['OWMBE Certified'] = count;
                break;
            default:
                classificationTotals['Other / Unclassified'] += count;
        }
    });

    // Calculate "Other/Unclassified" classifications
    classificationTotals['Other / Unclassified'] = Number(total_interactions) - classifiedSum;

    console.log(classificationTotals);

    // Prepare data for Chart.js
    const labels = Object.keys(classificationTotals);
    const data = labels.map(label => (classificationTotals[label] / parseInt(total_interactions)) * 100);

    // Chart.js configuration
    const ctx = chartRef.current?.getContext("2d");
    if (ctx) {

        // Destroy existing chart instance if it exists
        if (chartInstance.current) {
            chartInstance.current.destroy();
        }

        chartInstance.current = new Chart(ctx, {
            type: "bar",
            data: {
                labels: testLabel,
                datasets: [
                    {
                        label: "% of Interactions by Category",
                        data: data,
                        backgroundColor: '#4D4D4D',
                        borderRadius: 3,
                    },
                ],
            },
            options: {
                responsive: true,
                plugins: {
                    legend: {
                        display: false,
                    },
                    tooltip: {
                        callbacks: {
                            // Ensure tooltip header (title) is one line and remove comma
                            title: function(tooltipItems) {
                            let title = tooltipItems[0].label || '';
            
                            title = title.replace(/,/g, ' ');
                            return title;
                            },
                            // Display number of interactions instead in tooltip
                            label: function(tooltipItem) {
                                let label = tooltipItem.label;
                                // Replace the comma with a space to match the format used in classificationTotals
                                label = label.replace(/,/g, ' ');
                                const interactionCount = classificationTotals[label] || 0; // Ensure we get a number even if undefined
                                return `${label}: ${interactionCount} Interactions`;
                            },
                        },
                        backgroundColor: "#FFFFFF",
                        titleColor: "#4D4D4D", 
                        bodyColor: "#4D4D4D",
                        borderColor: "#4D4D4D",
                        borderWidth: 1,
                        titleFont: {
                            family: 'Gotham'
                        },
                        bodyFont: {
                            family: 'Gotham'
                        }
                    },
                    datalabels: {
                        color: '#4D4D4D',
                        anchor: 'end',
                        align: 'start',
                        formatter:(value) => value ? `${value.toFixed(0)}%` : null,
                        font: {
                            family: 'Gotham',
                            size: 10,
                            weight: 'normal'
                        },
                        offset: -20,
                    },
                },
                scales: {
                    x: {
                        grid: {
                            display: false,
                            drawTicks: true,
                        },
                        border: {
                            display: false
                        },
                        ticks: {
                            color: '#4D4D4D',
                            autoSkip: false,
                            minRotation: 0,
                            maxRotation: 0,
                            font: {
                                family: 'Gotham',
                                size: 10,
                                weight: 'normal'
                            },
                        },
                    },
                    y: {
                        beginAtZero: true,
                        ticks: {
                            color: '#4D4D4D',
                            autoSkip: false,
                            precision: 0,
                            callback: function(value) {
                                return value + "%";
                            },
                            font: {
                                family: 'Gotham',
                                size: 10,
                                weight: 'normal'
                            },
                            padding: 10
                        },
                        grid: {
                            tickBorderDash: [2, 4]
                        },
                        border: {
                            display: false,
                            dash: [2,4]
                        }
                    },
                },
            },
            plugins: [noDataPlugin, backgroundColorPlugin]
        });
    }

  }, [interactionSummary]);

    return ( 
        <div className="chart-container w-full h-84 min-w-lg max-w-lg md:w-1/2 border border-solid border-lightGray rounded-md appearance-none bg-at-white">
            <h2 className="text-white text-base font-bold py-2 px-3 bg-gotham-gray rounded-t-md">% of Interactions by Classification</h2>
            <div className="flex items-center border-t">
                <h2 className="text-gotham-gray text-sm font-bold my-2 mx-8 px-3 ">Total Interactions: {total_interactions}</h2>
            </div>
            <canvas ref={chartRef} className="max-h-56 max-w-md mx-8 mt-2"/>
        </div>
     );
}

export default InteractionsByCategoryChart;