// noDataPlugin - plugin to display a 'no data available' message inside of a chart 
export const noDataPlugin = {
    id: 'noData',
    afterDatasetsDraw: (chart: any) => {
        const { ctx, data, chartArea } = chart;
        const { top, left, width, height } = chartArea;

        ctx.save();
        // Check if the datasets array is empty or if all data values are 0 or NaN (charts using percentages / 0)
        if (
            data.datasets.length === 0 || 
            data.datasets[0].data.every((value: any) => value === 0 || isNaN(value))
        ) {
            // style the no data available container
            ctx.fillStyle = '#E5E7EB';
            ctx.fillRect(left, top, width, height);

            ctx.font = 'bold 12px Inter, system-ui, Avenir, Helvetica, Arial, sans-serif';
            ctx.fillStyle = 'black';
            ctx.textAlign = 'center';
            ctx.textBaseline = 'middle';
            ctx.fillText('No Data Available', left + width / 2, top + height / 2);
        }
        ctx.restore();
    }
};

// Set background color for charts
export const backgroundColorPlugin = {
    id: 'customCanvasBackgroundColor',
    beforeDraw: (chart: any) => {
        const ctx = chart.ctx;
        ctx.save();
        ctx.fillStyle = '#F0F0F0';
        ctx.fillRect(0, 0, chart.width, chart.height);
        ctx.restore();
    }
};