import { useState, useEffect } from 'react';
import Select from 'react-select';
import { InitiativeData } from '../../services/types';
import { useOrganizationContext } from '../../context/OrganizationContext';
import { sanitizeString } from '../../utils/helpers';
import { useAdminContext } from '../../context/AdminContext';

interface InitiativeSortProps {
    filterByInitiative: (initiative: string) => void;
    initiatives: InitiativeData[];
    currInitiative: string;
    ado: string;
    // year: string;
}

function InitiativeSort({ filterByInitiative, initiatives, currInitiative, ado }: InitiativeSortProps) {
    const { partnerName } = useOrganizationContext();
    const { isAdmin } = useAdminContext();

    if (!isAdmin) {
        ado = partnerName;
    }

    const getInitiativeLabel = (initiativeId: string) => {
        const foundInitiative = initiatives.find(init => init.id === Number(initiativeId));
        return foundInitiative ? foundInitiative.name : '';
    };
    
    const currentInitiatives = initiatives.filter(initiative => sanitizeString(initiative.partner_name) === sanitizeString(ado)).sort((a,b) => a.name?.localeCompare(b.name));

    const [initiative, setInitiative] = useState<{ value: string; label: string } | null>(
        currInitiative ? { value: currInitiative, label: getInitiativeLabel(currInitiative) } : null
    );

    useEffect(() => {
        setInitiative(currInitiative ? { value: currInitiative, label: getInitiativeLabel(currInitiative) } : null);
    }, [currInitiative]);

    const handleSelectChange = (selectedOption: { value: string; label: string } | null) => {
        const selectedInitiative = selectedOption ? selectedOption.value : '';
        setInitiative(selectedOption);
        filterByInitiative(selectedInitiative);
    };


    return (
        <div className="mt-2 lg:mt-0">
            <Select
                options={currentInitiatives.map(init => ({ value: init.id.toString(), label: init.name }))}
                value={initiative}
                onChange={handleSelectChange}
                placeholder="Select Initiative/ Project"
                isClearable={true}
                styles={{
                    control: (base) => ({
                        ...base,
                        borderColor: '#0A5F75',
                        boxShadow: 'none',
                        height: '36px',
                        minHeight: '36px',
                        borderRadius: '7px',
                        borderWidth: '2px',
                        width: '15rem',
                        cursor: 'pointer',
                        marginRight: '1rem',
                        '&:hover': {
                            borderColor: '#0B82A0',
                        },
                    }),
                    valueContainer: (base) => ({
                        ...base,
                        fontSize: '0.875rem',
                        padding: '0 0.5rem',
                        minHeight: '2rem',
                        height: '2rem',
                    }),
                    placeholder: (base) => ({
                        ...base,
                        color: '#0A5F75',
                        fontSize: '0.875rem',
                        fontWeight: 'bold',
                    }),
                    singleValue: (base) => ({
                        ...base,
                        color: '#0A5F75',
                        fontSize: '0.875rem',
                        fontWeight: 'bold'
                    }),
                    dropdownIndicator: (base) => ({
                        ...base,
                        color: '#0A5F75',
                        padding: '0',
                        '& hover': {
                            color: '#0B82A0'
                        }
                    }),
                    clearIndicator: (base) => ({
                        ...base,
                        padding: '0',
                    }),
                    indicatorsContainer: (base) => ({
                        ...base,
                        padding: '0 0.25rem',
                    }),
                    option: (base, state) => ({
                        ...base,
                        fontSize: '0.875rem',
                        backgroundColor: state.isSelected ? '#4D4D4D' : state.isFocused ? '#E2E8F0' : 'white',
                        borderRadius: '4px',
                        borderBottom: '1px solid #E2E8F0',
                        color: state.isSelected ? 'white' : '#4D4D4D',
                        '&:hover': {
                            backgroundColor: '#E2E8F0',
                            color: '#0A5F75'
                        },
                    }),
                    menu: (base) => ({
                        ...base,
                        width: '15rem',
                        position: 'absolute',
                        left: '0',
                        top: '2rem',
                        zIndex: 9999,
                        boxShadow: '0 8px 16px 0 rgba(0, 0, 0, 0.15)',
                    }),
                    menuList: (base) => ({
                        ...base,
                        padding: '4px',
                        maxHeight: '40rem',
                        overflowY: 'auto',
                        '::-webkit-scrollbar': {
                            width: '8px',
                            scrollBehavior: 'smooth'
                        },
                        '::-webkit-scrollbar-track': {
                            background: '#ffffff',
                            borderRadius: '4px',
                            scrollBehavior: 'smooth'
                        },
                        '::-webkit-scrollbar-thumb': {
                            background: '#E8E8E8',
                            borderRadius: '4px',
                        },
                    }),
                }}
            />
        </div>
    );
}

export default InitiativeSort;