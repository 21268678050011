import { useLocation, useNavigate } from 'react-router-dom';
import * as Sentry from '@sentry/react';
import Expand from '../../components/Navigation/Expand';
import { useContext, useState } from 'react';
import deleteSvg from '../../assets/delete-white.svg';
import { useIframeContext } from '../../context/IframeContext';
import InteractionDataContext from '../../context/InteractionDataContext';
import InitiativeDataContext from '../../context/InitiativeDataContext';
import { calculateFiscalYear } from '../../utils/helpers';


function DeleteInteraction() {
    const { isIframe } = useIframeContext();
    const { fetchInteractions, setInteractionsYear } = useContext(InteractionDataContext);
    const { fetchInitiatives } = useContext(InitiativeDataContext);
    const [deleteStatus, setDeleteStatus] = useState('');
    const [showImage, setShowImage] = useState(false);
    const [showContinueButton, setShowContinueButton] = useState(false);
    const [error, setError] = useState<string | null>(null);
    const currentFisYear = calculateFiscalYear('');

    const navigate = useNavigate();
    const location = useLocation();
    const { id, initId } = location.state;


    // Handler for navigating back to Interactions page
    const navigateBack = () => {
        navigate(-1);
    };

    const handleClose = () => {

        fetchInteractions();
        
        // We need to handle the path for interactions page vs. interaction table on initiatives page
        if (location.pathname !== '/commerce-interactions') {
            fetchInitiatives();
            setInteractionsYear(currentFisYear.toString());
            navigateBack();
        } else {
            setInteractionsYear(currentFisYear.toString());
            navigate('/commerce-interactions');
        }
    }

    const handleNext = async () => {

        setError(null);
        
        try {

            const response = await fetch(`${import.meta.env.VITE_COMMERCE_BACKEND_URL}/api/deleteinteraction?interactionId=${id}&initiativeId=${initId}`, {
                method: 'DELETE',
                headers: {
                    'Content-Type': 'application/json',
                },
            });
    
            if (!response.ok) {
                const responseData = await response.json();
                throw new Error(responseData.error || 'Failed to delete interaction');
            }

            setDeleteStatus('Interaction deleted successfully');
            setShowImage(true);
            setShowContinueButton(true);
            
            console.log(`Interaction with ID ${id} successfully deleted`);
        } catch (error) {
            // Log the caught error to Sentry
            Sentry.captureException(error);
            console.error('Error deleting data from backend:', error);
            setError('There was an issue deleting the interaction. Please try again later.');
        }
    };
    

    return (
        <div className="m-2 p-4">
            {/* Expand button */}
            {isIframe && 
            <div className='flex justify-end'>
                <div className="flex ml-auto mb-4">
                    <Expand/>
                </div>
            </div>
            }

            {/* Delete Interaction */}
            <div className="mb-8 border border-solid border-color-tableGray rounded-md">
                <div className='border-b border-solid border-titanium-white mb-5 bg-gotham-gray rounded-t-md'>
                    <h2 className="text-white font-semibold py-3 ml-7">Delete Interaction</h2>
                </div>
                <div className='flex ml-4 sm:ml-8 h-20 items-center'>
                    {showImage && 
                        <img src={deleteSvg} alt="Deleted Successfully" className="p-2 mr-4 h-9 w-10 border border-red-500 bg-red-500 rounded-full" />
                    }
                    <p className='font-semibold'>
                        {deleteStatus || 'Are you sure that you want to permanently delete the interaction?'}
                    </p>
                </div>
                {/* Display the server error message if it exists */}
                {error && (
                    <div className="text-red-500 text-sm ml-8">
                        {error}
                    </div>
                )}
                <div className='border-t border-solid border-color-tableGray mt-5 mb-2'>
                    {!showImage && (
                        <button
                            id="next"
                            onClick={handleNext}
                            className="ml-7 mt-5 mb-3 w-36 h-9 text-center text-white bg-gotham-gray border border-solid border-gotham-gray rounded-md focus:outline-none focus:border-blue-400 hover:bg-blue-400 hover:border-blue-500"
                        >
                            Next
                        </button>
                    )}
                    {!showImage && (
                        <button
                            id="cancel"
                            onClick={navigateBack}
                            className="ml-7 mt-5 mb-3 w-36 h-9 text-center text-gotham-gray bg-white border border-solid border-gotham-gray rounded-md focus:outline-none focus:border-red-500 hover:bg-red-400 hover:border-red-500 hover:text-white"
                        >
                            Cancel
                        </button>
                    )}
                    {showContinueButton && (
                        <button
                            id="close"
                            onClick={handleClose}
                            className="ml-7 mt-5 mb-3 w-36 h-9 text-center text-white bg-gotham-gray border border-solid border-gotham-gray rounded-md focus:outline-none focus:border-blue-400 hover:bg-blue-400 hover:border-blue-500"
                        >
                            Close
                        </button>
                    )}
                </div>
            </div>
        </div>
    );
};

export default DeleteInteraction;