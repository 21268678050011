import { useContext, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import editIcon from '/src/assets/edit.svg';
import deleteIcon from '/src/assets/delete.svg';
import upArrow from '../../assets/sort-arrow.svg';
// @ts-ignore
import { addCommas } from '../../utils/helpers.js';
import { OutcomeData } from '../../services/types.js';
import OutcomesDataContext from '../../context/OutcomeDataContext.js';

interface OutcomesTableProps {
    partnerId?: string;
    initiativeId?: number;
    id: string | undefined;
    from: string;
}

// OutcomesTable - Renders the outcome table within the InitiativeDetail/ProjectDetail page (ADO/Admin)
function OutcomesTable({ id, partnerId, initiativeId, from }: OutcomesTableProps) {
    const { outcomes } = useContext(OutcomesDataContext);
    const [data, setData] = useState<OutcomeData[]>([]);
    const [outcomeData, setOutcomeData] = useState<OutcomeData[]>([]);
    const [sortConfig, setSortConfig] = useState<{ key: string, direction: 'asc' | 'desc' | null }>({ key: '', direction: null });
    const navigate = useNavigate();

    useEffect(() => {
        const currOutcomes = outcomes.filter(outcome => Number(outcome.initiative_id) === Number(id));
        console.log('curr outcomes:', currOutcomes);
        if (currOutcomes) {
            setData(currOutcomes);
            setOutcomeData(currOutcomes);
            setSortConfig({ key: 'year', direction: 'asc' });
        }
    }, [outcomes, id])

    const handleEditClick = (row: OutcomeData) => {
        navigate(`/commerce-outcomes/edit/${row.id}`, { state: { data: row, partnerId, initiativeId, from } });
    };

    const handleDeleteClick = async (id: number) => {
        navigate(`/commerce-outcomes/delete/${id}`, { state: {id, partnerId, initiativeId, from } });
    };

    // Handle sorting
    const handleSortClick = (key: string) => {
        let direction: 'asc' | 'desc' = 'asc';
        if (sortConfig.key === key && sortConfig.direction === 'asc') {
            direction = 'desc';
        }
        setSortConfig({ key, direction });
    };

    // Sort data based on sortConfig
    useEffect(() => {
        if (sortConfig.key) {
            const sortedData = [...data].sort((a, b) => {
                let aVal, bVal;
                // Default case for other fields in OutcomeData
                aVal = a[sortConfig.key as keyof OutcomeData];
                bVal = b[sortConfig.key as keyof OutcomeData];
    
                // Ensure that empty or null values are pushed to the end
                if ((aVal === null || aVal === '') && (bVal === null || bVal === '')) return 0;
                if (aVal === null || aVal === '') return sortConfig.direction === 'asc' ? 1 : -1;
                if (bVal === null || bVal === '') return sortConfig.direction === 'asc' ? -1 : 1;

                // Standard comparison logic
                if (aVal < bVal) return sortConfig.direction === 'asc' ? -1 : 1;
                if (aVal > bVal) return sortConfig.direction === 'asc' ? 1 : -1;
                return 0;
            });
            setOutcomeData(sortedData);
        }
    }, [sortConfig, data]);

    return (
        <div className='p-4 bg-at-white'>
            <div className="overflow-x-auto border border-solid border-titanium-white rounded-md">
                <table className="min-w-full">
                    <thead className="bg-spanish-gray text-white">
                        <tr>
                            <th 
                                className="border-b border-titanium-white text-xs font-bold px-4 py-2 text-left"
                                onClick={() => handleSortClick('year')}
                            >
                                <div className='flex items-center hover:cursor-pointer'>
                                Year
                                {sortConfig.key === 'year' && (
                                    <img 
                                        src={upArrow} 
                                        alt='sort arrow' 
                                        className={`transform ${sortConfig.direction === 'desc' ? 'rotate-180' : ''}`} 
                                    />
                                )}
                                </div>
                            </th>
                            <th 
                                className="border-b border-titanium-white text-xs font-bold px-4 py-2 text-left"
                                onClick={() => handleSortClick('quarter')}
                            >
                                <div className='flex items-center hover:cursor-pointer'>
                                Quarter
                                {sortConfig.key === 'quarter' && (
                                    <img 
                                        src={upArrow} 
                                        alt='sort arrow' 
                                        className={`transform ${sortConfig.direction === 'desc' ? 'rotate-180' : ''}`} 
                                    />
                                )}
                                </div>
                            </th>
                            <th 
                                className="border-b border-titanium-white text-xs font-bold px-4 py-2 text-end text-nowrap"
                                onClick={() => handleSortClick('jobs_created')}
                            >
                                <div className='flex items-center hover:cursor-pointer'>
                                Jobs created
                                {sortConfig.key === 'jobs_created' && (
                                    <img 
                                        src={upArrow} 
                                        alt='sort arrow' 
                                        className={`transform ${sortConfig.direction === 'desc' ? 'rotate-180' : ''}`} 
                                    />
                                )}
                                </div>
                            </th>
                            <th 
                                className="border-b border-titanium-white text-xs font-bold px-4 py-2 text-end text-nowrap"
                                onClick={() => handleSortClick('jobs_retained')}
                            >
                                <div className='flex items-center hover:cursor-pointer'>
                                Jobs retained
                                {sortConfig.key === 'jobs_retained' && (
                                    <img 
                                        src={upArrow} 
                                        alt='sort arrow' 
                                        className={`transform ${sortConfig.direction === 'desc' ? 'rotate-180' : ''}`} 
                                    />
                                )}
                                </div>
                            </th>
                            <th 
                                className="border-b border-titanium-white text-xs font-bold px-4 py-2 text-center"
                                onClick={() => handleSortClick('jobs_created_retained_with_above_avg_wages')}
                            >
                                <div className='flex items-center hover:cursor-pointer'>
                                Jobs created/retained with above avg wages
                                {sortConfig.key === 'jobs_created_retained_with_above_avg_wages' && (
                                    <img 
                                        src={upArrow} 
                                        alt='sort arrow' 
                                        className={`transform ${sortConfig.direction === 'desc' ? 'rotate-180' : ''}`} 
                                    />
                                )}
                                </div>
                            </th>
                            <th 
                                className="border-b border-titanium-white text-xs font-bold px-4 py-2 text-center"
                                onClick={() => handleSortClick('public_investment_dollars')}
                            >
                                <div className='flex items-center hover:cursor-pointer'>
                                Public investment dollars
                                {sortConfig.key === 'public_investment_dollars' && (
                                    <img 
                                        src={upArrow} 
                                        alt='sort arrow' 
                                        className={`transform ${sortConfig.direction === 'desc' ? 'rotate-180' : ''}`} 
                                    />
                                )}
                                </div>
                            </th>
                            <th 
                                className="border-b border-titanium-white text-xs font-bold px-4 py-2 text-center"
                                onClick={() => handleSortClick('direct_foreign_investment_dollars')}
                            >
                                <div className='flex items-center hover:cursor-pointer'>
                                Direct foreign investment dollars
                                {sortConfig.key === 'direct_foreign_investment_dollars' && (
                                    <img 
                                        src={upArrow} 
                                        alt='sort arrow' 
                                        className={`transform ${sortConfig.direction === 'desc' ? 'rotate-180' : ''}`} 
                                    />
                                )}
                                </div>
                            </th>
                            <th 
                                className="border-b border-titanium-white text-xs font-bold px-4 py-2 text-center"
                                onClick={() => handleSortClick('private_investment_dollars')}
                            >
                                <div className='flex items-center hover:cursor-pointer'>
                                Private investment dollars
                                {sortConfig.key === 'private_investment_dollars' && (
                                    <img 
                                        src={upArrow} 
                                        alt='sort arrow' 
                                        className={`transform ${sortConfig.direction === 'desc' ? 'rotate-180' : ''}`} 
                                    />
                                )}
                                </div>
                            </th>
                            <th 
                                className="border-b border-titanium-white text-xs font-bold px-4 py-2 text-center"
                                onClick={() => handleSortClick('products_exported_dollars_year')}
                            >
                                <div className='flex items-center hover:cursor-pointer'>
                                {'Exports($/year)'}
                                {sortConfig.key === 'products_exported_dollars_year' && (
                                    <img 
                                        src={upArrow} 
                                        alt='sort arrow' 
                                        className={`transform ${sortConfig.direction === 'desc' ? 'rotate-180' : ''}`} 
                                    />
                                )}
                                </div>
                            </th>
                            <th 
                                className="border-b border-titanium-white text-xs font-bold px-4 py-2 text-center"
                                onClick={() => handleSortClick('startups_supported')}
                            >
                                <div className='flex items-center hover:cursor-pointer'>
                                Startup businesses
                                {sortConfig.key === 'startups_supported' && (
                                    <img 
                                        src={upArrow} 
                                        alt='sort arrow' 
                                        className={`transform ${sortConfig.direction === 'desc' ? 'rotate-180' : ''}`} 
                                    />
                                )}
                                </div>
                            </th>
                            <th className="border-b border-titanium-white text-xs font-bold px-4 py-2 text-center">Manage</th>
                        </tr>
                    </thead>
                    <tbody>
                        {outcomeData.map((row) => (
                            <tr key={row.id} className="bg-white border-b border-solid border-titanium-white last:border-none h-10">
                                <td className="px-4 py-2 min-w-24 text-gotham-gray text-sm text-left font-medium">
                                    {row.year}
                                </td>
                                <td className="px-4 py-2 min-w-24 text-gotham-gray text-sm text-left font-medium">
                                    {row.quarter}
                                </td>
                                <td className="px-4 py-2 min-w-28 text-gotham-gray text-sm text-center font-medium">
                                    {row.jobs_created}
                                </td>
                                <td className="px-4 py-2 min-w-32 text-gotham-gray text-sm text-center font-medium">
                                    {row.jobs_retained}
                                </td>
                                <td className="px-4 py-2 min-w-28 text-gotham-gray text-sm text-center font-medium">
                                    {row.jobs_created_retained_with_above_avg_wages}
                                </td>
                                <td className="px-4 py-2 min-w-36 text-gotham-gray text-sm text-end font-medium">
                                    {`$${addCommas(Number(row.public_investment_dollars || 0).toFixed(0))}`}
                                </td>
                                <td className="px-4 py-2 min-w-36 text-gotham-gray text-sm text-end font-medium">
                                    {`$${addCommas(Number(row.direct_foreign_investment_dollars || 0).toFixed(0))}`}
                                </td>
                                <td className="px-4 py-2 min-w-36 text-gotham-gray text-sm text-end font-medium">
                                    {`$${addCommas(Number(row.private_investment_dollars || 0).toFixed(0))}`}
                                </td>
                                <td className="px-4 py-2 min-w-36 text-gotham-gray text-sm text-end font-medium">
                                    {`$${addCommas(Number(row.products_exported_dollars_year || 0).toFixed(0))}`}
                                </td>
                                <td className="px-4 py-2 min-w-24 text-gotham-gray text-sm text-center font-medium">
                                    {row.startups_supported}
                                </td>
                                <td className="w-24 py-2">
                                    <div className='w-24 flex items-center justify-center'>
                                        <button onClick={(e) => { e.stopPropagation(); handleEditClick(row) }} className="mr-3">
                                            <img className="border border-solid border-button-blue h-6 w-6 p-1 rounded-md" src={editIcon} alt="Edit" />
                                        </button>
                                        <button onClick={(e) => { e.stopPropagation(); handleDeleteClick(row.id) }}
                                            className="flex items-center">
                                            <img className="border border-solid border-button-blue h-6 w-6 p-1 rounded-md" src={deleteIcon} alt="Delete" />
                                        </button>
                                    </div>
                                </td>
                            </tr>
                        ))}
                    </tbody>
                </table>
            </div>
        </div>
    );
}

export default OutcomesTable;