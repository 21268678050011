export const customStyles = (isSubmitted?: boolean, field?: any) => {

    const isFieldInvalid = isSubmitted && (Array.isArray(field) ? field.length === 0 || field.every(item => item === null) : !field);

    return {
        control: (base: any, state: any) => ({
            ...base,
            width: '100%',
            borderColor: state.isFocused 
                ? '#3B82F6' 
                : isSubmitted && isFieldInvalid
                ? 'red' 
                : '#E5E7EB',
            borderRadius: '4px',
            boxShadow: 'none',
            cursor: 'pointer',
            '&:hover': {
                borderColor: state.isFocused 
                    ? '#3B82F6' 
                    : isSubmitted && isFieldInvalid
                    ? 'red' 
                    : '#E5E7EB',
            },
        }),
        valueContainer: (base: any) => ({
            ...base,
            padding: '0 0.5rem',
        }),
        placeholder: (base: any) => ({
            ...base,
            color: '#3A3C41',
            fontSize: '0.875rem',
        }),
        singleValue: (base: any) => ({
            ...base,
            color: '#3A3C41',
            fontSize: '0.875rem',
        }),
        dropdownIndicator: (base: any) => ({
            ...base,
            padding: '0',
        }),
        clearIndicator: (base: any) => ({
            ...base,
            padding: '0',
        }),
        indicatorsContainer: (base: any) => ({
            ...base,
            padding: '0 0.25rem',
        }),
        option: (base: any, state: any) => ({
            ...base,
            fontSize: '0.875rem',
            backgroundColor: state.isSelected ? '#626262' : state.isFocused ? '#CCCCCC' : 'white',
            borderRadius: '4px',
            borderBottom: '1px solid #E2E8F0',
            color: state.isSelected ? 'white' : '#3A3C41',
            '&:hover': {
                backgroundColor: '#CCCCCC',
            },
        }),
        menu: (base: any) => ({
            ...base,
            minWidth: '100%',
            width: 'auto',
            position: 'absolute',
            left: '0',
            top: '2rem',
            zIndex: 9999,
            boxShadow: '0 8px 16px 0 rgba(0, 0, 0, 0.15)',
        }),
        menuList: (base: any) => ({
            ...base,
            padding: '4px',
            maxHeight: '35rem', // Adjust height as needed
            overflowY: 'auto',
            '::-webkit-scrollbar': {
                width: '8px',
            },
            '::-webkit-scrollbar-track': {
                background: '#ffffff',
                borderRadius: '4px',
            },
            '::-webkit-scrollbar-thumb': {
                background: '#E8E8E8',
                borderRadius: '4px',
            },
        }),
    }
};


export const customStylesXL = (isSubmitted?: boolean, field?: any) => {

    const isFieldInvalid = isSubmitted && (Array.isArray(field) ? field.length === 0 || field.every(item => item === null) : !field);

    return {
        control: (base: any, state: any) => ({
            ...base,
            width: '100%',
            borderColor: state.isFocused 
                ? '#3B82F6' 
                : isSubmitted && isFieldInvalid
                ? 'red' 
                : '#E5E7EB',
            borderRadius: '4px',
            boxShadow: 'none',
            cursor: 'pointer',
            '&:hover': {
                borderColor: state.isFocused 
                    ? '#3B82F6' 
                    : isSubmitted && isFieldInvalid
                    ? 'red' 
                    : '#E5E7EB',
            },
        }),
        valueContainer: (base: any) => ({
            ...base,
            padding: '0 0.5rem',
        }),
        placeholder: (base: any) => ({
            ...base,
            color: '#3A3C41',
            fontSize: '0.875rem',
        }),
        singleValue: (base: any) => ({
            ...base,
            color: '#3A3C41',
            fontSize: '0.875rem',
        }),
        dropdownIndicator: (base: any) => ({
            ...base,
            padding: '0',
        }),
        clearIndicator: (base: any) => ({
            ...base,
            padding: '0',
        }),
        indicatorsContainer: (base: any) => ({
            ...base,
            padding: '0 0.25rem',
        }),
        option: (base: any, state: any) => ({
            ...base,
            fontSize: '0.875rem',
            backgroundColor: state.isSelected ? '#626262' : state.isFocused ? '#CCCCCC' : 'white',
            borderRadius: '4px',
            borderBottom: '1px solid #E2E8F0',
            color: state.isSelected ? 'white' : '#3A3C41',
            '&:hover': {
                backgroundColor: '#CCCCCC',
            },
        }),
        menu: (base: any) => ({
            ...base,
            minWidth: '100%',
            width: '125%',
            position: 'absolute',
            left: '0',
            top: '2rem',
            zIndex: 9999,
            boxShadow: '0 8px 16px 0 rgba(0, 0, 0, 0.15)',
        }),
        menuList: (base: any) => ({
            ...base,
            padding: '4px',
            maxHeight: '45rem', // Adjust height as needed
            overflowY: 'auto',
            '::-webkit-scrollbar': {
                width: '8px',
            },
            '::-webkit-scrollbar-track': {
                background: '#ffffff',
                borderRadius: '4px',
            },
            '::-webkit-scrollbar-thumb': {
                background: '#E8E8E8',
                borderRadius: '4px',
            },
        }),
    }
};