import { useNavigate } from "react-router-dom";

interface NewOutcomeButtonProps {
    id: string | undefined;
    from: string;
}

function NewOutcomeButton({ id, from }: NewOutcomeButtonProps) {
    const navigate = useNavigate();

    const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
        navigate('/commerce-outcomes/addoutcome', {state: {id, from}});
        console.log('button clicked at id:', id, event);
    };

    return ( 
        <span className="flex items-center">
        <button
            id="add-outcome"
            onClick={handleClick}
            className="w-40 px-4 mr-4 text-white font-bold text-sm bg-button-blue border border-solid border-button-blue rounded-md focus:outline-none focus:border-blue-500 hover:border-color-cai-blue hover:bg-color-cai-blue h-[36px] appearance-none text-center"
        > + Add Outcome
        </button>
        </span>
    );
}

export default NewOutcomeButton;