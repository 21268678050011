import { useState, useEffect } from 'react';
import '../../App.css'
import '../../index.css'
import Select from 'react-select';

interface FiscalYearProps {
    filterByYear: (year: string) => void;
    availableYears: string[];
    currYear: string;
}

function FiscalYear({ filterByYear, availableYears, currYear }: FiscalYearProps) {
    const [fisYear, setFisYear] = useState(currYear || '');
    const yearOptions = availableYears.map(year => ({value: year, label: year}));

    useEffect(() => {
        setFisYear(currYear);
    }, [currYear]);

    const handleSelectChange = (selectedOption: { value: string; label: string } | null) => {
        const selectedYear = selectedOption ? selectedOption.value : '';
        console.log('selected year:', selectedYear);

        setFisYear(selectedYear);
        filterByYear(selectedYear);
    };


    return ( 
        <div className='mt-2 lg:mt-0'>
            <Select
                options={yearOptions}
                value={yearOptions.find(option => option.value === fisYear)}
                onChange={handleSelectChange}
                placeholder="Fiscal Year"
                isClearable={false}
                isSearchable={false}
                styles={{
                    control: (base) => ({
                        ...base,
                        height: '36px',
                        minHeight: '36px',
                        width: '8.5rem',
                        borderColor: '#0A5F75',
                        borderRadius: '7px',
                        borderWidth: '2px',
                        marginRight: '1rem',
                        boxShadow: 'none',
                        cursor: 'pointer',
                        '&:hover': {
                            borderColor: '#0B82A0',
                        },
                    }),
                    valueContainer: (base) => ({
                        ...base,
                        padding: '0 0.5rem',
                        minHeight: '2rem',
                        height: '2rem',
                    }),
                    placeholder: (base) => ({
                        ...base,
                        color: '#0A5F75',
                        fontSize: '0.875rem',
                        fontWeight: 'bold',
                    }),
                    singleValue: (base) => ({
                        ...base,
                        color: '#0A5F75',
                        fontSize: '0.875rem',
                        fontWeight: 'bold'
                    }),
                    dropdownIndicator: (base) => ({
                        ...base,
                        color: '#0A5F75',
                        padding: '0',
                        '& hover': {
                            color: '#0B82A0'
                        }
                    }),
                    clearIndicator: (base) => ({
                        ...base,
                        padding: '0',
                    }),
                    indicatorsContainer: (base) => ({
                        ...base,
                        padding: '0 0.25rem',
                    }),
                    option: (base, state) => ({
                        ...base,
                        fontSize: '0.875rem',
                        backgroundColor: state.isSelected ? '#4D4D4D' : state.isFocused ? '#E2E8F0' : 'white',
                        borderRadius: '4px',
                        borderBottom: '1px solid #E2E8F0',
                        color: state.isSelected ? 'white' : '#4D4D4D',
                        '&:hover': {
                            backgroundColor: '#E2E8F0',
                            color: '#0A5F75'
                        },
                    }),
                    menu: (base) => ({
                        ...base,
                        position: 'absolute',
                        left: '0',
                        top: '2rem',
                        width: '8rem',
                        zIndex: 9999,
                        boxShadow: '0 0 10px rgba(0, 0, 0, 0.1)',
                    }),
                    menuList: (base) => ({
                        ...base,
                        padding: '4px',
                        maxHeight: '200px',
                        overflowY: 'auto',
                        '::-webkit-scrollbar': {
                            width: '8px',
                        },
                        '::-webkit-scrollbar-track': {
                            background: '#ffffff',
                            borderRadius: '4px',
                        },
                        '::-webkit-scrollbar-thumb': {
                            background: '#E8E8E8',
                            borderRadius: '4px',
                            height: '50px',
                            padding: '10px'
                        },
                    }),
                }}
            />
        </div>
    );
}

export default FiscalYear;