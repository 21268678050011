import { useState } from 'react';
import Select from 'react-select';
// import uploadSvg from '../../assets/upload.svg';
// import historySvg from '../../assets/view-history.svg';
import { useNavigate } from 'react-router-dom';

interface UploadInteractionsBtnProps {
    id?: string | undefined;
    initiative: string;
    initiativeData?: any;
    isDefault: boolean;
}

function UploadInteractionsBtn({ id, initiative, initiativeData, isDefault }: UploadInteractionsBtnProps) {
    const [upload, setUpload] = useState('');
    const navigate = useNavigate();
    let placeholder = "Bulk Upload";

    if (isDefault) {
        placeholder = "Upload"
    };

    const handleSelectChange = (selectedOption: { value: string; label: string } | null) => {
        if (selectedOption) {
            const selectedType = selectedOption.value;
            console.log('selected upload type:', selectedType);

            if (selectedType === "new") {
                console.log('initiative and id:', initiative, id);
                navigate('/upload-interactions', { state: { initiativeId: id, initiative, initiativeData } });
            } else if (selectedType === "history") {
                navigate('/upload-history', { state: { initiativeId: id }});
            }
            setUpload(selectedType);
        }
    };

    const options = [
        { value: 'new', label: 'New Upload' },
        { value: 'history', label: 'View Upload History' },
    ];

    const customStyles = {
        control: (base: any) => ({
            ...base,
            background: isDefault ? '#FFFFFF' : '#0A5F75',
            borderColor: isDefault ? '#FFFFFF' : '#0A5F75',
            boxShadow: 'none',
            height: isDefault ? '2rem' : '36px',
            minHeight: isDefault ? '2rem' : '36px',
            width: isDefault ? '7rem' : '9rem',
            borderRadius: '4px',
            marginRight: '1rem',
            cursor: 'pointer',
            '&:hover': {
                borderColor: '#0B82A0',
                background: '#0B82A0',
            },
        }),
        valueContainer: (base: any) => ({
            ...base,
            padding: '0 0.5rem',
            minHeight: '2rem',
            height: '2rem',
        }),
        placeholder: (base: any) => ({
            ...base,
            color: isDefault ? '#4D4D4D': '#FFFFFF',
            fontSize: '0.875rem',
            fontWeight: 'bold',
        }),
        singleValue: (base: any) => ({
            ...base,
            color: '#3A3C41',
            fontSize: '0.875rem',
        }),
        dropdownIndicator: (base: any) => ({
            ...base,
            padding: '0',
            color: isDefault ? '#4D4D4D': '#FFFFFF',
        }),
        indicatorsContainer: (base: any) => ({
            ...base,
            padding: '0 0.25rem',
        }),
        option: (base: any, state: any) => ({
            ...base,
            fontSize: '0.875rem',
            backgroundColor: state.isSelected ? '#4D4D4D' : state.isFocused ? '#E2E8F0' : 'white',
            borderRadius: '4px',
            borderBottom: '1px solid #E2E8F0',
            color: state.isSelected ? 'white' : '#4D4D4D',
            '&:hover': {
                backgroundColor: '#E2E8F0',
                color: '#0A5F75'
            },
        }),
        menu: (base: any) => ({
            ...base,
            width: '9rem',
            position: 'absolute',
            left: '0',
            top: '2rem',
            zIndex: 9999,
            boxShadow: '0 8px 16px 0 rgba(0, 0, 0, 0.15)',
        }),
        menuList: (base: any) => ({
            ...base,
            padding: '4px',
            '::-webkit-scrollbar': {
                width: '0.5rem',
            },
            '::-webkit-scrollbar-track': {
                borderRadius: '0.5rem',
            },
            '::-webkit-scrollbar-thumb': {
                background: '#E8E8E8',
                borderRadius: '0.5rem',
                borderWidth: '4px',
                borderColor: 'transparent',
                backgroundClip: 'padding-box',
                height: '3rem',
                minHeight: '3rem',
            },
        }),
    }

    return (
        <div className='mt-2 sm:mt-0'>
            <Select
                options={options}
                value={options.find(option => option.value === upload)}
                onChange={handleSelectChange}
                placeholder={placeholder}
                isSearchable={false}
                styles={customStyles}
            /> 
        </div>
    );
}

export default UploadInteractionsBtn;