import { useState, useEffect, useContext } from 'react';
import { useNavigate } from 'react-router-dom';
// import editIcon from '/src/assets/edit.svg';
import deleteIcon from '/src/assets/delete.svg';
import upArrow from '../../assets/sort-arrow.svg';
import { InteractionData } from '../../services/types';
import InitiativeDataContext from '../../context/InitiativeDataContext';
import { generatePageNumbers, getName } from '../../utils/helpers';

interface InteractionTableProps {
    data: InteractionData[];
    initiativeId: string | undefined;
    handleSelectRows: (selectedRows: number[]) => void;
}

function InitiativeInteractionTable({ data, initiativeId, handleSelectRows }: InteractionTableProps) {
    const { initiatives } = useContext(InitiativeDataContext);
    const [selectedRows, setSelectedRows] = useState<number[]>([]);
    const [interactionData, setInteractionData] = useState<InteractionData[]>([]);
    const [currentPage, setCurrentPage] = useState(1);
    const [sortConfig, setSortConfig] = useState<{ key: string, direction: 'asc' | 'desc' | null }>({ key: '', direction: null });

    const rowsPerPage = 20;
    let initiativeName = '';
    let initId: number | null = null;

    const navigate = useNavigate();

    const filteredData = data.filter(item => item.initiative_id === Number(initiativeId));

    useEffect(() => {
        if (Array.isArray(filteredData)) {
            setInteractionData(filteredData);
            setSortConfig({ key: 'business_name', direction: 'asc' });
        } else {
            console.error('interactionData is not an array:', data);
        }
    }, [data]);

    const handleCheckboxChange = (id: number) => {
        const index = selectedRows.indexOf(id);
        let updatedSelectedRows: number[];
        if (index === -1) {
            updatedSelectedRows = [...selectedRows, id];
        } else {
            updatedSelectedRows = selectedRows.filter(rowId => rowId !== id);
        }
        setSelectedRows(updatedSelectedRows);
    };
    
    useEffect(() => {
        handleSelectRows(selectedRows);
    }, [selectedRows]);

    const handleSelectAll = () => {
        const pageIds = paginatedData.map(row => row.id);
        setSelectedRows(prev => {
            // Add only rows not already selected
            const updatedSelection = [...prev, ...pageIds.filter(id => !prev.includes(id))];
            return updatedSelection;
        });
        handleSelectRows(pageIds);
    };

    const handleDeselectAll = () => {
        const pageIds = paginatedData.map(row => row.id);
        setSelectedRows(prev => prev.filter(id => !pageIds.includes(id))); // Remove only the current page IDs
        handleSelectRows([]);
    };

    const handleEditClick = (id: number) => {
        navigate(`/commerce-interactions/edit/${id}`, { state: { initiativeName, initId } });
    };

    const handleDeleteClick = async (id: number) => {
        navigate(`/commerce-interactions/delete/${id}`, { state: {id, initId } })
    };

    const handlePageClick = (page: number) => {
        setCurrentPage(page);
    };

    // Handle sorting
    const handleSortClick = (key: string) => {
        let direction: 'asc' | 'desc' = 'asc';
        if (sortConfig.key === key && sortConfig.direction === 'asc') {
            direction = 'desc';
        }
        setSortConfig({ key, direction });
    };

    // Sort data based on sortConfig
    useEffect(() => {
        if (sortConfig.key) {
            const sortedData = [...filteredData].sort((a, b) => {
                let aVal, bVal;

                if (sortConfig.key === 'name') { // 'name' is for initiative name
                    // Get the initiative names for comparison
                    const aInitiative = initiatives.find(init => init.id === a.initiative_id);
                    const bInitiative = initiatives.find(init => init.id === b.initiative_id);
                    aVal = aInitiative ? aInitiative.name : '';
                    bVal = bInitiative ? bInitiative.name : '';
                } else if (sortConfig.key === 'business_name') { // Sorting by business name
                    // Use business_legal_name if available, otherwise use business_trade_name
                    aVal = a.business_legal_name || a.business_trade_name || '';
                    bVal = b.business_legal_name || b.business_trade_name || '';
                } else if (sortConfig.key === 'interaction_type') { // Sorting by interaction_type.display_name
                    const aInteractionType = a.interaction_type || {};
                    const bInteractionType = b.interaction_type || {};
                    aVal = aInteractionType.display_name || '';
                    bVal = bInteractionType.display_name || '';
                } else if (sortConfig.key === 'assistance_types') { // Sorting by assistance_types.name
                    const aAssistanceType = a.assistance_types && a.assistance_types[0];
                    const bAssistanceType = b.assistance_types && b.assistance_types[0];
                    // If assistance_types is empty, treat it as a value that should go to the end
                    aVal = aAssistanceType ? aAssistanceType.name : null;
                    bVal = bAssistanceType ? bAssistanceType.name : null;
                    
                    // Ensure empty assistance_types are placed at the end
                    if (aVal === null && bVal === null) return 0;
                    if (aVal === null) return sortConfig.direction === 'asc' ? 1 : -1;
                    if (bVal === null) return sortConfig.direction === 'asc' ? -1 : 1;
                } else {
                    // Default case for other fields in InteractionData
                    aVal = a[sortConfig.key as keyof InteractionData];
                    bVal = b[sortConfig.key as keyof InteractionData];
                }
    
                // Ensure that empty or null values are pushed to the end
                if (aVal === null && bVal === null) return 0;
                if (aVal === null) return sortConfig.direction === 'asc' ? 1 : -1;
                if (bVal === null) return sortConfig.direction === 'asc' ? -1 : 1;

                // Standard comparison logic
                if (aVal < bVal) return sortConfig.direction === 'asc' ? -1 : 1;
                if (aVal > bVal) return sortConfig.direction === 'asc' ? 1 : -1;
                return 0;
            });
            setInteractionData(sortedData);
        }
    }, [sortConfig, data]);


    // Pagination logic
    const totalPages = Math.ceil(interactionData.length / rowsPerPage);
    const pageNumbersToShow = generatePageNumbers(totalPages, currentPage);

    const startIndex = (currentPage - 1) * rowsPerPage;
    const endIndex = Math.min(startIndex + rowsPerPage, interactionData.length);
    const paginatedData = interactionData.slice(startIndex, endIndex);

    return (
        <div className='p-4 bg-at-white'>
            <div className="overflow-x-auto border border-solid border-titanium-white rounded-md">
                <table className="min-w-full">
                    <thead className="bg-spanish-gray text-white">
                        <tr>
                            <th className="border-b border-titanium-white text-xs font-bold px-4 py-2 text-left">
                                <input 
                                    type="checkbox" 
                                    checked={paginatedData.every(row => selectedRows.includes(row.id))} // Select all on page
                                    onChange={() =>
                                        paginatedData.every(row => selectedRows.includes(row.id))
                                            ? handleDeselectAll()
                                            : handleSelectAll()
                                    } 
                                />
                            </th>
                            <th 
                                className="border-b border-titanium-white text-xs font-bold px-4 py-2 text-left min-w-72 max-w-72"
                                onClick={() => handleSortClick('business_name')}
                            >
                                <div className='flex items-center hover:cursor-pointer'>
                                Business Name
                                {sortConfig.key === 'business_name' && (
                                    <img 
                                        src={upArrow} 
                                        alt='sort arrow' 
                                        className={`transform ${sortConfig.direction === 'desc' ? 'rotate-180' : ''}`} 
                                    />
                                )}
                                </div>
                            </th>
                            <th 
                                className="border-b border-titanium-white text-xs font-bold px-4 py-2 text-left min-w-36 max-w-36"
                                onClick={() => handleSortClick('business_ubi')}
                            >
                                <div className='flex items-center hover:cursor-pointer'>
                                Business UBI
                                {sortConfig.key === 'business_ubi' && (
                                    <img 
                                        src={upArrow} 
                                        alt='sort arrow' 
                                        className={`transform ${sortConfig.direction === 'desc' ? 'rotate-180' : ''}`} 
                                    />
                                )}
                                </div>
                            </th>
                            <th 
                                className="border-b border-titanium-white text-xs font-bold px-4 py-2 text-left min-w-40 max-w-40"
                                onClick={() => handleSortClick('interaction_type')}
                            >
                                <div className='flex items-center hover:cursor-pointer'>
                                Interaction Type
                                {sortConfig.key === 'interaction_type' && (
                                    <img 
                                        src={upArrow} 
                                        alt='sort arrow' 
                                        className={`transform ${sortConfig.direction === 'desc' ? 'rotate-180' : ''}`} 
                                    />
                                )}
                                </div>
                            </th>
                            <th 
                                className="border-b border-titanium-white text-xs font-bold px-4 py-2 text-left min-w-48 max-w-48"
                                onClick={() => handleSortClick('assistance_types')}
                            >
                                <div className='flex items-center hover:cursor-pointer'>
                                Assistance Type
                                {sortConfig.key === 'assistance_types' && (
                                    <img 
                                        src={upArrow} 
                                        alt='sort arrow' 
                                        className={`transform ${sortConfig.direction === 'desc' ? 'rotate-180' : ''}`} 
                                    />
                                )}
                                </div>
                            </th>
                            <th 
                                className="border-b border-titanium-white text-xs font-bold px-4 py-2 text-left min-w-40 max-w-40"
                                onClick={() => handleSortClick('interaction_date')}
                            >
                                <div className='flex items-center hover:cursor-pointer'>
                                Interaction Date
                                {sortConfig.key === 'interaction_date' && (
                                    <img 
                                        src={upArrow} 
                                        alt='sort arrow' 
                                        className={`transform ${sortConfig.direction === 'desc' ? 'rotate-180' : ''}`} 
                                    />
                                )}
                                </div>
                            </th>
                            <th className="border-b border-titanium-white text-xs font-bold px-4 py-2 text-center">Delete</th>
                        </tr>
                    </thead>
                    <tbody>
                        {paginatedData.map((row) => {

                            initId = Number(row.initiative_id);
                            const initiative = initiatives.find(init => init.id === initId);
                            initiativeName = initiative ? initiative.name : 'N/A';

                            const date = new Date(row.interaction_date);
                            // Manually format the date as mm/dd/yyyy in UTC
                            const month = String(date.getUTCMonth() + 1).padStart(2, '0'); // Months are 0-based, so add 1
                            const day = String(date.getUTCDate()).padStart(2, '0');
                            const year = date.getUTCFullYear();

                            const formattedDate = `${month}/${day}/${year}`;

                            const businessName = getName(row);

                            return (
                                <tr key={row.id} className="bg-white border-b border-solid border-titanium-white last:border-none h-10 hover:bg-at-white hover:text-black hover:cursor-pointer">
                                    <td className="px-4 py-2 text-gotham-gray text-sm text-left">
                                        <input 
                                            type="checkbox" 
                                            onClick={(e) => {e.stopPropagation();}} onChange={() => {  handleCheckboxChange(row.id)}} 
                                            checked={selectedRows.includes(row.id)} 
                                        />
                                    </td>
                                    <td className="px-4 py-2 text-gotham-gray text-sm text-left min-w-72 max-w-72" onClick={(e) => { e.stopPropagation(); handleEditClick(row.id) }}>
                                        {businessName}
                                    </td>
                                    <td className="px-4 py-2 text-gotham-gray text-sm text-left min-w-72 max-w-72" onClick={(e) => { e.stopPropagation(); handleEditClick(row.id) }}>
                                        {row.business_ubi}
                                    </td>
                                    <td className="px-4 py-2 text-gotham-gray text-sm text-left min-w-36 max-w-36" onClick={(e) => { e.stopPropagation(); handleEditClick(row.id) }}>
                                        {row.interaction_type?.display_name}
                                    </td>
                                    <td className="px-4 py-2 text-gotham-gray text-sm text-left min-w-48 max-w-48" onClick={(e) => { e.stopPropagation(); handleEditClick(row.id) }}>
                                        {row.assistance_types.length > 0 && (
                                            row.assistance_types.map((type: any) => type.name).join(', ')
                                        )}
                                    </td>
                                    <td className="px-4 py-2 text-gotham-gray text-sm text-left min-w-24 max-w-24" onClick={(e) => { e.stopPropagation(); handleEditClick(row.id) }}>
                                        {formattedDate}
                                    </td>
                                    <td className="w-24 px-4 py-2">
                                        <div className='w-24 flex items-center justify-center'>
                                            {/* <button onClick={(e) => { e.stopPropagation(); handleEditClick(row.id) }} className="mr-3 flex items-center">
                                                <img className="border border-solid border-button-blue h-6 w-6 p-1 rounded-md" src={editIcon} alt="Edit" />
                                            </button> */}
                                            <button onClick={(e) => { e.stopPropagation(); handleDeleteClick(row.id) }} className="flex items-center">
                                                <img className="border border-solid border-button-blue h-6 w-6 p-1 rounded-md" src={deleteIcon} alt="Delete" />
                                            </button>
                                        </div>
                                    </td>
                                </tr>
                            )
                        })}
                    </tbody>
                </table>
            </div>
            {pageNumbersToShow.length > 1 && 
            <div className="flex justify-center mt-4">
                <div className="border border-solid border-at-white rounded-md flex">
                    <button
                        className="text-gotham-gray font-bold text-xs border-r border-solid border-at-white px-2 py-1"
                        onClick={() => handlePageClick(Math.max(currentPage - 1, 1))}
                        disabled={currentPage === 1}
                    >
                        « Previous
                    </button>
                    {pageNumbersToShow.map((pageNumber, index) => (
                        <button
                            key={index}
                            className={`text-gotham-gray font-bold text-xs border-r border-solid border-at-white px-3 py-1 ${pageNumber === currentPage ? 'bg-gray-300' : ''}`}
                            onClick={() => handlePageClick(typeof pageNumber === 'number' ? pageNumber : currentPage)}
                        >
                            {pageNumber}
                        </button>
                    ))}
                    <button
                        className="text-gotham-gray font-bold text-xs px-2 py-1"
                        onClick={() => handlePageClick(Math.min(currentPage + 1, totalPages))}
                        disabled={currentPage === totalPages}
                    >
                        Next »
                    </button>
                </div>
            </div>
            }
        </div>
    );
}

export default InitiativeInteractionTable;