import { createContext, useState, useEffect } from 'react';
import axios from 'axios';
import { AdminProviderProps, InteractionData, InteractionDataContextType } from '../services/types';
import * as Sentry from '@sentry/react';
import { useOrganizationContext } from './OrganizationContext';



const InteractionDataContext = createContext<InteractionDataContextType>({
    interactions: [],
    fetchInteractions: () => {},
    loading: false,
    interactionsYear: '',
    setInteractionsYear: () => {},
});

export const InteractionDataProvider = ({ children }: AdminProviderProps) => {
    const [interactions, setInteractions] = useState<InteractionData[]>([]);
    const [loading, setLoading] = useState(false);
    const [interactionsYear, setInteractionsYear] = useState('');
    const { partnerId } = useOrganizationContext();
    const apiUrl = `${import.meta.env.VITE_COMMERCE_BACKEND_URL}/api/getinteractions`;

    const fetchInteractions = async () => {
        setLoading(true);

        if (partnerId === -1 || !partnerId) {
            // Don't fetch interactions if partnerId is -1
            console.log('Skipping interactions fetch for partnerId -1 (Admin)');
            setLoading(false);
            return;
        }

        if (!interactionsYear) {
            console.log('Skipping interactions fetch: interactionsYear is not set');
            setLoading(false);
            return;
        }

        // Determine if interactionsYear should be empty based on "all" value
        const yearParam = interactionsYear === 'all' ? '' : interactionsYear;

        try {
            const response = await axios.get(apiUrl, { params: { partnerId, interactionsYear: yearParam }});
            // const response = await axios.get(apiUrl, { params: { partnerId }});
            if (response.status === 200) {
                const data = response.data.data;
                setInteractions(data);
            } else {
                // Log the error response to Sentry
                Sentry.captureMessage(`Error fetching interactions: ${response.data.message}`, 'warning');
                console.error('Error fetching interactions:', response.data);
                setLoading(false);
            }
        } catch (error) {
            // Log the caught error to Sentry
            Sentry.captureException(error);
            console.error('Error fetching Interactions:', error);
            setLoading(false);
        } finally {
            setLoading(false);
        }
    };

    useEffect(() => {
        fetchInteractions();
    }, [partnerId, interactionsYear]);

    // useEffect(() => {
    //     fetchInteractions();
    // }, [partnerId]);

    return (
        <InteractionDataContext.Provider value={{ interactions, fetchInteractions, loading, interactionsYear, setInteractionsYear }}>
            {children}
        </InteractionDataContext.Provider>
    );
};

export default InteractionDataContext;