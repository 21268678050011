import { useNavigate } from "react-router-dom";

function NewTargetButton() {
    const navigate = useNavigate();

    const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
        navigate('/commerce-targets/addtarget');
        console.log('button clicked:', event);
    };

    return ( 
        <span className="flex items-center sm:mt-0 mt-2">
        <button
            id="new-target"
            onClick={handleClick}
            className="pl-4 w-36 pr-4 text-white font-bold text-sm bg-button-blue border border-solid border-button-blue rounded-md focus:outline-none focus:border-blue-500 h-[36px] appearance-none text-center"
        > + Add Target
        </button>
        </span>
    );
}

export default NewTargetButton;