import { useContext, useEffect, useState } from 'react';
import Select from 'react-select';
import AdoDataContext from '../../context/AdoDataContext';
import { useOrganizationContext } from '../../context/OrganizationContext';

interface AdminViewSelectProps {
    currAdo: string;
    filterByAdo: (ado: string) => void;
}

function AdminViewSelect({ currAdo, filterByAdo }: AdminViewSelectProps) {
    const { adoNames } = useContext(AdoDataContext);
    const { partnerData } = useContext(AdoDataContext);
    const { setPartnerName, setPartnerId } = useOrganizationContext();
    const [ado, setAdo] = useState<string>('Admin');

    // Create a new array with "Admin" added and sort alphabetically by `label`
    const adminViewOptions = [...adoNames, { value: 'Admin', label: 'Admin' }]
        .sort((a, b) => a.label.localeCompare(b.label));

    useEffect(() => {
        setAdo(currAdo);
    }, [currAdo]);

    const handleSelectChange = (selectedOption: { value: string; label: string } | null) => {
        const selectedAdo = selectedOption ? selectedOption.value : '';
        setAdo(selectedAdo);
        setPartnerName(selectedAdo)
        filterByAdo(selectedAdo);

        if (selectedAdo === 'Admin') {
            setPartnerId(-1);
        } else {
            const partner = partnerData.find(partner => partner.name === selectedAdo);
            if (partner) {
                setPartnerId(partner.id);
            }
        }
    };

    return (
        <div className='sm:mt-0 mt-2'>
            <Select
                options={adminViewOptions}
                value={adminViewOptions.find(option => option.value === ado)}
                onChange={handleSelectChange}
                placeholder="Select ADO"
                isClearable={false}
                styles={{
                    control: (base) => ({
                        ...base,
                        borderColor: '#0A5F75',
                        boxShadow: 'none',
                        height: '36px',
                        minHeight: '36px',
                        borderRadius: '7px',
                        borderWidth: '2px',
                        width: '25rem',
                        marginRight: '1rem',
                        cursor: 'pointer',
                        '&:hover': {
                            borderColor: '#0B82A0',
                        },
                    }),
                    valueContainer: (base) => ({
                        ...base,
                        fontSize: '0.875rem',
                        padding: '0 0.5rem',
                        minHeight: '2rem',
                        height: '2rem',
                    }),
                    placeholder: (base) => ({
                        ...base,
                        color: '#0A5F75',
                        fontSize: '0.875rem',
                        fontWeight: 'bold',
                    }),
                    singleValue: (base) => ({
                        ...base,
                        color: '#0A5F75',
                        fontSize: '0.875rem',
                        fontWeight: 'bold'
                    }),
                    dropdownIndicator: (base) => ({
                        ...base,
                        color: '#0A5F75',
                        padding: '0',
                        '& hover': {
                            color: '#0B82A0'
                        }
                    }),
                    clearIndicator: (base) => ({
                        ...base,
                        padding: '0',
                    }),
                    indicatorsContainer: (base) => ({
                        ...base,
                        padding: '0 0.25rem',
                    }),
                    option: (base, state) => ({
                        ...base,
                        fontSize: '0.875rem',
                        backgroundColor: state.isSelected ? '#4D4D4D' : state.isFocused ? '#E2E8F0' : 'white',
                        borderRadius: '4px',
                        borderBottom: '1px solid #E2E8F0',
                        color: state.isSelected ? 'white' : '#4D4D4D',
                        '&:hover': {
                            backgroundColor: '#E2E8F0',
                            color: '#0A5F75'
                        },
                    }),
                    menu: (base) => ({
                        ...base,
                        width: '25rem',
                        position: 'absolute',
                        left: '0',
                        top: '2rem',
                        zIndex: 9999,
                        boxShadow: '0 8px 16px 0 rgba(0, 0, 0, 0.15)',
                        
                    }),
                    menuList: (base) => ({
                        ...base,
                        padding: '4px',
                        maxHeight: '200px',
                        overflowY: 'auto',
                        '::-webkit-scrollbar': {
                            width: '8px',
                        },
                        '::-webkit-scrollbar-track': {
                            background: '#ffffff',
                            borderRadius: '4px',
                        },
                        '::-webkit-scrollbar-thumb': {
                            background: '#E8E8E8',
                            borderRadius: '4px',
                            height: '50px',
                            padding: '10px'
                        },
                    }),
                }}
            />
        </div>
    );
}

export default AdminViewSelect;