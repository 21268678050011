// TypesContext.tsx
import { createContext, useState, useEffect, ReactNode } from 'react';
import axios from 'axios';
import { TypesData } from '../services/types';
import * as Sentry from '@sentry/react'

interface TypesContextType {
    typesData: TypesData;
    fetchTypes: () => void;
}

const defaultTypesData: TypesData = {
    assistance_types: [],
    classification_types: [],
    contact_types: [],
    interaction_types: [],
    initiative_types: [],
    industry_types: [],
    partner_locations: []
};

const TypesContext = createContext<TypesContextType>({
    typesData: defaultTypesData,
    fetchTypes: () => {},
});

interface TypesProviderProps {
    children: ReactNode;
}

export const TypesProvider = ({ children }: TypesProviderProps) => {
    const [typesData, setTypesData] = useState<TypesData>(defaultTypesData);
    const apiUrl = `${import.meta.env.VITE_COMMERCE_BACKEND_URL}/api/gettypes/`;

    const fetchTypes = async () => {
        try {
            const response = await axios.get(apiUrl);
            if (response.status === 200) {
                const data = response.data.data;
                setTypesData(data.types);
            } else {
                // Log the error response to Sentry
                Sentry.captureMessage(`Error fetching types: ${response.data.message}`, 'warning');
                console.error('Error fetching types:', response.data);
            }
        } catch (error) {
            // Log the caught error to Sentry
            Sentry.captureException(error);
            console.error('Error fetching types:', error);
        }
    };

    useEffect(() => {
        fetchTypes();
    }, []);

    return (
        <TypesContext.Provider value={{ typesData, fetchTypes }}>
            {children}
        </TypesContext.Provider>
    );
};

export default TypesContext;
