// Create a context to manage iframe state
import { createContext, useContext, useState, useEffect, ReactNode } from 'react';

interface IframeContextType {
    isIframe: boolean;
}

const IframeContext = createContext<IframeContextType>({ isIframe: false });

interface IframeProviderProps {
    children: ReactNode;
}

// Define a provider for the context
export const IframeProvider = ({ children }: IframeProviderProps) => {
    const [isIframe, setIsIframe] = useState(false);

    useEffect(() => {
        setIsIframe(window.self !== window.top);
    }, []);

    return (
        <IframeContext.Provider value={{ isIframe }}>
            {children}
        </IframeContext.Provider>
    );
};

// Export a hook to use the context
export const useIframeContext = () => useContext(IframeContext);