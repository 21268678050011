import { useNavigate } from "react-router-dom";

function AddProjectButton() {
    const navigate = useNavigate();
    const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
        navigate('/commerce-projects/addproject', { state: { from: window.location.pathname }});
        console.log('button clicked:', event);
    };

    return ( 
        <span className="flex items-center lg:mt-0 mt-2">
        <button
            id="add-project"
            onClick={handleClick}
            className="w-36 px-4 text-white font-bold text-sm bg-button-blue border border-solid border-button-blue rounded-md focus:outline-none focus:border-blue-500 hover:border-color-cai-blue hover:bg-color-cai-blue h-[36px] appearance-none text-center"
        > + Add Project
        </button>
        </span>
    );
}

export default AddProjectButton;