import { createContext, useState, useEffect } from 'react';
import axios from 'axios';
import { AdminProviderProps, OutcomeData, OutcomesDataContextType } from '../services/types';
import * as Sentry from '@sentry/react';


const OutcomesDataContext = createContext<OutcomesDataContextType>({
    outcomes: [],
    fetchOutcomes: () => {},
});

export const OutcomesDataProvider: React.FC<AdminProviderProps> = ({ children }: AdminProviderProps) => {
    const [outcomes, setOutcomes] = useState<OutcomeData[]>([]);
    const apiUrl = `${import.meta.env.VITE_COMMERCE_BACKEND_URL}/api/getoutcomes`;

    const fetchOutcomes = async () => {
        try {
            const response = await axios.get(apiUrl);
            if (response.status === 200) {
                const data = response.data.data;
                setOutcomes(data);
            } else {
                // Log the error response to Sentry
                Sentry.captureMessage(`Error fetching outcomes: ${response.data.message}`, 'warning');
                console.error('Error fetching outcomes:', response.data);
            }
        } catch (error) {
            // Log the caught error to Sentry
            Sentry.captureException(error);
            console.error('Error fetching outcomes:', error);
        }
    };

    useEffect(() => {
        fetchOutcomes();
    }, []);

    return (
        <OutcomesDataContext.Provider value={{ outcomes, fetchOutcomes }}>
            {children}
        </OutcomesDataContext.Provider>
    );
};

export default OutcomesDataContext;
