import targetSvg from '../../assets/summary-target.svg';
import checkboxSvg from '../../assets/checkbox-checked.svg';
import { addCommas } from "../../utils/helpers";
import { useAdminContext } from '../../context/AdminContext';

interface Totals {
    bre: number;
    recruitment: number;
    training: number;
    startup: number;
    export: number;
    marketing_plan_required: number;
    marketing_plan_received: number;
}

interface InteractionSummaryProps {
    totalInteractions: Totals;
    totalTargets: Totals;
};


const InteractionSummary = ({ totalInteractions, totalTargets }: InteractionSummaryProps) => {
    const { isAdmin } = useAdminContext();

    const getFiscalYear = () => {
        const currentMonth = new Date().getMonth() + 1;
        const currentYear = new Date().getFullYear();

        let fiscalYear;
        if (currentMonth >= 7) {
            fiscalYear = currentYear + 1;
        } else {
            fiscalYear = currentYear;
        }

        return fiscalYear;
    }
    
    // Grab the last two of the current fiscal year
    const currentFisYear = getFiscalYear().toString().slice(2);

    const rows = [
        { title: "BRE", interactions: totalInteractions.bre, targets: totalTargets.bre },
        { title: "Recruitment", interactions: totalInteractions.recruitment, targets: totalTargets.recruitment },
        { title: "Training", interactions: totalInteractions.training, targets: totalTargets.training },
        { title: "Startup Support", interactions: totalInteractions.startup, targets: totalTargets.startup },
        { title: "Export Assistance", interactions: totalInteractions.export, targets: totalTargets.export },
        { title: "Marketing Plan", interactions: totalTargets.marketing_plan_received, targets: totalTargets.marketing_plan_required },
    ];

    // Calculate progress percentage
    const calculatePercentage = (interactions: number, targets: number) => {
        return targets !== 0 ? ((interactions / targets) * 100).toFixed(0) : 0;
    };

    return (
        <div className="overflow-x-auto">
            <p className='text-gotham-gray text-sm ml-2'>Achievement details for current fiscal year.</p>
            <table className="min-w-full">
                <thead>
                    <tr className="text-center">
                        <th className="text-left py-1 px-2">Interactions FY{currentFisYear}</th>
                        <th className="py-1 px-3">
                            <img src={checkboxSvg} alt='checkbox icon' className="w-6 h-6 inline" />
                        </th>
                        <th className="py-1 px-3">
                            <img src={targetSvg} alt='target icon' className="w-6 h-6 inline" />
                        </th>
                        <th className="text-xl font-extrabold py-1 px-3">%</th>
                    </tr>
                </thead>
                <tbody>
                    {rows.map((row, index) => {

                        const percentage = calculatePercentage(row.interactions, row.targets);
                        const isMarketingPlanComplete = row.title === "Marketing Plan" && percentage === '100';
                        
                        return (
                            <tr key={index} className="text-center">
                                <td className="text-sm text-left py-1 px-2">{row.title}</td>
                                <td className="text-sm py-1 px-3">
                                    {isMarketingPlanComplete && !isAdmin ? (
                                        <img src={checkboxSvg} alt='checkbox icon' className="w-5 h-5 inline" />
                                    ) : (
                                        addCommas(row.interactions)
                                    )}
                                </td>
                                <td className="text-sm py-1 px-3">
                                    {isMarketingPlanComplete && !isAdmin ? (
                                        null
                                    ) : (
                                        addCommas(row.targets)
                                    )}
                                </td>
                                <td className="text-sm py-1 px-3">
                                    {isMarketingPlanComplete && !isAdmin ? (
                                        null
                                    ) : (
                                        `${percentage}%`
                                    )}
                                </td>
                            </tr>
                        )
                    })}
                </tbody>
            </table>
        </div>
    );
};

export default InteractionSummary;
