import { useNavigate } from "react-router-dom";

interface AddInteractionButtonProps {
    id?: string;
}

function AddInteractionButton({ id }: AddInteractionButtonProps) {
    const navigate = useNavigate();
    const handleClick = () => {
        if (id) {
            navigate('/commerce-interactions/addinteraction', {state: {id}});
        } else navigate('/commerce-interactions/addinteraction');
    };

    return ( 
        <span className="flex items-center mt-2 sm:mt-0">
        <button
            id="add-interaction"
            onClick={handleClick}
            className="w-40 px-3 mr-4 text-white font-bold text-sm bg-button-blue border border-solid border-button-blue rounded-md focus:outline-none focus:border-blue-500 hover:border-color-cai-blue hover:bg-color-cai-blue h-[36px] appearance-none text-center"
        > + Add Interaction
        </button>
        </span>
    );
}

export default AddInteractionButton;