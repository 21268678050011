import { useEffect, useState } from 'react';
// import editIcon from '/src/assets/edit.svg';
import deleteIcon from '/src/assets/delete.svg';
import upArrow from '../../assets/sort-arrow.svg';
import { useNavigate } from 'react-router-dom';
import { generatePageNumbers } from '../../utils/helpers.ts';
import { InitiativeData } from '../../services/types.ts';
import { useAdminContext } from '../../context/AdminContext.tsx';
import { useOrganizationContext } from '../../context/OrganizationContext.tsx';

interface InitiativeTableProps {
    data: InitiativeData[];
}

// ProjectTable - Renders the Projects table within the Projects view (ADO/Admin)
function ProjectTable({ data }: InitiativeTableProps) {
    const { isAdmin } = useAdminContext(); // All ADO Context (Diana's view)
    const { admin } = useOrganizationContext(); // CAI Admin
    const [projectData, setProjectData] = useState<InitiativeData[]>([]);
    const [sortConfig, setSortConfig] = useState<{ key: string, direction: 'asc' | 'desc' | null }>({ key: '', direction: null });
    const [currentPage, setCurrentPage] = useState(1);
    const rowsPerPage = 10;

    const navigate = useNavigate();

    useEffect(() => {
        if (Array.isArray(data)) {
            setProjectData(data);
            setSortConfig({ key: 'name', direction: 'asc' });
        } else {
            console.error('projectData is not an array:', data);
        }
    }, [data]);

    const handleEditClick = (id: number) => {
        const rowData = data.find(row => row.id === id);

        if (rowData) {
            navigate(`/commerce-projects/detail/${id}`);            
        }
    }

    const handleDeleteClick = async (id: number) => {
        const rowData = data.find(row => row.id === id);
        navigate(`/commerce-projects/delete/${id}`, { state: { rowData } });
    }

    const handlePageClick = (page: number) => {
        setCurrentPage(page);
    };

    // Handle sorting
    const handleSortClick = (key: string) => {
        let direction: 'asc' | 'desc' = 'asc';
        if (sortConfig.key === key && sortConfig.direction === 'asc') {
            direction = 'desc';
        }
        setSortConfig({ key, direction });
    };

    // Sort data based on sortConfig
    useEffect(() => {
        if (sortConfig.key) {
            const sortedData = [...data].sort((a, b) => {
                let aVal, bVal;

                if (sortConfig.key === 'initiative_type') {
                    const aInitiativeType = a.initiative_type || {};
                    const bInitiativeType = b.initiative_type || {};
                    aVal = aInitiativeType.display_name || '';
                    bVal = bInitiativeType.display_name || '';
                } else {
                    // Default case for other fields in InteractionData
                    aVal = a[sortConfig.key as keyof InitiativeData];
                    bVal = b[sortConfig.key as keyof InitiativeData];
                }
    
                // Ensure that empty or null values are pushed to the end
                if ((aVal === null || aVal === '') && (bVal === null || bVal === '')) return 0;
                if (aVal === null || aVal === '') return sortConfig.direction === 'asc' ? 1 : -1;
                if (bVal === null || bVal === '') return sortConfig.direction === 'asc' ? -1 : 1;

                // Standard comparison logic
                if (aVal < bVal) return sortConfig.direction === 'asc' ? -1 : 1;
                if (aVal > bVal) return sortConfig.direction === 'asc' ? 1 : -1;
                return 0;
            });
            setProjectData(sortedData);
        }
    }, [sortConfig, data]);

    // Pagination Logic
    const totalPages = Math.ceil(projectData.length / rowsPerPage);
    const pageNumbersToShow = generatePageNumbers(totalPages, currentPage);

    const startIndex = (currentPage - 1) * rowsPerPage;
    const endIndex = Math.min(startIndex + rowsPerPage, projectData.length);
    const paginatedData = projectData.slice(startIndex, endIndex);

    return (
        <div>
            <div className="overflow-x-auto border border-solid border-titanium-white rounded-lg">
                <table className="min-w-full">
                    <thead className="bg-spanish-gray text-white rounded-t-lg">
                        <tr>
                            {/* <th className="border-b border-titanium-white px-4 py-2 text-left text-sm min-w-72">ADO</th> */}
                            {/* <th className="border-b border-border-color-at-white px-4 py-2 text-left text-sm w-28">Year</th> */}
                            <th 
                                className="border-b border-titanium-white text-xs font-bold px-4 py-2 text-left"
                                onClick={() => handleSortClick('name')}
                            >
                                <div className='flex items-center hover:cursor-pointer'>
                                Project
                                {sortConfig.key === 'name' && (
                                    <img 
                                        src={upArrow} 
                                        alt='sort arrow' 
                                        className={`transform ${sortConfig.direction === 'desc' ? 'rotate-180' : ''}`} 
                                    />
                                )}
                                </div>
                            </th>
                            <th 
                                className="border-b border-titanium-white text-xs font-bold px-4 py-2 text-left"
                                onClick={() => handleSortClick('description')}
                            >
                                <div className='flex items-center hover:cursor-pointer'>
                                Description
                                {sortConfig.key === 'description' && (
                                    <img 
                                        src={upArrow} 
                                        alt='sort arrow' 
                                        className={`transform ${sortConfig.direction === 'desc' ? 'rotate-180' : ''}`} 
                                    />
                                )}
                                </div>
                            </th>
                            {isAdmin && <th 
                                className="border-b border-titanium-white text-xs font-bold px-4 py-2 text-left"
                                onClick={() => handleSortClick('partner_name')}
                            >
                                <div className='flex items-center hover:cursor-pointer'>
                                ADO
                                {sortConfig.key === 'partner_name' && (
                                    <img 
                                        src={upArrow} 
                                        alt='sort arrow' 
                                        className={`transform ${sortConfig.direction === 'desc' ? 'rotate-180' : ''}`} 
                                    />
                                )}
                                </div>
                            </th>}
                            {isAdmin && <th 
                                className="border-b border-titanium-white text-xs font-bold px-4 py-2 text-left min-w-36 max-w-36"
                                onClick={() => handleSortClick('initiative_type')}
                            >
                                <div className='flex items-center hover:cursor-pointer'>
                                Type
                                {sortConfig.key === 'initiative_type' && (
                                    <img 
                                        src={upArrow} 
                                        alt='sort arrow' 
                                        className={`transform ${sortConfig.direction === 'desc' ? 'rotate-180' : ''}`} 
                                    />
                                )}
                                </div>
                            </th>}
                            <th className="border-b border-titanium-white text-xs font-bold px-4 py-2 text-left min-w-16 max-w-16">Year</th>
                            <th 
                                className="border-b border-titanium-white text-xs font-bold px-4 py-2 text-right text-nowrap min-w-36 max-w-36"
                                onClick={() => handleSortClick('total_outcomes')}
                            >
                                <div className='flex items-center justify-end hover:cursor-pointer'>
                                {sortConfig.key === 'total_outcomes' && (
                                    <img 
                                        src={upArrow} 
                                        alt='sort arrow' 
                                        className={`transform ${sortConfig.direction === 'desc' ? 'rotate-180' : ''}`} 
                                    />
                                )}
                                Total Outcomes
                                </div>
                            </th>
                            <th 
                                className="border-b border-titanium-white text-xs font-bold px-4 py-2 text-right text-nowrap min-w-36 max-w-36"
                                onClick={() => handleSortClick('total_interactions')}
                            >
                                <div className='flex items-center justify-end hover:cursor-pointer'>
                                {sortConfig.key === 'total_interactions' && (
                                    <img 
                                        src={upArrow} 
                                        alt='sort arrow' 
                                        className={`transform ${sortConfig.direction === 'desc' ? 'rotate-180' : ''}`} 
                                    />
                                )}
                                Total Interactions
                                </div>
                            </th>
                            <th 
                                className="border-b border-titanium-white text-xs font-bold px-4 py-2 text-end min-w-20 max-w-20"
                                onClick={() => handleSortClick('status')}
                            >
                                <div className='flex items-center justify-end hover:cursor-pointer'>
                                {sortConfig.key === 'status' && (
                                    <img 
                                        src={upArrow} 
                                        alt='sort arrow' 
                                        className={`transform ${sortConfig.direction === 'desc' ? 'rotate-180' : ''}`} 
                                    />
                                )}
                                Status
                                </div>
                            </th>
                            <th className="border-b border-titanium-white text-xs font-bold px-4 py-2 text-center">Delete</th>
                        </tr>
                    </thead>
                    <tbody>
                        {paginatedData.map((row) => (
                        <tr key={row.id} onClick={(e) => { e.stopPropagation(); handleEditClick(row.id) }} className="border-b border-solid border-titanium-white last:border-none h-10 hover:bg-at-white hover:text-black hover:cursor-pointer">
                            <td className="px-4 py-2 text-gotham-gray text-sm text-left min-w-48 max-w-48">{row.name}</td>
                            <td className="px-4 py-2 text-gotham-gray text-sm text-left min-w-96 max-w-96 truncate"> {row.description}</td>
                            {isAdmin && <td className="px-4 py-2 text-gotham-gray text-sm text-left min-w-36 max-w-36">{row.partner_name}</td>}
                            {isAdmin && <td className="px-4 py-2 text-gotham-gray text-sm text-left min-w-40 max-w-40">{row.initiative_type?.name}</td>}
                            <td className="px-4 py-2 text-gotham-gray text-sm text-left min-w-16 max-w-16">{row.year}</td>
                            <td className="px-4 py-2 text-gotham-gray text-sm text-end min-w-16 max-w-16">{row.total_outcomes}</td>
                            <td className="px-4 py-2 text-gotham-gray text-sm text-end min-w-16 max-w-16">{row.total_interactions}</td>
                            <td className="px-4 py-2 text-gotham-gray text-sm text-end min-w-16 max-w-16">{row.status.charAt(0).toUpperCase() + row.status.slice(1).toLowerCase()}</td>
                            <td className="w-24 py-2 pl-2">
                                <div className='w-24 flex items-center justify-center'>
                                    {/* <button onClick={(e) => { e.stopPropagation(); handleEditClick(row.id) }} className="mr-3 flex items-center">
                                        <img className="border border-solid border-button-blue h-6 w-6 p-1 rounded-md" src={editIcon} alt="Edit" />
                                    </button> */}
                                    {isAdmin || admin || (!isAdmin && !row.admin_managed) ? (
                                        <button onClick={(e) => { e.stopPropagation(); handleDeleteClick(row.id) }}
                                            className="flex items-center mr-3">
                                            <img className="border border-solid border-button-blue h-6 w-6 p-1 rounded-md" src={deleteIcon} alt="Delete" />
                                        </button>
                                    ) : null}
                                </div>
                            </td>
                        </tr>
                        ))}
                    </tbody>
                </table>
            </div>
            {pageNumbersToShow.length > 1 && 
            <div className="flex justify-center mt-3">
                <div className="border border-solid border-at-white rounded-md flex">
                    <button
                        className="text-gotham-gray font-bold text-xs border-r border-solid border-at-white px-2 py-1"
                        onClick={() => handlePageClick(Math.max(currentPage - 1, 1))}
                        disabled={currentPage === 1}
                    >
                        « Previous
                    </button>
                    {pageNumbersToShow.map((pageNumber, index) => (
                        <button
                            key={index}
                            className={`text-gotham-gray font-bold text-xs border-r border-solid border-at-white px-3 py-1 ${pageNumber === currentPage ? 'bg-titanium-white' : ''}`}
                            onClick={() => handlePageClick(typeof pageNumber === 'number' ? pageNumber : currentPage)}
                        >
                            {pageNumber}
                        </button>
                    ))}
                    <button
                        className="text-gotham-gray font-bold text-xs px-2 py-1"
                        onClick={() => handlePageClick(Math.min(currentPage + 1, totalPages))}
                        disabled={currentPage === totalPages}
                    >
                        Next »
                    </button>
                </div>
            </div>
            }
            
        </div>
    );
}

export default ProjectTable;