import leftArrow from '../../assets/left-arrow.svg';
import { useIframeContext } from '../../context/IframeContext';
import Expand from './Expand';

interface BackButtonHeaderProps {
    navigateBack: () => void;
    name: string;
}

const BackButtonHeader: React.FC<BackButtonHeaderProps> = ({ navigateBack, name }) => {
    const { isIframe } = useIframeContext();

    return (
        <div className='bg-lime-green w-full px-4 py-1'>
        <div className='flex justify-between mx-2 my-2'>
            <button onClick={navigateBack} className="bg-white rounded-md px-3 h-[36px] flex items-center font-semibold text-sm hover:border hover:border-color-cai-blue">
                <img src={leftArrow} alt='left arrow' className='h-3 w-3 mr-3' />
                <p className='text-button-blue text-xs'>Back to {name}</p>
            </button>
            {isIframe && (
                <div className="flex ml-auto">
                    <Expand />
                </div>
            )}
        </div>
        </div>
    );
};

export default BackButtonHeader;