import { createContext, useState, useEffect } from 'react';
import axios from 'axios';
import { AdminProviderProps, InitiativeData, InitiativeDataContextType } from '../services/types';
import * as Sentry from '@sentry/react';



const InitiativeDataContext = createContext<InitiativeDataContextType>({
    initiatives: [],
    fetchInitiatives: () => {},
});

export const InitiativeDataProvider = ({ children }: AdminProviderProps) => {
    const [initiatives, setInitiatives] = useState<InitiativeData[]>([]);
    const apiUrl = `${import.meta.env.VITE_COMMERCE_BACKEND_URL}/api/getinitiatives`;

    const fetchInitiatives = async () => {
        try {
            const response = await axios.get(apiUrl);
            if (response.status === 200) {
                const data = response.data.data;
                setInitiatives(data);
            } else {
                // Log the error response to Sentry
                Sentry.captureMessage(`Error fetching Initiatives: ${response.data.message}`, 'warning');
                console.error('Error fetching Initiatives:', response.data);
            }
        } catch (error) {
            // Log the caught error to Sentry
            Sentry.captureException(error);
            console.error('Error fetching Initiatives:', error);
        }
    };

    useEffect(() => {
        fetchInitiatives();
    }, []);

    return (
        <InitiativeDataContext.Provider value={{ initiatives, fetchInitiatives }}>
            {children}
        </InitiativeDataContext.Provider>
    );
};

export default InitiativeDataContext;
