import { IndustryType, LocationType, PartnerLocation, TypesAttributes, TypesData } from "../services/types";
import { calculateAvailableYears } from "./helpers";

// Assuming status can be 'active' or 'inactive'
export const getTypeOptions = (
    typesData: TypesData,
    key: string,
    currentTypeValue: string | string[] | undefined
) => {
    const data = typesData[key as keyof TypesData];
    if (!data) {
        console.warn(`Key "${key}" not found in typesData.`);
        return [];
    }
    // Get active options
    const activeOptions = data
        .filter((type: TypesAttributes) => type.status === 'active')
        .map((type: TypesAttributes) => ({
            value: type.id,
            label: type.name,
        }));

    // Function to find and add an inactive type if it exists
    const addInactiveTypeOption = (value: string) => {
        const currentType = data.find((type: TypesAttributes) => type.id === Number(value));
        if (currentType && currentType.status === 'inactive') {
            activeOptions.push({
                value: currentType.id,
                label: currentType.name,
            });
        }
    };

    // Handle the case where currentTypeValue is a string
    if (typeof currentTypeValue === 'string') {
        addInactiveTypeOption(currentTypeValue);
    }

    // Handle the case where currentTypeValue is an array of strings
    if (Array.isArray(currentTypeValue)) {
        currentTypeValue.forEach(addInactiveTypeOption);
    }

    return activeOptions;
};

export const getIndustryTypeOptions = (
    typesData: TypesData,
    key: string,
    currentTypeValue: string | string[] | undefined
) => {
    const data = typesData[key as keyof TypesData];
    if (!data) {
        console.warn(`Key "${key}" not found in typesData.`);
        return [];
    }

    // Map the options using the 'code' as the value and 'name' as the label
    const options = data.map((type: IndustryType) => ({
        value: type.code, // Use 'code' instead of 'id'
        label: type.name,
    }));

    // If currentTypeValue is a string, ensure it's included in the options
    if (typeof currentTypeValue === 'string') {
        const currentType = data.find((type: IndustryType) => Number(type.code) === Number(currentTypeValue));
        if (currentType && !options.some((option: any) => option.value === currentTypeValue)) {
            options.push({
                value: currentType.code,
                label: currentType.name,
            });
        }
    }

    // If currentTypeValue is an array, ensure each value is included in the options
    if (Array.isArray(currentTypeValue)) {
        currentTypeValue.forEach(value => {
            const currentType = data.find((type: IndustryType) => type.code === value);
            if (currentType && !options.some((option: any) => option.value === value)) {
                options.push({
                    value: currentType.code,
                    label: currentType.name,
                });
            }
        });
    }

    return options;
};


export const getSubIndustryOptions = (industry?: IndustryType) => {
    if (!industry) {
        return [];
    }
    return industry.sub_industries.map(subIndustry => ({
        value: subIndustry.code,
        label: subIndustry.name,
    }));
};

export const getPartnerLocations = (partnerId: number, typesData: TypesData): LocationType[] => {
    const partner = typesData.partner_locations.find((p: PartnerLocation) => p.partner_id === partnerId);
    return partner ? partner.locations : [];
};

// Transform locations into the format expected by the Select component
export const transformLocationsToOptions = (locations: LocationType[]): { value: string, label: string }[] => {
    return locations.map(location => ({
        value: location.id,
        label: location.name
    }));
};

// Initiatives Form Options
const availableYears = calculateAvailableYears();
export const yearOptions = availableYears.map(year => ({ value: year, label: year.toString() }));

export const statusOptions = [
    { value: 'active', label: 'Active' },
    { value: 'inactive', label: 'Inactive' }
];

export const monthOptions = [
    { value: true, label: 'Yes' },
    { value: false, label: 'No' }
];

export const initiativePurposeOptions = [
    { value: 'project', label: 'A Project "win" serving an individual for-profit business' },
    { value: 'initiative', label: 'An Initiative used for strategic purposes' }
];

export const serviceAreaOptions = [
    { value: 'Full ADO Area', label: 'Full ADO Area'},
    { value: 'Selected Area within ADO', label: 'Selected Area within ADO'},
];