import { useState, MouseEvent, useEffect } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import carrotWhite from '../../assets/carrot-white.svg';
import { useAdminContext } from '../../context/AdminContext';

type ReportingType = 'targets' | 'initiatives' | 'projects' | 'summary' | 'interactions';

const reportingOptions: { [key: string]: string } = {
    targets: 'ADO Reporting: Targets',
    initiatives: 'ADO Reporting: Initiatives',
    projects: 'ADO Reporting: Projects',
    summary: 'ADO Reporting: Summary Dashboard',
    interactions: 'ADO Reporting: Interactions'
  };

/**
 * ReportingDropdown Component
 *
 * Renders a button that displays the current reporting option.
 * When the button is clicked, it navigates to the main page ("/").
 * Clicking the carrot icon toggles the visibility of a custom dropdown menu with additional reporting options.
 * Selecting an option from the dropdown updates the state and navigates to the corresponding route.
 */
function ReportingDropdown() {
  const { isAdmin } = useAdminContext();
  const [reportingType, setReportingType] = useState('');
  const [showDropdown, setShowDropdown] = useState(false);
  const navigate = useNavigate();
  const location = useLocation();

  useEffect(() => {
    // set the reporting type of the dropdown according to the current page
    const path = location.pathname.split('/');
    const reportingSegment = path.find(segment => segment.startsWith('commerce-'));
    if (reportingSegment) {
      const type = reportingSegment.replace('commerce-', '');
      if (type) {
        setReportingType(type as ReportingType);
      }
    }
  }, [location.pathname]);

  const handleOptionClick = (value: ReportingType) => {
    setReportingType(value);
    navigate(`/commerce-${value}`);
    setShowDropdown(false);
  };

  const handleButtonClick = (event: MouseEvent<HTMLButtonElement>) => {
    event.preventDefault();
    navigate('/');
  };

  const toggleDropdown = (event: MouseEvent<HTMLImageElement>) => {
    event.stopPropagation();
    setShowDropdown((prevShowDropdown) => !prevShowDropdown);
  };

  // Determine available options based on isAdmin
  const availableOptions: ReportingType[] = isAdmin 
    ? ['targets', 'initiatives', 'projects', 'summary'] 
    : ['initiatives', 'projects', 'interactions', 'summary'];

  return (
    <div className='bg-lime-green w-full px-4 py-1'>
      <span className="mx-2 my-2 flex relative">
        <button
          onClick={handleButtonClick}
          className="w-[31.5rem] h-[39.39px] max-w-sm ml-[10.5px] sm:ml-0 px-4 py-2 text-black text-[15.6px] bg-white border border-solid border-white rounded-[5px] focus:outline-none flex justify-between items-center hover:bg-petrol hover:text-white hover:border-petrol"
        >
          {/* {reportingOptions[reportingType]} */}
          <span className="flex items-center">
            {reportingType ? (
              <>
                <strong>ADO Reporting</strong>: {reportingOptions[reportingType].split(': ')[1]}
              </>
            ) : (
              <strong>ADO Reporting</strong>
            )}
          </span>
          <img
            src={carrotWhite}
            alt="Dropdown Carrot"
            onClick={toggleDropdown}
            className={`h-4 w-4 ml-2 cursor-pointer transition-transform duration-200 ${showDropdown ? 'transform rotate-180' : ''}`}
          />
        </button>
        {showDropdown && (
          <div
            className="absolute w-96 ml-[10.5px] mt-2 py-2 text-black text-sm bg-white border border-solid border-[#E2E8F0] rounded-[5px] z-10"
            style={{ top: '100%' }}
          >
            {availableOptions.map((value: ReportingType) => (
              <div
                key={value}
                onClick={() => handleOptionClick(value as ReportingType)}
                className="px-4 py-2 text-black hover:bg-petrol hover:text-white cursor-pointer"
              >
                {reportingOptions[value as ReportingType]}
              </div>
            ))}
          </div>
        )}
      </span>
    </div>
  );
}

export default ReportingDropdown;