type NoResultsMessageProps = {
    itemName: string; 
    hasFilters: boolean;
    onRemoveFilters: () => void; 
  };
  
const NoResultsMessage: React.FC<NoResultsMessageProps> = ({ itemName, hasFilters, onRemoveFilters }) => {
    return (
      <div className="text-color-cai-dashboard flex flex-col p-16 bg-at-white justify-center items-center">
        <p>No {itemName} found that match current filters.</p>
        <p className="text-blue-600 text-sm cursor-pointer hover:underline" onClick={onRemoveFilters}>
          {hasFilters ? `Remove filters or add an ${itemName.slice(0, itemName.length - 1)} to see more results.` : `Add an ${itemName.slice(0, itemName.length - 1)} to see more results.`}
        </p>
      </div>
    );
  };
  
  export default NoResultsMessage;