import { useLocation, useNavigate } from 'react-router-dom';
import { useContext, useEffect, useRef, useState } from 'react';
import { FileHistoryType } from '../../services/types';
import dotIcon from '../../assets/three-dot.svg';
import InitiativeDataContext from '../../context/InitiativeDataContext';
import Expand from '../../components/Navigation/Expand';
import { useIframeContext } from '../../context/IframeContext';
import BackButtonHeader from '../../components/Navigation/BackButtonHeader';

function UploadHistory() {
    const { initiatives } = useContext(InitiativeDataContext);
    const { isIframe } = useIframeContext();
    const [fileHistory, setFileHistory] = useState<FileHistoryType[]>([]);
    const [openDropdownIndex, setOpenDropdownIndex] = useState<number | null>(null);
    
    const dropdownRef = useRef<HTMLDivElement>(null);
    const navigate = useNavigate();
    const location = useLocation();
    const { initiativeId } = location.state;
    console.log('initiativeId:', initiativeId);

    const initiative = initiatives.find(initiative => initiative.id === Number(initiativeId));
    const partnerId = Number(initiative?.partner_id);

    const navigateBack = () => {
        navigate(`/commerce-initiatives/detail/${initiativeId}`);
    }

    useEffect(() => {
        const fetchPartnerData = async (partnerId: number) => {
            const url = `${import.meta.env.VITE_COMMERCE_BACKEND_URL}/api/uploadhistory/${partnerId}`;

            try {
                const response = await fetch(url, {
                    method: 'GET',
                    headers: {
                        'Content-Type': 'application/json',
                    },
                });

                if (!response.ok) {
                    throw new Error('Network response was not ok');
                }

                const data = await response.json();
                
                // Transform data to match the expected format
                const transformedData = data.map((item: any) => ({
                    name: item.file_name,
                    type: item.file_type,
                    upload_id: item.upload_id,
                    status: item.status,
                    creation_date: item.creation_date,
                    record_count: item.record_count
                }));

                // sort file history by date
                
                const sortedData = transformedData.sort((a: FileHistoryType, b: FileHistoryType) => {
                    const dateA = new Date(a.creation_date).getTime();
                    const dateB = new Date(b.creation_date).getTime();
                    return dateB - dateA;
                });
                setFileHistory(sortedData);
                console.log('File history successfully retrieved from backend:', data);
            } catch (error) {
                console.error('Error retrieving data from backend:', error);
            }
        };

        fetchPartnerData(partnerId);
    }, [partnerId]);

    const handleEditClick = (id: number) => {
        const selected = fileHistory[id];
        console.log('Clicked:', selected);
        
            const fetchPendingUpload = async (uploadId: number) => {
                console.log('upload id:', uploadId);
                const url = `${import.meta.env.VITE_COMMERCE_BACKEND_URL}/api/getinteractionsbyid/${selected.upload_id}`;
    
                try {
                    const response = await fetch(url, {
                        method: 'GET',
                        headers: {
                            'Content-Type': 'application/json',
                        },
                    });
    
                    if (!response.ok) {
                        throw new Error('Network response was not ok');
                    }
    
                    const data = await response.json();
                    // setInteractionData(data);
                    console.log('Pending File Data successfully retrieved from backend:', data);

                    navigate('/upload-interactions/validate', { state: { data, file: selected, initiativeId, from: window.location.pathname } });

                } catch (error) {
                    console.error('Error retrieving data from backend:', error);
                }
            };
    
            fetchPendingUpload(selected.upload_id);
    }

    const handleDropdownToggle = (index: number) => {
        setOpenDropdownIndex(openDropdownIndex === index ? null : index);
    }

    // Close dropdown when clicking outside of it
    useEffect(() => {
        const handleClickOutside = (event: MouseEvent) => {
            if (dropdownRef.current && !dropdownRef.current.contains(event.target as Node)) {
                setOpenDropdownIndex(null);
            }
        };

        document.addEventListener('mousedown', handleClickOutside);

        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, []);

    const handleDeleteClick = async (index: number) => {
        const selected = fileHistory[index];

        if (!selected.upload_id) return;

        const url = `${import.meta.env.VITE_COMMERCE_BACKEND_URL}/api/deletefilebyid/${selected.upload_id}`;

        try {
            const response = await fetch(url, {
                method: 'DELETE',
                headers: {
                    'Content-Type': 'application/json',
                },
            });

            if (!response.ok) {
                throw new Error('Network response was not ok');
            }

            console.log(`File with upload id ${selected.upload_id} successfully deleted`);

            // Update the fileHistory state to remove the deleted record
            setFileHistory(prevFileHistory => prevFileHistory.filter(file => file.upload_id !== selected.upload_id));

        } catch (error) {
            console.error('Error retrieving data from backend:', error);
        }
    }

    return ( 
        <>
            <BackButtonHeader navigateBack={navigateBack} name='Initiative'/>
            <div className="p-4 m-2">
                <div className="mb-8 pb-2 border border-solid border-titanium-white rounded-md w-full">
                    <div className="flex items-center py-3 bg-gotham-gray border-b border-solid border-titanium-white rounded-t-md">
                        <h2 className="font-semibold text-xl ml-7 w-1/3 text-white">View Upload History</h2>
                        {isIframe && 
                            <div className="flex ml-auto mr-4 mb-4">
                                <Expand/>
                            </div>
                        }
                    </div>
                    <h3 className='ml-4 sm:ml-16 mt-5 w-full font-semibold text-gotham-gray'>List of Files Uploaded</h3>
                    <p className='ml-4 sm:ml-16 text-gotham-gray text-left text-sm'>Uploaded Files are accessible for 7 days only.</p>

                    <div className="overflow-x-auto border border-solid border-titanium-white sm:mx-16 m-4 rounded-lg">
                        <table className="min-w-full">
                            <thead className="bg-spanish-gray text-white rounded-t-lg">
                                <tr>
                                    <th className="border-b border-titanium-white text-xs font-bold px-4 py-2 text-left">Date</th>
                                    <th className="border-b border-titanium-white text-xs font-bold px-4 py-2 text-left">File Name</th>
                                    <th className="border-b border-titanium-white text-xs font-bold px-4 py-2 text-left">No. of Records</th>
                                    <th className="border-b border-titanium-white text-xs font-bold px-4 py-2 text-left">Status</th>
                                    <th className="border-b border-titanium-white text-xs font-bold px-4 py-2 text-left"></th>
                                </tr>
                            </thead>
                            <tbody>
                                {fileHistory.map((row: FileHistoryType, index: number) => {

                                    return (
                                        <tr key={index} className={`border-b border-solid border-titanium-white last:border-none h-10`}>
                                            <td className="px-4 py-2 text-gotham-gray text-sm text-left">
                                                {row.creation_date ? new Date(row.creation_date).toLocaleDateString() : ''}
                                            </td>
                                            <td className="px-4 py-2 text-gotham-gray text-sm text-left">
                                                {row.name || ''}
                                            </td>
                                            <td className="px-4 py-2 text-gotham-gray text-sm text-left">
                                                {row.record_count || ''}
                                            </td>
                                            <td className="px-4 py-2 text-gotham-gray text-sm text-left">
                                            {row.status === 'Complete' ? (
                                                <span className="px-2 inline-flex text-xs leading-5 font-semibold rounded-md bg-[#1FAB1F] text-white">
                                                    {row.status}
                                                </span>
                                            ) : (
                                                <span className="px-2 inline-flex text-xs leading-5 font-semibold rounded-md bg-gray-200 text-gotham-gray">
                                                    {row.status}
                                                </span>
                                            )}
                                            </td>
                                            <td className="relative flex w-24 px-4 py-2 text-gotham-gray text-sm text-left items-center">
                                                <div className={`relative flex ${index === fileHistory.length - 1 ? 'items-end' : 'items-start'}`}>
                                                    <button onClick={(e) => { e.stopPropagation(); handleDropdownToggle(index); }} className="mr-3 flex items-center">
                                                        <img className="border border-solid border-tableGray h-6 w-6 p-1 rounded-md" src={dotIcon} alt="Options" />
                                                    </button>
                                                    {openDropdownIndex === index && (
                                                        <div ref={dropdownRef} className="absolute w-36 bg-white rounded-md shadow-lg z-50">
                                                            <div className="py-1">
                                                                <button onClick={() => { handleEditClick(index); setOpenDropdownIndex(null); }} className="block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100 hover:text-gray-900 w-full text-left">
                                                                    View
                                                                </button>
                                                                <button onClick={() => { handleDeleteClick(index); setOpenDropdownIndex(null); }} className="block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100 hover:text-gray-900 w-full text-left">
                                                                    Remove
                                                                </button>
                                                                
                                                            </div>
                                                        </div>
                                                    )}
                                                </div>
                                            </td>
                                        </tr>
                                    );
                                })}
                            </tbody>
                        </table>
                    </div>
                </div>
            </div> 
        </>  
    );
}

export default UploadHistory;