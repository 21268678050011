import { useContext, useEffect, useRef, useState } from 'react';
import downloadSvg from '../../assets/download-file-icon.svg';
import { InitiativeData, InteractionData, OutcomeData } from "../../services/types";
import { addCommas, getCurrentDate } from "../../utils/helpers";
// import { CSVLink } from 'react-csv';
import { useAdminContext } from '../../context/AdminContext';
import AdoDataContext from '../../context/AdoDataContext';
import InitiativeDataContext from '../../context/InitiativeDataContext';
import XLSX from 'xlsx-js-style';
import ProjectDataContext from '../../context/ProjectDataContext';

interface DownloadButtonProps {
    outcomeData: OutcomeData[];
    initiativeData: InitiativeData[];
    interactionData: InteractionData[];
}

interface SummaryData {
    'County': string;
    'Associate Development Organization': string | undefined;
    'New Jobs': number;
    'Jobs Retained': number;
    'Private Investment': number;
    'Public Investment': number;
    'Startups': number;
    'Recruitment': number;
    'Expansion': number;
    'Retention': number;
    'Retention/Expansion': number;
}

// DownloadButton - Handles excel workbook creation, transformations, and Download UI
function DownloadButton({ outcomeData, initiativeData, interactionData }: DownloadButtonProps) {
    const { isAdmin } = useAdminContext();
    const { initiatives } = useContext(InitiativeDataContext);
    const { projects } = useContext(ProjectDataContext);
    const { partnerData } = useContext(AdoDataContext);
    const [openDropdown, setOpenDropdown] = useState<boolean>(false);
    const dropdownRef = useRef<HTMLDivElement>(null);
    const currentDate = getCurrentDate();

    const handleDropdownToggle = () => {
        setOpenDropdown(prevState => !prevState);
    }

    // removeStubs - Function that ensures that all cells, even empty ones, are preserved when the worksheet is generated
    const removeStubs = (wb: XLSX.WorkBook) => {
        Object.values(wb.Sheets).forEach((ws: XLSX.WorkSheet) => {
          Object.values(ws).filter((v: XLSX.CellObject) => v.t === 'z').forEach((v: XLSX.CellObject) => Object.assign(v,{t:'s',v:''}));
        });
        return wb;
    }

    const handleSummaryDownload = () => {
        // Convert csvSummaryData to XLSX
        const worksheet = XLSX.utils.json_to_sheet(csvSummaryData);

        // Remove stubs so we can style empty cells
        removeStubs({
            SheetNames: ['Summary'],
            Sheets: { 'Summary': worksheet } 
        });

        // Set up cell styles
        // @ts-ignore
        const range = XLSX.utils.decode_range(worksheet['!ref']); // Get the range of the worksheet

        // Define column widths
        worksheet['!cols'] = [
            { wch: 20 },
            { wch: 46 },
            { wch: 18 },
            { wch: 18 },
            { wch: 25 },
            { wch: 25 },
            { wch: 18 },
            { wch: 18 },
            { wch: 18 },
            { wch: 18 },
            { wch: 25 },
        ];

        for (let row = range.s.r; row <= range.e.r; row++) {
            for (let col = range.s.c; col <= range.e.c; col++) {
                const cellAddress = XLSX.utils.encode_cell({ r: row, c: col });
        
                // Set styles for the header and footer row
                if (row === 0) { // First row (header)
                    if (col >= range.e.c - 4) {
                        worksheet[cellAddress].s = {
                            fill: { fgColor: { rgb: "85CA3A" } }, // Green color for last five cells
                            font: { bold: true, sz: 14, color: { rgb: "FFFFFF" } }
                        };
                    } else {
                        worksheet[cellAddress].s = {
                            fill: { fgColor: { rgb: "2779A6" } }, 
                            font: { bold: true, sz: 14, color: { rgb: "FFFFFF" } }
                        };
                    }
                } else if (row === range.e.r) { // Last row (footer)
                    if (col >= range.e.c - 4) {
                        worksheet[cellAddress].s = {
                            fill: { fgColor: { rgb: "85CA3A" } }, // Green color for last five cells
                            font: { bold: true, sz: 14, color: { rgb: "FFFFFF" } }
                        };
                    } else {
                        worksheet[cellAddress].s = {
                            fill: { fgColor: { rgb: "2779A6" } }, 
                            font: { bold: true, sz: 14, color: { rgb: "FFFFFF" } }
                        };
                    }
                } else {
                    // Set styles for alternate rows
                    if (row % 2 === 0) {
                        worksheet[cellAddress].s = {
                            fill: { fgColor: { rgb: "A6DEF9" } }, 
                            font: { sz: 12, color: { rgb: "000000"} }
                        };
                    } else {
                        worksheet[cellAddress].s = {
                            fill: { fgColor: { rgb: "CDEBF7" } }, // White background for odd rows
                            font: { sz: 12, color: { rgb: "000000"} }
                        };
                    }
                }
            }
        }

        const workbook = XLSX.utils.book_new();
        XLSX.utils.book_append_sheet(workbook, worksheet, 'Summary');
        XLSX.writeFile(workbook, `summary_export_${currentDate}.xlsx`);
    };

    const handleDetailDownload = () => {
        // Create a new workbook
        const workbook = XLSX.utils.book_new();

        // Convert detailData to XLSX
        const initiativeWorksheet = XLSX.utils.json_to_sheet(initiativeDetails);

        // Include headers as first row
        XLSX.utils.sheet_add_aoa(initiativeWorksheet, [initiativeHeaders.map(header => header.label)], { origin: 'A1' });

        // Remove stubs so we can style empty cells
        removeStubs({
            SheetNames: ['Initiatives'],
            Sheets: { 'Initiatives': initiativeWorksheet } 
        });

        // Set up cell styles
        // @ts-ignore
        const range = XLSX.utils.decode_range(initiativeWorksheet['!ref']); // Get the range of the worksheet

        // Define column widths
        initiativeWorksheet['!cols'] = [
            { wch: 10 },
            { wch: 30 },
            { wch: 20 },
            { wch: 9 },
            { wch: 9 },
            { wch: 25 },
            { wch: 13 },
            { wch: 20 },
            { wch: 34 },
            { wch: 36 },
            { wch: 20 },
            { wch: 35 },
            { wch: 36 },
            { wch: 39 },
            { wch: 14 },
            { wch: 16 },
            { wch: 22 },
            { wch: 22 },
            { wch: 26 },
            { wch: 31 },
            { wch: 32 },
            { wch: 36 },
            { wch: 38 },
            { wch: 50 },
        ];

        for (let row = range.s.r; row <= range.e.r; row++) {
            for (let col = range.s.c; col <= range.e.c; col++) {
                const cellAddress = XLSX.utils.encode_cell({ r: row, c: col });

                // Set styles for the header row
                if (row === 0) {
                    initiativeWorksheet[cellAddress].s = {
                        fill: { fgColor: { rgb: "FFFFFF" } }, 
                        font: { bold: true, color: { rgb: "000000" } }
                    };
                } else {
                    // Set styles for alternate rows
                    if (row % 2 === 0) {
                        initiativeWorksheet[cellAddress].s = {
                            fill: { fgColor: { rgb: "D9D9D9" } }, 
                        };
                    } else {
                        initiativeWorksheet[cellAddress].s = {
                            fill: { fgColor: { rgb: "FFFFFF" } }, // White background for odd rows
                        };
                    }
                }
            }
        }
        // Append the initiative sheet to the workbook
        XLSX.utils.book_append_sheet(workbook, initiativeWorksheet, 'Initiatives');

        // Create interaction sheet if not in admin view
        if (!isAdmin) {
            // Convert interaction data to XLSX
            const interactionWorksheet = XLSX.utils.json_to_sheet(interactionDetails);
            XLSX.utils.sheet_add_aoa(interactionWorksheet, [interactionHeaders.map(header => header.label)], { origin: 'A1' });

            // Remove stubs so we can style empty cells
            removeStubs({
                SheetNames: ['Interactions'],
                Sheets: { 'Interactions': interactionWorksheet } 
            });

            // Set up styles for interaction worksheet (similar to your current code)
            // @ts-ignore
            const interactionRange = XLSX.utils.decode_range(interactionWorksheet['!ref']);

            // Define column widths dynamically based on headers
            interactionWorksheet['!cols'] = Array(interactionHeaders.length).fill({ wch: 20 });

            for (let row = interactionRange.s.r; row <= interactionRange.e.r; row++) {
                for (let col = interactionRange.s.c; col <= interactionRange.e.c; col++) {
                    const cellAddress = XLSX.utils.encode_cell({ r: row, c: col });

                    if (row === 0) {
                        interactionWorksheet[cellAddress].s = {
                            fill: { fgColor: { rgb: "FFFFFF" } }, 
                            font: { bold: true, color: { rgb: "000000" } }
                        };
                    } else {
                        // Set styles for alternate rows
                        if (row % 2 === 0) {
                            interactionWorksheet[cellAddress].s = {
                                fill: { fgColor: { rgb: "D9D9D9" } },
                            };
                        } else {
                            interactionWorksheet[cellAddress].s = {
                                fill: { fgColor: { rgb: "FFFFFF" } },
                            };
                        }
                    }
                }
            }
            // Append the interaction sheet to the workbook
            XLSX.utils.book_append_sheet(workbook, interactionWorksheet, 'Interactions');
        }

        // Export the workbook
        XLSX.writeFile(workbook, `detail_export_${currentDate}.xlsx`);
    };

    // Close dropdown when clicking outside of it
    useEffect(() => {
        const handleClickOutside = (event: MouseEvent) => {
            if (dropdownRef.current && !dropdownRef.current.contains(event.target as Node)) {
                setOpenDropdown(false);
            }
        };

        document.addEventListener('mousedown', handleClickOutside);
        
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };

        
    }, []);


    ////////////
    // Relevant summary data below:
    ////////////

    // Step 1: Declare and initialize summaryData as an empty array
    let summaryData: SummaryData[] = [];

    // Step 2: Initialize all ADOs with 0 values
    partnerData.forEach(partner => {
        const adoName = partner.name;

        summaryData.push({
            'County': partner.counties_supported || '',
            'Associate Development Organization': adoName,
            'New Jobs': 0,
            'Jobs Retained': 0,
            'Private Investment': 0,
            'Public Investment': 0,
            'Startups': 0,
            'Recruitment': 0,
            'Expansion': 0,
            'Retention': 0,
            'Retention/Expansion': 0,
        });
    });

    // Step 3: Map outcomeData and add job and investment metrics
    
    outcomeData.forEach(row => {
        const initiative = initiatives.find(init => Number(init.id) === Number(row.initiative_id));
        const adoName = initiative?.partner_name || '';
        const existingData = summaryData.find(data => data['Associate Development Organization'] === adoName);

        if (existingData) {
            existingData['New Jobs'] += Number(row.jobs_created);
            existingData['Jobs Retained'] += Number(row.jobs_retained);
            existingData['Private Investment'] += Number(row.private_investment_dollars);
            existingData['Public Investment'] += Number(row.public_investment_dollars);
            // existingData['Startups'] += Number(row.startups_supported);
        }
    });

    // Iterate over Project data and aggregate the new columns
    const projectData = initiativeData.filter(initiative => initiative.category === 'project');

    projectData.forEach(initiative => {
        const adoName = initiative.partner_name;
        const existingData = summaryData.find(data => data['Associate Development Organization'] === adoName);

        if (existingData) {
            // Increment based on initiative type
            switch (initiative.initiative_type?.name) {
                case 'Recruitment':
                    existingData['Recruitment'] += 1;
                    break;
                case 'Expansion':
                    existingData['Expansion'] += 1;
                    break;
                case 'Retention Only':
                    existingData['Retention'] += 1;
                    break;
                case 'Retention/Expansion':
                    existingData['Retention/Expansion'] += 1;
                    break;
                case 'Startup':
                    existingData['Startups'] += 1;
                    break;
            }
        }
    });
    
    // Aggregate summaryData based on Associate Development Organization
    const aggregatedDataMap = new Map<string, SummaryData>();
    
    summaryData.forEach(curr => {
        const adoName = curr['Associate Development Organization'];
        
        if (!adoName) return;
    
        const existingData = aggregatedDataMap.get(adoName);
        
        if (existingData) {
            existingData['New Jobs'] += curr['New Jobs'];
            existingData['Jobs Retained'] += curr['Jobs Retained'];
            existingData['Private Investment'] += curr['Private Investment'];
            existingData['Public Investment'] += curr['Public Investment'];
            existingData['Startups'] += curr['Startups'];
            existingData['Recruitment'] += curr['Recruitment'];
            existingData['Expansion'] += curr['Expansion'];
            existingData['Retention'] += curr['Retention'];
            existingData['Retention/Expansion'] += curr['Retention/Expansion'];
        } else {
            aggregatedDataMap.set(adoName, { ...curr });
        }
    });
    
    // Initialize ADOs with zero values if missing
    partnerData.forEach(partner => {
        const adoName = partner.name;
    
        if (!aggregatedDataMap.has(adoName)) {
            aggregatedDataMap.set(adoName, {
                'County': partner.counties_supported || '',
                'Associate Development Organization': adoName,
                'New Jobs': 0,
                'Jobs Retained': 0,
                'Private Investment': 0,
                'Public Investment': 0,
                'Startups': 0,
                'Recruitment': 0,
                'Expansion': 0,
                'Retention': 0,
                'Retention/Expansion': 0,
            });
        }
    });
    
    // Format data and calculate grand totals
    let grandTotal: SummaryData = {
        'County': '',
        'Associate Development Organization': 'Grand Total',
        'New Jobs': 0,
        'Jobs Retained': 0,
        'Private Investment': 0,
        'Public Investment': 0,
        'Startups': 0,
        'Recruitment': 0,
        'Expansion': 0,
        'Retention': 0,
        'Retention/Expansion': 0,
    };
    
    const aggregatedData = Array.from(aggregatedDataMap.values()).map(row => {
        grandTotal['New Jobs'] += row['New Jobs'];
        grandTotal['Jobs Retained'] += row['Jobs Retained'];
        grandTotal['Private Investment'] += row['Private Investment'];
        grandTotal['Public Investment'] += row['Public Investment'];
        grandTotal['Startups'] += row['Startups'];
        grandTotal['Recruitment'] += row['Recruitment'];
        grandTotal['Expansion'] += row['Expansion'];
        grandTotal['Retention'] += row['Retention'];
        grandTotal['Retention/Expansion'] += row['Retention/Expansion'];
    
        return {
            ...row,
            'Private Investment': `$${addCommas(row['Private Investment'].toFixed(2))}`,
            'Public Investment': `$${addCommas(row['Public Investment'].toFixed(2))}`,
        };
    });
    
    // Add formatted grand total
    const formattedGrandTotal: SummaryData = {
        ...grandTotal,
        //@ts-ignore
        'Private Investment': `$${addCommas(grandTotal['Private Investment'].toFixed(2))}`,
        //@ts-ignore
        'Public Investment': `$${addCommas(grandTotal['Public Investment'].toFixed(2))}`
    };
    //@ts-ignore
    aggregatedData.push(formattedGrandTotal);
    
    // Prepare CSV data
    const csvSummaryData = aggregatedData.map(item => ({
        County: item.County,
        'Associate Development Organization': item['Associate Development Organization'],
        'New Jobs': item['New Jobs'],
        'Jobs Retained': item['Jobs Retained'],
        'Private Investment': item['Private Investment'],
        'Public Investment': item['Public Investment'],
        'Startups': item['Startups'],
        'Recruitment': item['Recruitment'],
        'Expansion': item['Expansion'],
        'Retention': item['Retention'],
        'Retention/Expansion': item['Retention/Expansion'],
    }));

    ////////////
    // Relevant initiative and interaction detail data below:
    ////////////

    const mergeInitiativeData = () => {
        const initiativeDetails = initiativeData.flatMap(initiative => {
            // Check if the current initiative is a project
            const isProject = projects.some(project => Number(project.id) === Number(initiative.id));
                    
            // Set the type field
            const type = isProject ? 'Project' : 'Initiative';


            // Find all outcomes related to the current initiative
            const outcomes = outcomeData.filter(outcome => Number(outcome.initiative_id) === Number(initiative.id));
          
            // For the ADO view, if no outcomes are found return the initiative with default values for outcome-related fields. Admin only shows initiatives with outcomes
            if (!isAdmin) {
                if (outcomes.length === 0) {
                    return {
                        initiative_id: initiative.id,
                        name: initiative.name,
                        partner_name: initiative.partner_name,
                        year: initiative.year,
                        type: type,
                        status: initiative.status,
                        description: initiative.description,
                        initiative_type: initiative.initiative_type?.display_name,
                        project_business_name: initiative.project_business_name,
                        project_business_ubi: initiative.project_business_ubi,
                        outcome_year: null,
                        outcome_quarter: null,
                        outcome_narrative: null,
                        jobs_created: null,
                        jobs_retained: null,
                        startups_supported: null,
                        public_investment_dollars: null,
                        private_investment_dollars: null,
                        products_exported_dollars_year: null,
                        direct_foreign_investment_dollars: null,
                        jobs_created_retained_with_above_avg_wages: null
                    };
                }
            }
          
            // If outcomes are found, return a separate object for each outcome, including all initiative details
            return outcomes.map(outcome => ({
              initiative_id: initiative.id,
              name: initiative.name,
              partner_name: initiative.partner_name,
              year: initiative.year,
              type: type,
              status: initiative.status,
              description: initiative.description,
              initiative_type: initiative.initiative_type?.display_name,
              project_business_name: initiative.project_business_name,
              project_business_ubi: initiative.project_business_ubi,
              outcome_year: outcome.year || null,
              outcome_quarter: outcome.quarter || null,
              outcome_narrative: outcome.narrative ? outcome.narrative : null,
              jobs_created: Number(outcome.jobs_created) || null,
              jobs_retained: Number(outcome.jobs_retained) || null,
              startups_supported: Number(outcome.startups_supported) || null,
              public_investment_dollars: Number(outcome.public_investment_dollars) || null,
              private_investment_dollars: Number(outcome.private_investment_dollars) || null,
              products_exported_dollars_year: Number(outcome.products_exported_dollars_year) || null,
              direct_foreign_investment_dollars: Number(outcome.direct_foreign_investment_dollars) || null,
              jobs_created_retained_with_above_avg_wages: Number(outcome.jobs_created_retained_with_above_avg_wages) || null
            }));
          });
    
        return initiativeDetails;
    };
    
    const mergeInteractionData = () => {
        const interactionDetails = initiativeData.flatMap(initiative => {
            const interactions = interactionData.filter(interaction => interaction.initiative_id === initiative.id);

            const interactionsWithDefaults = interactions.length > 0 ? interactions : [{
                interaction_date: null,
                note: null,
                email: null,
                phone: null,
                latitude: null,
                longitude: null,
                assistance_types: null,
                first_name: null,
                last_name: null,
                staff_name: null,
                business_ubi: null,
                contact_type: null,
                gross_revenue: null,
                people_employed: null,
                interaction_type: null,
                business_legal_name: null,
                business_trade_name: null,
                classification_types: null,
            }];
    
            return interactionsWithDefaults.map(interaction => {
                // Mapping over assistance_types and creating a comma-separated list of names
                const assistanceNames = interaction.assistance_types
                    ? interaction.assistance_types.map(a => a.name).join(', ')
                    : '';
    
                // Mapping over classification_types to create a comma-separated list of names
                const classificationNames = interaction.classification_types
                    ? interaction.classification_types.map(c => c.name).join(', ')
                    : '';
    
                return {
                    interaction_date: interaction.interaction_date,
                    note: interaction.note,
                    email: interaction.email,
                    phone: interaction.phone,
                    latitude: interaction.latitude,
                    longitude: interaction.longitude,
                    assistance_types: assistanceNames,
                    first_name: interaction.first_name,
                    last_name: interaction.last_name,
                    staff_name: interaction.staff_name,
                    business_ubi: interaction.business_ubi,
                    contact_type: interaction.contact_type?.display_name,
                    gross_revenue: interaction.gross_revenue,
                    people_employed: interaction.people_employed ? interaction.people_employed : null,
                    interaction_type: interaction.interaction_type?.display_name,
                    business_legal_name: interaction.business_legal_name,
                    business_trade_name: interaction.business_trade_name,
                    classification_types: classificationNames,
                };
            });
        });
    
        return interactionDetails;
    };
    
    // Now you can call both functions to get the data
    const initiativeDetails = mergeInitiativeData();
    const interactionDetails = mergeInteractionData();

    const interactionHeaders = [
        { label: 'Interaction Date', key: 'interaction_date' },
        { label: 'Interaction Note', key: 'note' },
        { label: 'Interaction Email', key: 'email' },
        { label: 'Interaction Phone', key: 'phone' },
        { label: 'Interaction Latitude', key: 'latitude' },
        { label: 'Interaction Longitude', key: 'longitude' },
        { label: 'Interaction Assistance', key: 'assistance_types' },
        { label: 'Interaction First Name', key: 'first_name' },
        { label: 'Interaction Last Name', key: 'last_name' },
        { label: 'Interaction Staff Name', key: 'staff_name' },
        { label: 'Interaction Business UBI', key: 'business_ubi' },
        { label: 'Interaction Contact Type', key: 'contact_type' },
        { label: 'Interaction Gross Revenue', key: 'gross_revenue' },
        { label: 'Interaction People Employed', key: 'people_employed' },
        { label: 'Interaction Type', key: 'interaction_type' },
        { label: 'Interaction Business Legal Name', key: 'business_legal_name' },
        { label: 'Interaction Business Trade Name', key: 'business_trade_name' },
        { label: 'Interaction Business Classification', key: 'classification_types' },
    ];

    const initiativeHeaders = [
        { label: 'Initiative ID', key: 'id' },
        { label: 'Initiative Name', key: 'name' },
        { label: 'ADO Name', key: 'partner_name' },
        { label: 'Year', key: 'year' },
        { label: 'Type', key: 'type'},
        { label: 'Status', key: 'status' },
        { label: 'Description', key: 'description' },
        { label: 'Initiative Type', key: 'initiative_type' },
        { label: 'Project Business Name', key: 'project_business_name' },
        { label: 'Project Business UBI', key: 'project_business_ubi' },
        { label: 'Outcome Year', key: 'outcome_year' },
        { label: 'Outcome Quarter', key: 'outcome_quarter' },
        { label: 'Outcome Narrative', key: 'outcome_narrative' },
        { label: 'Outcome Jobs Created', key: 'jobs_created' },
        { label: 'Outcome Jobs Retained', key: 'jobs_retained' },
        { label: 'Outcome Startups Supported', key: 'startups_supported' },
        { label: 'Outcome Public Investment Dollars', key: 'public_investment_dollars' },
        { label: 'Outcome Private Investment Dollars', key: 'private_investment_dollars' },
        { label: 'Outcome Products Exported Dollars Year', key: 'products_exported_dollars_year' },
        { label: 'Outcome Direct Foreign Investment Dollars', key: 'direct_foreign_investment_dollars' },
        { label: 'Outcome Jobs Created Retained with Above Avg Wages', key: 'jobs_created_retained_with_above_avg_wages' },
    ];

    return ( 
        <span className="flex relative">
            <button
                id="new-target"
                onClick={handleDropdownToggle}
                className="text-center w-36 mx-4 px-4 text-button-blue font-bold text-sm bg-white border border-solid border-titanium-white rounded-md focus:outline-none focus:border-blue-500 hover:bg-button-blue hover:border-button-blue hover:text-white h-8 appearance-none"
            > 
                <p className="">Download</p>
                <img src={downloadSvg} alt="download icon" className="absolute top-[10px] left-8" />
            </button>
            {openDropdown && (
                <div ref={dropdownRef} className="absolute mt-9 w-36 left-4 bg-white rounded-md border border-color-cai-lightGray shadow-lg z-10">
                    <div className="py-1">
                        {isAdmin && 
                            <button 
                                onClick={() => { 
                                    handleSummaryDownload(); 
                                    setOpenDropdown(false); 
                                }} 
                                className="block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100 hover:text-gray-900 w-full text-left">
                                Download Table C Business Project Wins
                            </button>
                        }
                                    
                        <button 
                            onClick={() => { 
                                handleDetailDownload(); 
                                setOpenDropdown(false); 
                            }} 
                            className="block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100 hover:text-gray-900 w-full text-left">
                            Download Projects & Initiatives Detail
                        </button>
                        
                    </div>
                </div>
            )}
        </span>
    );
}

export default DownloadButton;