import { useContext, useEffect, useState } from 'react';
import upArrow from '../../assets/sort-arrow.svg';
import { addCommas, generatePageNumbers } from '../../utils/helpers.ts';
import { InitiativeData } from '../../services/types.js';
import useDeepCompareEffect from '../../hooks/useDeepCompareEffect.js';
import { useAdminContext } from '../../context/AdminContext.tsx';
import AdoDataContext from '../../context/AdoDataContext.tsx';
import { useOrganizationContext } from '../../context/OrganizationContext.tsx';

interface ProjectSummaryTableProps {
    initiativeData: InitiativeData[];
    printView: boolean;
}

type AggregatedData = {
    partner_name: string;
    project_count: number;
    jobs_created: number;
    jobs_retained: number;
    startups_supported: number;
    public_investment_dollars: number;
    private_investment_dollars: number;
    products_exported_dollars_year: number;
    direct_foreign_investment_dollars: number;
    jobs_created_retained_with_above_avg_wages: number;
};

// Define the type for the entire aggregated object
type AggregatedDataMap = {
    [partner: string]: AggregatedData;
};

/* ProjectSummaryTable - Renders a summary of outcomes by ADO (Admin view and ADO View) */
function ProjectSummaryTable({ initiativeData, printView }: ProjectSummaryTableProps) {
    const { isAdmin } = useAdminContext();
    const { adoNames } = useContext(AdoDataContext);
    const { partnerName } = useOrganizationContext();
    const [data, setData] = useState<any[]>([]);
    const [sortConfig, setSortConfig] = useState<{ key: string, direction: 'asc' | 'desc' | null }>({ key: '', direction: null });
    const [currentPage, setCurrentPage] = useState(1);
    const rowsPerPage = 10;

    const projects = initiativeData.filter(initiative => initiative.category === 'project');

    // Initialize with all partners from adoNames
    const aggregatedData: AggregatedDataMap = isAdmin
    ? adoNames.reduce((acc, ado) => {
        acc[ado.label] = {
            partner_name: ado.label,
            project_count: 0,
            jobs_created: 0,
            jobs_retained: 0,
            startups_supported: 0,
            public_investment_dollars: 0,
            private_investment_dollars: 0,
            products_exported_dollars_year: 0,
            direct_foreign_investment_dollars: 0,
            jobs_created_retained_with_above_avg_wages: 0,
        };
        return acc;
    }, {} as AggregatedDataMap)
    : {
        [partnerName]: {
            partner_name: partnerName,
            project_count: 0,
            jobs_created: 0,
            jobs_retained: 0,
            startups_supported: 0,
            public_investment_dollars: 0,
            private_investment_dollars: 0,
            products_exported_dollars_year: 0,
            direct_foreign_investment_dollars: 0,
            jobs_created_retained_with_above_avg_wages: 0,
        },
    };

    // Aggregate outcomes and update data for partners with projects
    projects.forEach((initiative) => {
        const partner = initiative.partner_name;

        if (aggregatedData[partner]) {
            // Increment project count
            aggregatedData[partner].project_count += 1;

            // Sum outcomes
            initiative.outcomes.forEach((outcome) => {
                aggregatedData[partner].jobs_created += Number(outcome.jobs_created || 0);
                aggregatedData[partner].jobs_retained += Number(outcome.jobs_retained || 0);
                aggregatedData[partner].startups_supported += Number(outcome.startups_supported || 0);
                aggregatedData[partner].public_investment_dollars += Number(outcome.public_investment_dollars || 0);
                aggregatedData[partner].private_investment_dollars += Number(outcome.private_investment_dollars || 0);
                aggregatedData[partner].products_exported_dollars_year += Number(outcome.products_exported_dollars_year || 0);
                aggregatedData[partner].direct_foreign_investment_dollars += Number(outcome.direct_foreign_investment_dollars || 0);
                aggregatedData[partner].jobs_created_retained_with_above_avg_wages += Number(outcome.jobs_created_retained_with_above_avg_wages || 0);
            });
        }
    });

    // Convert the result into an array for easier display
    const aggregatedArray: any[] = Object.values(aggregatedData);

    useDeepCompareEffect(() => {
        if (Array.isArray(aggregatedArray)) {
            setData(aggregatedArray);
            setSortConfig({ key: 'name', direction: 'asc' });
        } else {
            console.error('initiativeData is not an array:', initiativeData);
        }
    }, [initiativeData]);

    const handlePageClick = (page: number) => {
        setCurrentPage(page);
    };

    // Handle sorting
    const handleSortClick = (key: string) => {
        let direction: 'asc' | 'desc' = 'asc';
        if (sortConfig.key === key && sortConfig.direction === 'asc') {
            direction = 'desc';
        }
        setSortConfig({ key, direction });
    };

    // Sort data based on sortConfig
    useEffect(() => {
        if (sortConfig.key) {
            const sortedData = [...aggregatedArray].sort((a, b) => {
                const getValue = (item: any, key: string) => {
                    const defaultKeys = {
                        number_of_projects: 'project_count',
                        jobs_created: 'jobs_created',
                        jobs_retained: 'jobs_retained',
                        jobs_created_retained_with_above_avg_wages: 'jobs_created_retained_with_above_avg_wages',
                        startups_supported: 'startups_supported',
                        products_exported_dollars_year: 'products_exported_dollars_year',
                        public_investment_dollars: 'public_investment_dollars',
                        private_investment_dollars: 'private_investment_dollars',
                        direct_foreign_investment_dollars: 'direct_foreign_investment_dollars',
                    };
    
                    const actualKey = defaultKeys[key as keyof typeof defaultKeys] || key;
                    return item[actualKey] || 0;
                };
    
                const aVal = getValue(a, sortConfig.key);
                const bVal = getValue(b, sortConfig.key);
    
                // Ensure that empty or null values are pushed to the end
                if ((aVal === null || aVal === '') && (bVal === null || bVal === '')) return 0;
                if (aVal === null || aVal === '') return sortConfig.direction === 'asc' ? 1 : -1;
                if (bVal === null || bVal === '') return sortConfig.direction === 'asc' ? -1 : 1;
    
                // Standard comparison logic
                if (aVal < bVal) return sortConfig.direction === 'asc' ? -1 : 1;
                if (aVal > bVal) return sortConfig.direction === 'asc' ? 1 : -1;
                return 0;
            });
    
            setData(sortedData);
        }
    }, [sortConfig, initiativeData]);

    // Pagination logic for flattened data
    const totalPages = Math.ceil(data.length / rowsPerPage);
    const pageNumbersToShow = generatePageNumbers(totalPages, currentPage);

    const startIndex = (currentPage - 1) * rowsPerPage;
    const endIndex = Math.min(startIndex + rowsPerPage, data.length);
    const paginatedData = printView ? data : data.slice(startIndex, endIndex);

    return (
        <div>
            <div className="overflow-x-auto border-b border-solid border-color-tableGray rounded-md">
                <table className="w-full table-auto">
                    <thead className="bg-spanish-gray text-white">
                        <tr>
                            <th 
                                className="border-b border-titanium-white text-xs font-bold px-4 py-2 text-left min-w-28 max-w-28"
                                onClick={() => handleSortClick('partner_name')}
                            >
                                <div className='flex items-center hover:cursor-pointer'>
                                ADO
                                {sortConfig.key === 'partner_name' && (
                                    <img 
                                        src={upArrow} 
                                        alt='sort arrow' 
                                        className={`transform ${sortConfig.direction === 'desc' ? 'rotate-180' : ''}`} 
                                    />
                                )}
                                </div>
                            </th>
                            <th 
                                className="border-b border-titanium-white text-xs font-bold px-4 py-2 text-left min-w-20 max-w-20"
                                onClick={() => handleSortClick('number_of_projects')}
                            >
                                <div className='flex items-center hover:cursor-pointer'>
                                Number of Projects
                                {sortConfig.key === 'number_of_projects' && (
                                    <img 
                                        src={upArrow} 
                                        alt='sort arrow' 
                                        className={`transform ${sortConfig.direction === 'desc' ? 'rotate-180' : ''}`} 
                                    />
                                )}
                                </div>
                            </th>
                            <th 
                                className="border-b border-titanium-white text-xs font-bold px-4 py-2 text-left text-nowrap min-w-28 max-w-28"
                                onClick={() => handleSortClick('jobs_created')}
                            >
                                <div className='flex items-center hover:cursor-pointer'>
                                Jobs created
                                {sortConfig.key === 'jobs_created' && (
                                    <img 
                                        src={upArrow} 
                                        alt='sort arrow' 
                                        className={`transform ${sortConfig.direction === 'desc' ? 'rotate-180' : ''}`} 
                                    />
                                )}
                                </div>
                            </th>
                            <th 
                                className="border-b border-titanium-white text-xs font-bold px-4 py-2 text-end text-nowrap min-w-28 max-w-28"
                                onClick={() => handleSortClick('jobs_retained')}
                            >
                                <div className='flex items-center hover:cursor-pointer'>
                                Jobs retained
                                {sortConfig.key === 'jobs_retained' && (
                                    <img 
                                        src={upArrow} 
                                        alt='sort arrow' 
                                        className={`transform ${sortConfig.direction === 'desc' ? 'rotate-180' : ''}`} 
                                    />
                                )}
                                </div>
                            </th>
                            <th 
                                className="border-b border-titanium-white text-xs font-bold px-4 py-2 text-center min-w-32 max-w-32"
                                onClick={() => handleSortClick('jobs_created_retained_with_above_avg_wages')}
                            >
                                <div className='flex items-center hover:cursor-pointer'>
                                Jobs created/retained with above avg wages
                                {sortConfig.key === 'jobs_created_retained_with_above_avg_wages' && (
                                    <img 
                                        src={upArrow} 
                                        alt='sort arrow' 
                                        className={`transform ${sortConfig.direction === 'desc' ? 'rotate-180' : ''}`} 
                                    />
                                )}
                                </div>
                            </th>
                            <th 
                                className="border-b border-titanium-white text-xs font-bold px-4 py-2 text-center min-w-24 max-w-24"
                                onClick={() => handleSortClick('startups_supported')}
                            >
                                <div className='flex items-center hover:cursor-pointer'>
                                Startup businesses
                                {sortConfig.key === 'startups_supported' && (
                                    <img 
                                        src={upArrow} 
                                        alt='sort arrow' 
                                        className={`transform ${sortConfig.direction === 'desc' ? 'rotate-180' : ''}`} 
                                    />
                                )}
                                </div>
                            </th>
                            <th 
                                className="border-b border-titanium-white text-xs font-bold px-4 py-2 text-center text-nowrap min-w-36 max-w-36"
                                onClick={() => handleSortClick('products_exported_dollars_year')}
                            >
                                <div className='flex items-center hover:cursor-pointer'>
                                {'Exports($/year)'}
                                {sortConfig.key === 'products_exported_dollars_year' && (
                                    <img 
                                        src={upArrow} 
                                        alt='sort arrow' 
                                        className={`transform ${sortConfig.direction === 'desc' ? 'rotate-180' : ''}`} 
                                    />
                                )}
                                </div>
                            </th>
                            <th 
                                className="border-b border-titanium-white text-xs font-bold px-4 py-2 text-center min-w-40 max-w-40"
                                onClick={() => handleSortClick('public_investment_dollars')}
                            >
                                <div className='flex items-center hover:cursor-pointer'>
                                Public investment dollars
                                {sortConfig.key === 'public_investment_dollars' && (
                                    <img 
                                        src={upArrow} 
                                        alt='sort arrow' 
                                        className={`transform ${sortConfig.direction === 'desc' ? 'rotate-180' : ''}`} 
                                    />
                                )}
                                </div>
                            </th>
                            <th 
                                className="border-b border-titanium-white text-xs font-bold px-4 py-2 text-center min-w-40 max-w-40"
                                onClick={() => handleSortClick('private_investment_dollars')}
                            >
                                <div className='flex items-center hover:cursor-pointer'>
                                Private investment dollars
                                {sortConfig.key === 'private_investment_dollars' && (
                                    <img 
                                        src={upArrow} 
                                        alt='sort arrow' 
                                        className={`transform ${sortConfig.direction === 'desc' ? 'rotate-180' : ''}`} 
                                    />
                                )}
                                </div>
                            </th>
                            <th 
                                className="border-b border-titanium-white text-xs font-bold px-4 py-2 text-center min-w-36 max-w-36"
                                onClick={() => handleSortClick('direct_foreign_investment_dollars')}
                            >
                                <div className='flex items-center hover:cursor-pointer'>
                                Direct foreign investment dollars
                                {sortConfig.key === 'direct_foreign_investment_dollars' && (
                                    <img 
                                        src={upArrow} 
                                        alt='sort arrow' 
                                        className={`transform ${sortConfig.direction === 'desc' ? 'rotate-180' : ''}`} 
                                    />
                                )}
                                </div>
                            </th>
                        </tr>
                    </thead>
                    <tbody>
                        {paginatedData.map((row: any) => {
                            const productsExported = `$${addCommas(Number(row.products_exported_dollars_year || 0).toFixed(0))}`;
                            const publicInvest = `$${addCommas(Number(row.public_investment_dollars || 0).toFixed(0))}`;
                            const privateInvest = `$${addCommas(Number(row.private_investment_dollars || 0).toFixed(0))}`;
                            const foreignInvest = `$${addCommas(Number(row.direct_foreign_investment_dollars || 0).toFixed(0))}`;

                            return (
                                <tr key={row.rowKey} className="bg-white border-b border-solid border-color-tableGray last:border-none h-10">
                                    <td className="px-4 py-2 text-gotham-gray text-sm text-left font-medium">{row.partner_name}</td>
                                    <td className="px-4 py-2 text-gotham-gray text-sm text-left font-medium">{row.project_count}</td>
                                    <td className="px-4 py-2 text-gotham-gray text-sm text-center font-medium">{row.jobs_created}</td>
                                    <td className="px-4 py-2 text-gotham-gray text-sm text-center font-medium">{row.jobs_retained}</td>
                                    <td className="px-4 py-2 text-gotham-gray text-sm text-center font-medium">{row.jobs_created_retained_with_above_avg_wages}</td>
                                    <td className="px-4 py-2 text-gotham-gray text-sm text-center font-medium">{row.startups_supported}</td>
                                    <td className="px-4 py-2  text-gotham-gray text-sm text-end font-medium">{row.products_exported_dollars_year ? productsExported : `$0`}</td>
                                    <td className="px-4 py-2  text-gotham-gray text-sm text-end font-medium">{row.public_investment_dollars ? publicInvest : `$0`}</td>
                                    <td className="px-4 py-2  text-gotham-gray text-sm text-end font-medium">{row.private_investment_dollars ? privateInvest : `$0`}</td>
                                    <td className="px-4 py-2  text-gotham-gray text-sm text-end font-medium">{row.direct_foreign_investment_dollars ? foreignInvest : `$0`}</td>
                                </tr>
                            );
                        })}
                    </tbody>
                </table>
            </div>

            {/* Pagination Controls */}
            {pageNumbersToShow.length > 1 &&
                <div className="hidden-on-print flex justify-center mt-4">
                    <div className="border border-solid border-at-white rounded-md flex">
                        <button
                            className="text-gotham-gray font-bold text-xs border-r border-solid border-at-white px-2 py-1"
                            onClick={() => handlePageClick(Math.max(currentPage - 1, 1))}
                            disabled={currentPage === 1}
                        >
                            « Previous
                        </button>
                        {pageNumbersToShow.map((pageNumber, index) => (
                            <button
                                key={index}
                                className={`text-gotham-gray font-bold text-xs border-r border-solid border-at-white px-3 py-1 ${pageNumber === currentPage ? 'bg-titanium-white' : ''}`}
                                onClick={() => handlePageClick(typeof pageNumber === 'number' ? pageNumber : currentPage)}
                            >
                                {pageNumber}
                            </button>
                        ))}
                        <button
                            className="text-gotham-gray font-bold text-xs px-2 py-1"
                            onClick={() => handlePageClick(Math.min(currentPage + 1, totalPages))}
                            disabled={currentPage === totalPages}
                        >
                            Next »
                        </button>
                    </div>
                </div>
            }
        </div>
    );
}

export default ProjectSummaryTable;