import { useState, ChangeEvent, useEffect, useContext } from 'react';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import * as Sentry from '@sentry/react';
import Select from 'react-select';
import { IndustryType, LocationType, PartnerLocation } from '../../services/types';
import { calculateFiscalYear, sanitizeString } from '../../utils/helpers';
import { customStyles, customStylesXL } from '../../utils/customFormStyles';
import { getIndustryTypeOptions, getPartnerLocations, getSubIndustryOptions, getTypeOptions, serviceAreaOptions, statusOptions, transformLocationsToOptions, yearOptions } from '../../utils/formOptions';
import useDeepCompareEffect from '../../hooks/useDeepCompareEffect';

import AdoDataContext from '../../context/AdoDataContext';
import TypesContext from '../../context/TypesContext';
import InitiativeDataContext from '../../context/InitiativeDataContext';
import ProjectDataContext from '../../context/ProjectDataContext';
import { useAdminContext } from '../../context/AdminContext';
import { useOrganizationContext } from '../../context/OrganizationContext';

import BackButtonHeader from '../../components/Navigation/BackButtonHeader';


function EditInitiative() {
    const { isAdmin } = useAdminContext();
    const { partnerName } = useOrganizationContext();
    const { initiatives, fetchInitiatives } = useContext(InitiativeDataContext);
    const { fetchProjects } = useContext(ProjectDataContext);
    const { partnerData, adoNames } = useContext(AdoDataContext);
    const { typesData } = useContext(TypesContext);
    
    const [adoOptions, setAdoOptions] = useState<{ label: string; value: string }[]>([]);
    const [ado, setAdo] = useState<string>('');
    const [year, setYear] = useState<string>('');
    const [quarter, setQuarter] = useState<string>('');
    const [name, setName] = useState<string>('');
    const [description, setDescription] = useState<string>('');
    const [isDefault, setIsDefault] = useState<boolean>(false);
    const [isAdminManaged, setIsAdminManaged] = useState<boolean>(false);
    const [initiativeStatus, setInitiativeStatus] = useState<string>('');
    const [initiativeType, setInitiativeType] = useState<string>('');
    const [industry, setIndustry] = useState<string>("");
    const [subIndustry, setSubIndustry] = useState<string>("");
    const [subIndustryOptions, setSubIndustryOptions] = useState<{ value: string; label: string; }[]>([]);
    const [serviceArea, setServiceArea] = useState<string>("");
    const [adoArea, setAdoArea] = useState<string[]>([]);
    const [adoAreaOptions, setAdoAreaOptions] = useState<{ value: string, label: string }[]>([]);
    const [projectBusinessName, setProjectBusinessName] = useState<string>('');
    const [isSubmitted, setIsSubmitted] = useState(false);
    const [error, setError] = useState<string | null>(null);

    // Projects-related state
    const [isProject, setIsProject] = useState<boolean>(false);
    const [category, setCategory] = useState<string>('');
    const [linkedInitiative, setLinkedInitiative] = useState<{id: number, name: string} | null>(null);
    const [initiativeList, setInitiativeList] = useState<{
        value: number;
        label: string;
    }[]>([]);

    const navigate = useNavigate();
    const location = useLocation();
    const currFisYear = calculateFiscalYear(year);
    const { id } = useParams();
    const { initiativeData } = location.state;

    // Find the partner data matching the selected ADO name
    const partner = partnerData.find(partner => partner.name.trim().toLowerCase() === ado.trim().toLowerCase());

    // Create select options for initiatives and industries
    const initiativeTypeOptions = getTypeOptions(typesData, 'initiative_types', initiativeType);
    const industryTypesData = getIndustryTypeOptions(typesData, 'industry_types', industry);
    // Add the "All Industries" option to the beginning of the array
    const industryTypeOptions = [
        { value: null, label: "All Industries" },
        ...industryTypesData
    ];

    // Helper function to find locations by names
    const getLocationsByNames = (names: string[], partnerLocations: PartnerLocation[]): LocationType[] => {
        const locations: LocationType[] = [];
        
        for (const partnerLocation of partnerLocations) {
            for (const location of partnerLocation.locations) {
                if (names.includes(location.name)) {
                    locations.push(location);
                }
            }
        }
        
        return locations;
    };

    const currentInitiatives = isAdmin
    ? initiatives // Show all initiatives if isAdmin is true
    : initiatives.filter(initiative => sanitizeString(initiative.partner_name) === sanitizeString(ado));

    // Map the current initiatives to create an array of options with `id` as `value` and `name` as `label`
    const initiativeOptions = (currentInitiatives || [])
    .filter(initiative => initiative.name && initiative.id)
    .filter(initiative => initiative.category === 'initiative')
    .sort((a, b) => sanitizeString(a.name).localeCompare(sanitizeString(b.name)))
    .map(initiative => ({
        value: initiative.id,
        label: initiative.name,
    }));

    // Set ado options
    useEffect(() => {
        setAdoOptions(adoNames);

        // Capture previous path from location.state (if available)
        const previousPath = location.state?.from;

        // Check if the previous location contains '/commerce-projects'
        if (previousPath && previousPath.includes('/commerce-projects')) {
            setIsProject(true);
            setCategory('project');
        } else {
            setIsProject(false);
            setCategory('initiative');
        }

        if (!isAdmin) {
            setAdo(partnerName);
        }
    }, []);

    // Set initiative list based on current initiative names for selected partner
    useEffect(() => {    
        setInitiativeList(initiativeOptions);
    }, [initiatives, ado]);

    // Set default year to current Fiscal Year on component mount
    useEffect(() => {
        setYear(currFisYear.toString());
    }, [currFisYear]);

    useEffect(() => {
        if (industry) {
            const selectedIndustry = typesData.industry_types.find((type: IndustryType) => type.code === industry);
            const options = getSubIndustryOptions(selectedIndustry);
            setSubIndustryOptions(options);
        } else {
            setSubIndustryOptions([]);
        }
    }, [industry, typesData]);

    useEffect(() => {
        if (partner) {
            const partnerId = partner.id;
            const locations = getPartnerLocations(partnerId, typesData);
            const options = transformLocationsToOptions(locations);
            setAdoAreaOptions(options);
        } else {
            setAdoAreaOptions([]);
        }
    }, [partner, typesData]);

    // Handler for navigating back to Initiatives page
    const navigateBack = () => {
        const currentPath = location.pathname;
    
        // Check if the current path includes '/commerce-initiatives'
        if (currentPath.includes('/commerce-initiatives')) {
            fetchInitiatives();
        } else {
            fetchProjects();
        }

        navigate(-1);
    };

    // Fetch and populate the initiative data when the component mounts
    useDeepCompareEffect(() => {

        const fetchCurrentInitiative = async () => {
            try {
                const response = await fetch(`${import.meta.env.VITE_COMMERCE_BACKEND_URL}/api/getinitiativebyid`, {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json',
                    },
                    body: JSON.stringify({
                        partner_id: initiativeData.partner_id,
                        id: id
                    }),
                });
        
                const responseData = await response.json();
        
                if (!response.ok) {
                    throw new Error(responseData.error || 'Failed to fetch initiative');
                }
        
                console.log('Data successfully fetched from backend:', responseData);

                // Extract and map location names from the response data
                const mappedLocations = responseData.locations.map((location: { id: string; location: string }) => location.id);

                // Determine the service area
                const mappedServiceArea = responseData.all_area ? 'Full ADO Area' : 'Selected Area within ADO';
        
                // Update the state with the fetched data
                setAdo(responseData.partner_name.trim() || '');
                setYear(responseData.year || '');
                setQuarter(responseData.quarter || '');
                setName(responseData.name || '');
                setDescription(responseData.description || '');
                setIsDefault(responseData.is_default);
                setIsAdminManaged(responseData.admin_managed);
                setInitiativeStatus(responseData.status || '');
                setInitiativeType(responseData.initiative_type?.id || '');
                setIndustry(responseData.industry_detail?.industry?.id || null);
                setSubIndustry(responseData.industry_detail?.sub_industry?.id || null);
                setServiceArea(mappedServiceArea);
                setAdoArea(mappedLocations);
                setProjectBusinessName(responseData.project_business_name || '');
                setLinkedInitiative(responseData.linked_initiative || null);
            } catch (error) {
                // Log the caught error to Sentry
                Sentry.captureException(error);
                console.error('Error fetching data from backend:', error);
            }
        };

        fetchCurrentInitiative();
    }, []);

    // Generic change handler for input fields
    const handleInputChange = (setter: React.Dispatch<React.SetStateAction<string>>) => (event: ChangeEvent<HTMLInputElement>) => {
        setter(event.target.value);
    };

    // Toggle handler for the "Default Initiative" switch
    const handleDefaultInitiativeToggle = () => {
        setIsDefault(prevState => {
            const newDefault = !prevState;
            // Also set isAdminManaged to true if newDefault is true
            if (newDefault) {
                setIsAdminManaged(true);
            }
            return newDefault;
        });
    };

    // Toggle handler for the "Admin Managed" switch
    const handleAdminManagedToggle = () => setIsAdminManaged(prevState => !prevState);

    // Change handler for text area fields
    const handleTextAreaChange = (setter: React.Dispatch<React.SetStateAction<string>>) => (event: ChangeEvent<HTMLTextAreaElement>) => {
        setter(event.target.value);
    };

    // Change handler for linked initiative
    const handleLinkedInitiativeChange = (
        setter: React.Dispatch<React.SetStateAction<{id: number, name: string} | null>>
      ) => (selectedOption: any) => {
        if (selectedOption) {
          setter({ id: selectedOption.value, name: selectedOption.label });
        } else {
          setter(null);
        }
    };

    // Change handler factory for react-select components
    const createSelectChangeHandler = (setter: React.Dispatch<React.SetStateAction<string>>) => (selectedOption: any) => {
        setter(selectedOption ? selectedOption.value : '');
    };

    const createMultiSelectChangeHandler = (setter: React.Dispatch<React.SetStateAction<string[]>>) => (selectedOption: any) => {
        const selection = selectedOption ? selectedOption.map((option: any) => option.value) : [];
        setter(selection);
    };

    // Save handler for updating the initiative
    const handleSave = async () => {

        setError(null);
        setIsSubmitted(true);

        if (!partner) {
            return;
        }

        // Map selected values to their corresponding labels
        const mappedServiceArea = serviceAreaOptions.find(area => area.value === serviceArea)?.label;
        
        const selectedAdoAreaNames = adoAreaOptions
            .filter(option => adoArea.includes(option.value))
            .map(option => option.label);

        // Find the matching location objects from the locations object
        const selectedLocations = getLocationsByNames(selectedAdoAreaNames, typesData.partner_locations);

        const transformedLocations = selectedLocations.map(location => ({
            loc_id: location.id,
        }));

        // Find the matching initiative type object
        const matchedInitiativeType = typesData.initiative_types.find(
            (type) => type.id === Number(initiativeType)
        );

        const partnerId = partner.id;
        const allIndustry = industry === null;
        
        // Construct the updated initiative object
        const updatedInitiative = {
            external_id: "",
            partner_id: partnerId,
            year: year,
            quarter: quarter,
            name: name,
            description: description,
            status: initiativeStatus,
            initiative_type_id: matchedInitiativeType?.id ?? null,
            all_industry: allIndustry,
            industry_code: industry,
            sub_industry_code: subIndustry,
            all_area: mappedServiceArea === 'Full ADO Area' ? true : false,
            locations: transformedLocations,
            is_default: isDefault,
            admin_managed: isAdminManaged,
            category: category,
            linked_initiative_id: linkedInitiative?.id ?? null,
            ...(isProject && {
                project_business_name: projectBusinessName,
            })
        };

        if (
            year &&
            quarter &&
            name &&
            description &&
            initiativeStatus &&
            (!isProject || (isProject && initiativeType)) &&
            (serviceArea !== "Selected Area within ADO" || (serviceArea === "Selected Area within ADO" && adoArea && adoArea.length > 0))
        ) {
            try {
                const response = await fetch(`${import.meta.env.VITE_COMMERCE_BACKEND_URL}/api/updateinitiative?partnerId=${partnerId}&initiativeId=${id}&category=${category}`, {
                    method: 'PATCH',
                    headers: {
                        'Content-Type': 'application/json',
                    },
                    body: JSON.stringify(updatedInitiative),
                });
        
                const responseData = await response.json();
        
                if (!response.ok) {
                    throw new Error(responseData.error || 'Failed to update initiative');
                }
        
                console.log('Data successfully sent to backend:', responseData);
                // Return to Initiatives page
                navigateBack();
            } catch (error) {
                // Log the caught error to Sentry
                Sentry.captureException(error);
                console.error('Error sending data to backend:', error);
                setError('There was an issue saving the initiative. Please try again later.');
            }
        }
    };

    // Cancel handler for returning to the previous page
    const handleCancel = () => {
        navigateBack();
    }

    return (
        <>
            <BackButtonHeader navigateBack={navigateBack} name={isProject ? 'Projects' : 'Initiatives'}/>
            <div className="m-2 p-4">
                {/* Initiative / Project Details */}
                <div className="mb-8 border border-solid border-titanium-white rounded-md">
                    <div className='bg-gotham-gray border-b border-solid border-titanium-white mb-5 rounded-t-md'>
                        <h2 className="text-white text-sm font-semibold py-3 ml-7">Edit {isProject ? 'Project' : 'Initiative'}</h2>
                    </div>
                    <div className='grid grid-cols-1 md:grid-cols-2 gap-1 px-3'>
                        {/* ADO Name (Admin Only) */}
                        { isAdmin &&
                            <div className="ff-container">
                                <label htmlFor="selectAdo" className="text-sm mb-2 sm:mb-0 sm:mr-4 font-semibold w-48">
                                    ADO:
                                </label>
                                <div className="relative w-full">
                                    <div className='flex flex-col w-full'>
                                        <Select
                                            id="selectAdo"
                                            value={adoOptions.find(option => option.value === ado)}
                                            onChange={createSelectChangeHandler(setAdo)}
                                            options={adoOptions}
                                            className="w-full"
                                            placeholder="Select ADO"
                                            styles={customStylesXL(isSubmitted, ado)}
                                        />
                                        <p className="text-red-500 text-sm">{`${isSubmitted && !ado ? 'ADO is required.' : ''}`}</p>
                                    </div>
                                </div>
                            </div>
                        }
                        { isAdmin && 
                        <div className="ff-container">
                            <label htmlFor="inputDefaultInitiative" className="mb-2 sm:mb-0 sm:mr-4 lg:mr-10 text-sm font-semibold w-25">
                                Default Initiative:
                            </label>
                            <button
                                id="inputDefaultInitiative"
                                type="button"
                                onClick={handleDefaultInitiativeToggle}
                                className={`w-14 h-7 rounded-full border border-gray-300 relative overflow-hidden transition-all duration-300 ${isDefault ? 'bg-blue-500' : 'bg-gray-300'} focus:ring-2 focus:ring-blue-500 focus:outline-none`}
                            >
                                <span
                                    className={`absolute left-0 top-[.05px] bg-white w-7 h-7 rounded-full transition-all duration-300 transform ${isDefault ? 'translate-x-7' : 'translate-x-0'}`}
                                />
                            </button>
                            
                            <label htmlFor="inputAdminManaged" className="mb-2 sm:mb-0 sm:mr-4 lg:mr-10 mt-4 sm:mt-0 ml-0 sm:ml-16 text-sm font-semibold w-25">
                                Admin Managed:
                            </label>
                            <button
                                id="inputAdminManaged"
                                type="button"
                                onClick={handleAdminManagedToggle}
                                className={`w-14 h-7 rounded-full border border-gray-300 relative overflow-hidden transition-all duration-300 ${isAdminManaged ? 'bg-blue-500' : 'bg-gray-300'} focus:ring-2 focus:ring-blue-500 focus:outline-none`}
                            >
                                <span
                                    className={`absolute left-0 top-[.05px] bg-white w-7 h-7 rounded-full transition-all duration-300 transform ${isAdminManaged ? 'translate-x-7' : 'translate-x-0'}`}
                                />
                            </button>
                        </div>
                        }
                        {/* Initiative / Project Name */}
                        <div className="ff-container">
                            <label htmlFor="inputInitiativeName" className="text-sm mb-2 sm:mb-0 sm:mr-4 font-semibold w-48">
                            {isProject ? 'Project' : 'Initiative'} Name:
                            </label>
                            <div className='flex flex-col w-full'>
                                <input
                                    id="inputInitiativeName"
                                    type="text"
                                    value={name}
                                    placeholder={`Add ${isProject ? 'Project' : 'Initiative'} Name`}
                                    onChange={handleInputChange(setName)}
                                    className={`text-sm pl-4 w-full pr-4 py-2 text-color-cai-dashboard border border-solid ${isSubmitted && !name ? 'border-red-500' : 'border-tableGray'} rounded-[4px] focus:outline-none focus:border-blue-500 h-10 appearance-none`}
                                />
                                <p className="text-red-500 text-sm">{`${isSubmitted && !name ? `${isProject ? 'Project' : 'Initiative'} Name is required.` : ''}`}</p>
                            </div>
                        </div>
                        {/* Select Initiative / Project Type (Project only for now) */}
                        {isProject &&
                        <div className="ff-container">
                            <label htmlFor="selectInitiativeType" className="text-sm mb-2 sm:mb-0 sm:mr-4 font-semibold w-48">
                            {isProject ? 'Project' : 'Initiative'} Type:
                            </label>
                            <div className="relative w-full">
                                <div className='flex flex-col w-full'>
                                    <Select
                                        id="selectType"
                                        value={initiativeTypeOptions.find((option: any) => option.value === initiativeType)}
                                        onChange={createSelectChangeHandler(setInitiativeType)}
                                        options={initiativeTypeOptions}
                                        placeholder="Select Type"
                                        styles={customStyles(isSubmitted, initiativeType)}
                                        isClearable
                                    />
                                    <p className="text-red-500 text-sm">{`${isSubmitted && !initiativeType ? `${isProject ? 'Project' : 'Initiative'} Type is required.` : ''}`}</p>
                                </div>
                            </div>
                        </div>
                        }
                        {/* Status */}
                        <div className="ff-container">
                            <label htmlFor="selectStatus" className="text-sm mb-2 sm:mb-0 sm:mr-4 font-semibold w-48">
                                Status:
                            </label>
                            <div className="relative w-full">
                                <div className='flex flex-col w-full'>
                                    <Select
                                        id="selectStatus"
                                        value={statusOptions.find(option => option.value === initiativeStatus)}
                                        onChange={createSelectChangeHandler(setInitiativeStatus)}
                                        options={statusOptions}
                                        placeholder="Select Status"
                                        styles={customStyles(isSubmitted, initiativeStatus)}
                                        isClearable
                                    />
                                    <p className="text-red-500 text-sm">{`${isSubmitted && !initiativeStatus ? 'Status is required.' : ''}`}</p>
                                </div>
                            </div>
                        </div>
                        {/* Description */}
                        <div className="sm:col-span-2 flex flex-col sm:flex-row items-start sm:items-start my-4 mx-4 sm:mr-20">
                            <label htmlFor="inputDescription" className="text-sm mb-2 sm:mb-0 sm:mr-4 font-semibold w-40">
                                Description:
                            </label>
                            <div className='flex flex-col w-full'>
                                <textarea
                                    id="inputDescription"
                                    value={description}
                                    placeholder='Add Description'
                                    onChange={handleTextAreaChange(setDescription)}
                                    className={`text-sm pl-4 w-full pr-4 py-2 text-color-cai-dashboard border border-solid ${isSubmitted && !description ? 'border-red-500' : 'border-tableGray'} rounded-[4px] focus:outline-none focus:border-blue-500 h-32 appearance-none`}
                                />
                                <p className="text-red-500 text-sm">{`${isSubmitted && !description ? 'Description is required.' : ''}`}</p>
                            </div>
                        </div>

                        {/* Linked Initiative Dropdown (Project Only)*/}
                        {isProject &&
                        <div className="ff-container">
                            <label htmlFor="selectLinkedInitiative" className="text-sm mb-2 sm:mb-0 sm:mr-4 font-semibold w-48">
                                Linked Initiative:
                            </label>
                            <div className="relative w-full">
                                <div className='flex flex-col w-full'>
                                    <Select
                                        id="selectLinkedInitiative"
                                        value={initiativeList.find((option: any) => option.value === linkedInitiative?.id)}
                                        onChange={handleLinkedInitiativeChange(setLinkedInitiative)}
                                        options={initiativeList}
                                        placeholder="Select Linked Initiative"
                                        styles={customStylesXL()}
                                        isClearable
                                    />
                                </div>
                            </div>
                        </div>
                        }
                        {/* Select Year */}
                        <div className="ff-container">
                            <label htmlFor="selectYear" className="text-sm mb-2 sm:mb-0 sm:mr-4 font-semibold w-48">
                                Year:
                            </label>
                            <div className="relative w-full">
                                <div className='flex flex-col w-full'>
                                    <Select
                                        id="selectYear"
                                        value={yearOptions.find(option => option.value === year)}
                                        onChange={createSelectChangeHandler(setYear)}
                                        options={yearOptions}
                                        placeholder="Select Year"
                                        styles={customStyles(isSubmitted, year)}
                                        isClearable
                                    />
                                    <p className="text-red-500 text-sm">{`${isSubmitted && !year ? 'Year is required.' : ''}`}</p>
                                </div>
                            </div>
                        </div>
                        {!isProject && <br></br>}
                        <div className="ff-container">
                            <label htmlFor="selectIndustry" className="text-sm mb-2 sm:mb-0 sm:mr-4 font-semibold w-48">
                                Industry:
                            </label>
                            <div className="relative w-full">
                                <div className='flex flex-col w-full'>
                                    <Select
                                        id="selectType"
                                        value={industryTypeOptions.find((option: any) => option.value === industry)}
                                        onChange={createSelectChangeHandler(setIndustry)}
                                        options={industryTypeOptions}
                                        placeholder="Select Type"
                                        styles={customStylesXL()}
                                        isClearable
                                    />
                                </div>
                            </div>
                        </div>
                        {industry !== null && 
                        <div className="ff-container">
                            <label htmlFor="selectSubIndustry" className="text-sm mb-2 sm:mb-0 sm:mr-4 font-semibold w-48">
                                Sub-Industry:
                            </label>
                            <div className="relative w-full">
                                <div className='flex flex-col w-full'>
                                    <Select
                                        id="selectType"
                                        value={subIndustryOptions.find(option => option.value === subIndustry)}
                                        onChange={createSelectChangeHandler(setSubIndustry)}
                                        options={subIndustryOptions}
                                        placeholder="Select Type"
                                        styles={customStylesXL()}
                                        isClearable
                                    />
                                </div>
                            </div>
                        </div>
                        }
                        {industry === null && <br></br>}
                        <div className="ff-container">
                            <label htmlFor="selectServiceArea" className="text-sm mb-2 sm:mb-0 sm:mr-4 font-semibold w-48">
                                Service Area:
                            </label>
                            <div className="relative w-full">
                                <div className='flex flex-col w-full'>
                                    <Select
                                        id="selectType"
                                        value={serviceAreaOptions.find(option => option.value === serviceArea)}
                                        onChange={createSelectChangeHandler(setServiceArea)}
                                        options={serviceAreaOptions}
                                        placeholder="Select Type"
                                        styles={customStyles()}
                                        isClearable
                                    />
                                </div>
                            </div>
                        </div>
                        {/* Area within ADO */}
                        {serviceArea == 'Selected Area within ADO' ? <div className="ff-container">
                            <label htmlFor="selectWithinADO" className="text-sm mb-2 sm:mb-0 sm:mr-4 font-semibold w-48">
                                Area within ADO:
                            </label>
                            <div className="relative w-full">
                                <div className='flex flex-col w-full'>
                                    <Select
                                        id="selectAdoArea"
                                        value={adoAreaOptions.filter((option: any) => adoArea.includes(option.value))}
                                        onChange={createMultiSelectChangeHandler(setAdoArea)}
                                        options={adoAreaOptions}
                                        placeholder="Select Area"
                                        styles={customStyles()}
                                        isClearable
                                        isMulti
                                    />
                                    <p className="text-red-500 text-sm">{`${isSubmitted && serviceArea == 'Selected Area within ADO' && adoArea.length === 0  ? 'Area is required if service area is selected.' : ''}`}</p>
                                </div>
                            </div>
                        </div> : <br></br>}

                        {/* Business Name if Pog initiative */}
                        {isProject && 
                        <div className="ff-container">
                            <label htmlFor="inputBusinessName" className="text-sm mb-2 sm:mb-0 sm:mr-4 font-semibold w-48">
                                Business or Project Code Name:
                            </label>
                            <input
                                id="inputBusinessName"
                                type="text"
                                value={projectBusinessName}
                                placeholder='Add Business or Project Code Name'
                                onChange={handleInputChange(setProjectBusinessName)}
                                className="text-sm pl-4 w-full pr-4 py-2 border border-solid border-tableGray rounded-[4px] focus:outline-none focus:border-blue-500 h-10 appearance-none"
                            />
                        </div>}
                    </div>
                    <div className='border-t border-solid border-titanium-white mt-5 mb-2'>
                        {/* Display the server error message if it exists */}
                        {error && (
                        <div className="text-red-500 text-sm mt-5 ml-7">
                            {error}
                        </div>
                        )}
                        <button
                            id="save-target"
                            onClick={handleSave}
                            className="ml-7 mt-5 mb-3 w-36 h-9 text-center text-white bg-gotham-gray border border-solid border-gotham-gray rounded-[4px] focus:outline-none focus:border-blue-500  hover:bg-blue-400 hover:border-blue-500"
                        > Save
                        </button>
                        <button
                            id="cancel-target"
                            onClick={handleCancel}
                            className="ml-7 mt-5 mb-3 w-36 h-9 text-center text-gotham-gray bg-white border border-solid border-gotham-gray rounded-[4px] focus:outline-none focus:border-red-500  hover:bg-red-400 hover:border-red-500 hover:text-white"
                        > Cancel
                        </button>
                    </div>
                </div>
            </div>
        </>
    );
};

export default EditInitiative;