import { useLocation, useNavigate } from 'react-router-dom';
import * as Sentry from '@sentry/react';
import Expand from '../../components/Navigation/Expand';
import { useContext, useState } from 'react';
import deleteSvg from '../../assets/delete-white.svg';
import { useIframeContext } from '../../context/IframeContext';
import { InteractionData } from '../../services/types';
import { useOrganizationContext } from '../../context/OrganizationContext';
import InteractionDataContext from '../../context/InteractionDataContext';
import FormErrorModal from '../../components/Modals/FormErrorModal';
import { calculateFiscalYear } from '../../utils/helpers';


function BulkDeleteInteractions() {
    const { isIframe } = useIframeContext();
    const { partnerId } = useOrganizationContext();
    const { fetchInteractions, setInteractionsYear } = useContext(InteractionDataContext);
    const [deleteStatus, setDeleteStatus] = useState('');
    const [showImage, setShowImage] = useState(false);
    const [showContinueButton, setShowContinueButton] = useState(false);

    const [showModal, setShowModal] = useState(false);
    const [formErrorMsg, setFormErrorMsg] = useState('');

    const navigate = useNavigate();
    const location = useLocation();
    const { selectedInteractions } = location.state;

    const currentFisYear = calculateFiscalYear('');


    // Handler for navigating back to Interactions page
    const navigateBack = () => {
        setInteractionsYear(currentFisYear.toString());
        navigate(-1);
    };

    const handleClose = () => {

        fetchInteractions();

        // We need to handle the path for interactions page vs. interaction table on initiatives page
        if (location.pathname !== '/commerce-interactions') {
            navigateBack();
        }
    }

    const handleNext = async () => {
        // Create an array of interaction IDs
        const interactionIds = selectedInteractions.map((interaction: InteractionData) => ({
            id: interaction.id,
        }));
    
        // Create the interactions object
        const interactions = { interactions: interactionIds };
        console.log('interactions payload:', interactions);
    
        const apiUrl = `${import.meta.env.VITE_COMMERCE_BACKEND_URL}/api/bulkdeleteinteractions?partnerId=${partnerId}`;
    
        try {
            // Send a single DELETE request with the interactions object as the body
            const response = await fetch(apiUrl, {
                method: 'DELETE',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(interactions),
            });
    
            if (!response.ok) {
                const responseData = await response.json();
                throw new Error(responseData.error || 'Failed to delete interactions');
            }
    
            console.log('Interactions successfully deleted');
            setDeleteStatus('Interactions deleted successfully');
            setShowImage(true);
            setShowContinueButton(true);
        } catch (error) {
            // Log the caught error to Sentry
            Sentry.captureException(error);
            console.error('Error deleting interactions:', error);
            setFormErrorMsg('There was an issue deleting the interactions. Please try again later.');
            setShowModal(true);
        }
    };
    

    return (
        <div className="m-2 p-4">
            {/* Expand button */}
            {isIframe && 
            <div className='flex justify-end'>
                <div className="flex ml-auto mb-4">
                    <Expand/>
                </div>
            </div>
            }

            {/* Delete Interaction */}
            <div className="mb-8 border border-solid border-color-tableGray rounded-md">
                <div className='border-b border-solid border-titanium-white mb-5 bg-gotham-gray rounded-t-md'>
                    <h2 className="text-white font-semibold py-3 ml-7">Delete Interaction</h2>
                </div>
                <div className='flex ml-4 sm:ml-8 h-20 items-center'>
                    {showImage && 
                        <img src={deleteSvg} alt="Deleted Successfully" className="p-2 mr-4 h-9 w-10 border border-red-500 bg-red-500 rounded-full" />
                    }
                    <p className='font-semibold'>
                        {deleteStatus || 'Are you sure that you want to permanently delete the interactions?'}
                    </p>
                </div>
                <FormErrorModal
                    isOpen={showModal}
                    onClose={() => setShowModal(false)}
                    message={formErrorMsg}
                />
                <div className='border-t border-solid border-color-tableGray mt-5 mb-2'>
                    {!showImage && (
                        <button
                            id="next"
                            onClick={handleNext}
                            className="ml-7 mt-5 mb-3 w-36 h-9 text-center text-white bg-gotham-gray border border-solid border-gotham-gray rounded-md focus:outline-none focus:border-blue-400 hover:bg-blue-400 hover:border-blue-500"
                        >
                            Next
                        </button>
                    )}
                    {!showImage && (
                        <button
                            id="cancel"
                            onClick={navigateBack}
                            className="ml-7 mt-5 mb-3 w-36 h-9 text-center text-gotham-gray bg-white border border-solid border-gotham-gray rounded-md focus:outline-none focus:border-red-500 hover:bg-red-400 hover:border-red-500 hover:text-white"
                        >
                            Cancel
                        </button>
                    )}
                    {showContinueButton && (
                        <button
                            id="close"
                            onClick={handleClose}
                            className="ml-7 mt-5 mb-3 w-36 h-9 text-center text-white bg-gotham-gray border border-solid border-gotham-gray rounded-md focus:outline-none focus:border-blue-400 hover:bg-blue-400 hover:border-blue-500"
                        >
                            Close
                        </button>
                    )}
                </div>
            </div>
        </div>
    );
};

export default BulkDeleteInteractions;