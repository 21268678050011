// Modal props typing
interface ConfirmDeleteModalProps {
    isOpen: boolean;
    onClose: () => void;
    onConfirm: () => void;
    message: string;
}

function ConfirmDeleteModal({ isOpen, onClose, onConfirm, message }: ConfirmDeleteModalProps) {
    if (!isOpen) return null;

    return (
    <div className="fixed inset-0 z-50 flex items-center justify-center overflow-x-hidden overflow-y-auto outline-none focus:outline-none">
      <div className="relative w-auto max-w-md mx-auto my-6">
        {/* Content */}
        <div className="relative flex flex-col w-full bg-white border-0 rounded-lg shadow-lg outline-none focus:outline-none">
          <div className="relative p-6 flex-auto">
            <p className="my-4 text-color-cai-gray text-lg">
              {message}
            </p>
          </div>

          <div className="flex items-center justify-center p-6 border-t border-solid rounded-b border-blueGray-200">
            <button
              className="text-blue-500 bg-transparent border border-solid rounded-md hover:bg-blue-100 hover:text-blue-700 active:bg-blue-200 font-bold uppercase px-6 py-2 text-sm outline-none focus:outline-none mr-1 mb-1"
              type="button"
              onClick={() => {
                onConfirm();
                onClose();
              }}
            >
              Yes
            </button>
            <button
              className="text-red-500 bg-transparent border border-solid rounded-md hover:bg-red-100 hover:text-red-700 active:bg-red-200 font-bold uppercase px-6 py-2 text-sm outline-none focus:outline-none mr-1 mb-1"
              type="button"
              onClick={onClose}
            >
              No
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ConfirmDeleteModal;