import { useContext, useEffect, useState } from 'react';
import Select from 'react-select';
import { useNavigate } from 'react-router-dom';
import InteractionDataContext from '../../context/InteractionDataContext';
import { InteractionData } from '../../services/types';

interface BulkActionBtnProps {
    disabled: boolean; 
    handleBulkAction: () => void; 
    selectedRows: number[];
}

function BulkActionBtn({ disabled, selectedRows }: BulkActionBtnProps) {
    const { interactions } = useContext(InteractionDataContext);
    const navigate = useNavigate();
    const [selectedInteractions, setSelectedInteractions] = useState<InteractionData[]>([]);
    const [showTooltip, setShowTooltip] = useState(false);
    const [menuOpen, setMenuOpen] = useState(false);

    useEffect(() => {
        findSelectedInteractions();
    }, [selectedRows]);

    const findSelectedInteractions = () => {
        const interactionList = interactions.filter(interaction => selectedRows.includes(interaction.id));
        setSelectedInteractions(interactionList);
    };

    const handleSelectChange = (selectedOption: { value: string; label: string } | null) => {
        if (selectedOption) {
            const selectedType = selectedOption.value;
            handleOptionClick(selectedType);
        }
    };

    const handleOptionClick = (selectedType: string) => {
        if (selectedType === 'move') {
            navigate('/commerce-interactions/move', { state: { selectedInteractions } });
        } else if (selectedType === 'delete') {
            navigate('/commerce-interactions/bulkdelete', { state: { selectedInteractions } });
        }
    };

    const options = [
        { value: 'move', label: 'Move' },
        { value: 'delete', label: 'Delete' },
    ];

    const customStyles = {
        control: (base: any) => ({
            ...base,
            background: '#FFFFFF',
            borderColor: '#0A5F75',
            boxShadow: 'none',
            height: '36px',
            minHeight: '36px',
            width: '9rem',
            borderRadius: '7px',
            borderWidth: '2px',
            marginRight: '1rem',
            cursor: disabled ? 'not-allowed' : 'pointer',
            opacity: disabled ? 0.5 : 1,
            '&:hover': {
                borderColor: '#0B82A0',
            },
        }),
        valueContainer: (base: any) => ({
            ...base,
            padding: '0 0.5rem',
            minHeight: '2rem',
            height: '2rem',
        }),
        placeholder: (base: any) => ({
            ...base,
            color: '#0A5F75',
            fontSize: '0.875rem',
            fontWeight: 'bold',
            '&:hover': {
                color: '#0B82A0',
            },
        }),
        singleValue: (base: any) => ({
            ...base,
            color: '#3A3C41',
            fontSize: '0.875rem',
        }),
        dropdownIndicator: (base: any) => ({
            ...base,
            padding: '0',
            color: '#0A5F75' 
        }),
        indicatorsContainer: (base: any) => ({
            ...base,
            padding: '0 0.25rem',
        }),
        option: (base: any, state: any) => ({
            ...base,
            fontSize: '0.875rem',
            backgroundColor: state.isSelected ? '#4D4D4D' : state.isFocused ? '#E2E8F0' : 'white',
            borderRadius: '4px',
            borderBottom: '1px solid #E2E8F0',
            color: state.isSelected ? 'white' : '#4D4D4D',
            '&:hover': {
                backgroundColor: '#E2E8F0',
                color: '#0A5F75'
            },
        }),
        menu: (base: any) => ({
            ...base,
            width: '9rem',
            position: 'absolute',
            left: '0',
            top: '2rem',
            zIndex: 9999,
            boxShadow: '0 8px 16px 0 rgba(0, 0, 0, 0.15)',
        }),
        menuList: (base: any) => ({
            ...base,
            padding: '4px',
            '::-webkit-scrollbar': {
                width: '0.5rem',
            },
            '::-webkit-scrollbar-track': {
                borderRadius: '0.5rem',
            },
            '::-webkit-scrollbar-thumb': {
                background: '#E8E8E8',
                borderRadius: '0.5rem',
                borderWidth: '4px',
                borderColor: 'transparent',
                backgroundClip: 'padding-box',
                height: '3rem',
                minHeight: '3rem',
            },
        }),
    }

    return (
        <div
            className="relative mt-2 sm:mt-0"
            onMouseEnter={() => !menuOpen && setShowTooltip(true)}
            onMouseLeave={() => setShowTooltip(false)}
        >
            {showTooltip && (
                <div className="absolute bottom-8 left-0 mb-2 bg-gotham-gray text-white text-xs px-3 py-2 rounded shadow-lg">
                    Select items below using the checkbox below to move or delete interactions in bulk.
                </div>
            )}
            <Select
                options={options}
                value={null} // Since we don't need to pre-select an option, set to null
                onChange={handleSelectChange}
                placeholder="Bulk Action"
                isSearchable={false}
                isDisabled={disabled}
                styles={customStyles}
                onMenuOpen={() => {
                    setMenuOpen(true);
                    setShowTooltip(false);
                }}
                onMenuClose={() => {
                    setMenuOpen(false);
                }}
            />
        </div>
    );
}

export default BulkActionBtn;
