import { useEffect, useState } from 'react';
import upArrow from '../../assets/sort-arrow.svg';
import { addCommas, generatePageNumbers } from '../../utils/helpers.ts';
import { InitiativeData } from '../../services/types.js';
import useDeepCompareEffect from '../../hooks/useDeepCompareEffect.js';
import { useAdminContext } from '../../context/AdminContext.tsx';

interface SummaryOutcomesTableProps {
    initiativeData: InitiativeData[];
    printView: boolean;
}

/* SummaryOutcomesTable - Renders the outcome table within the Summary view (ADO/Admin) */
function SummaryOutcomesTable({ initiativeData, printView }: SummaryOutcomesTableProps) {
    const { isAdmin } = useAdminContext();
    const [data, setData] = useState<any[]>([]);
    const [sortConfig, setSortConfig] = useState<{ key: string, direction: 'asc' | 'desc' | null }>({ key: '', direction: null });
    const [currentPage, setCurrentPage] = useState(1);
    const rowsPerPage = 10;

    // Flatten data to handle the outcomes separately
    const flattenedData = initiativeData.flatMap((row) => {
        const initiative = initiativeData.find(init => init.id === Number(row.id));
        return row.outcomes?.map((outcome, index) => ({
            ...row,
            initiative,
            outcome,
            rowKey: `${row.id}-${index}` // Unique key for each row
        })) || [];
    });

    useDeepCompareEffect(() => {
        if (Array.isArray(flattenedData)) {
            setData(flattenedData);
            setSortConfig({ key: 'name', direction: 'asc' });
        } else {
            console.error('initiativeData is not an array:', initiativeData);
        }
    }, [initiativeData]);

    const handlePageClick = (page: number) => {
        setCurrentPage(page);
    };

    // Handle sorting
    const handleSortClick = (key: string) => {
        let direction: 'asc' | 'desc' = 'asc';
        if (sortConfig.key === key && sortConfig.direction === 'asc') {
            direction = 'desc';
        }
        setSortConfig({ key, direction });
    };

    // Sort data based on sortConfig
    useEffect(() => {
        if (sortConfig.key) {
            const sortedData = [...flattenedData].sort((a, b) => {
                let aVal, bVal;
                
                if (sortConfig.key === 'initiative_type') {
                    const aInitiativeType = a.initiative_type || {};
                    const bInitiativeType = b.initiative_type || {};
                    aVal = aInitiativeType.display_name || '';
                    bVal = bInitiativeType.display_name || '';
                } else if (sortConfig.key === 'jobs_created') {
                    const aJobsCreated = a.outcome || {};
                    const bJobsCreated = b.outcome || {};
                    aVal = aJobsCreated.jobs_created || 0;
                    bVal = bJobsCreated.jobs_created || 0;
                } else if (sortConfig.key === 'jobs_retained') {
                    const aJobsCreated = a.outcome || {};
                    const bJobsCreated = b.outcome || {};
                    aVal = aJobsCreated.jobs_retained || 0;
                    bVal = bJobsCreated.jobs_retained || 0;
                } else if (sortConfig.key === 'jobs_created_retained_with_above_avg_wages') {
                    const aJobsCreated = a.outcome || {};
                    const bJobsCreated = b.outcome || {};
                    aVal = aJobsCreated.jobs_created_retained_with_above_avg_wages || 0;
                    bVal = bJobsCreated.jobs_created_retained_with_above_avg_wages || 0;
                } else if (sortConfig.key === 'startups_supported') {
                    const aJobsCreated = a.outcome || {};
                    const bJobsCreated = b.outcome || {};
                    aVal = aJobsCreated.startups_supported || 0;
                    bVal = bJobsCreated.startups_supported || 0;
                } else if (sortConfig.key === 'products_exported_dollars_year') {
                    const aJobsCreated = a.outcome || {};
                    const bJobsCreated = b.outcome || {};
                    aVal = aJobsCreated.products_exported_dollars_year || 0;
                    bVal = bJobsCreated.products_exported_dollars_year || 0;
                } else if (sortConfig.key === 'public_investment_dollars') {
                    const aJobsCreated = a.outcome || {};
                    const bJobsCreated = b.outcome || {};
                    aVal = aJobsCreated.public_investment_dollars || 0;
                    bVal = bJobsCreated.public_investment_dollars || 0;
                } else if (sortConfig.key === 'private_investment_dollars') {
                    const aJobsCreated = a.outcome || {};
                    const bJobsCreated = b.outcome || {};
                    aVal = aJobsCreated.private_investment_dollars || 0;
                    bVal = bJobsCreated.private_investment_dollars || 0;
                } else if (sortConfig.key === 'direct_foreign_investment_dollars') {
                    const aJobsCreated = a.outcome || {};
                    const bJobsCreated = b.outcome || {};
                    aVal = aJobsCreated.direct_foreign_investment_dollars || 0;
                    bVal = bJobsCreated.direct_foreign_investment_dollars || 0;
                } else {
                    // Default case for other fields in InteractionData
                    aVal = a[sortConfig.key as keyof InitiativeData];
                    bVal = b[sortConfig.key as keyof InitiativeData];
                }
    
                // Ensure that empty or null values are pushed to the end
                if ((aVal === null || aVal === '') && (bVal === null || bVal === '')) return 0;
                if (aVal === null || aVal === '') return sortConfig.direction === 'asc' ? 1 : -1;
                if (bVal === null || bVal === '') return sortConfig.direction === 'asc' ? -1 : 1;

                // Standard comparison logic
                if (aVal < bVal) return sortConfig.direction === 'asc' ? -1 : 1;
                if (aVal > bVal) return sortConfig.direction === 'asc' ? 1 : -1;
                return 0;
            });
            setData(sortedData);
        }
    }, [sortConfig, initiativeData]);

    // Pagination logic for flattened data
    const totalPages = Math.ceil(data.length / rowsPerPage);
    const pageNumbersToShow = generatePageNumbers(totalPages, currentPage);

    const startIndex = (currentPage - 1) * rowsPerPage;
    const endIndex = Math.min(startIndex + rowsPerPage, data.length);
    const paginatedData = printView ? data : data.slice(startIndex, endIndex);

    return (
        <div>
            <div className="overflow-x-auto border-b border-solid border-color-tableGray rounded-md">
                <table className="w-full table-auto">
                    <thead className="bg-spanish-gray text-white">
                        <tr>
                            <th 
                                className="border-b border-titanium-white text-xs font-bold px-4 py-2 text-left min-w-24 max-w-24"
                                onClick={() => handleSortClick('name')}
                            >
                                <div className='flex items-center hover:cursor-pointer'>
                                Initiative
                                {sortConfig.key === 'name' && (
                                    <img 
                                        src={upArrow} 
                                        alt='sort arrow' 
                                        className={`transform ${sortConfig.direction === 'desc' ? 'rotate-180' : ''}`} 
                                    />
                                )}
                                </div>
                            </th>
                            <th 
                                className="border-b border-titanium-white text-xs font-bold px-4 py-2 text-left min-w-20 max-w-20"
                                onClick={() => handleSortClick('category')}
                            >
                                <div className='flex items-center hover:cursor-pointer'>
                                Type
                                {sortConfig.key === 'category' && (
                                    <img 
                                        src={upArrow} 
                                        alt='sort arrow' 
                                        className={`transform ${sortConfig.direction === 'desc' ? 'rotate-180' : ''}`} 
                                    />
                                )}
                                </div>
                            </th>
                            {isAdmin && <th 
                                className="border-b border-titanium-white text-xs font-bold px-4 py-2 text-left min-w-28 max-w-28"
                                onClick={() => handleSortClick('partner_name')}
                            >
                                <div className='flex items-center hover:cursor-pointer'>
                                ADO
                                {sortConfig.key === 'partner_name' && (
                                    <img 
                                        src={upArrow} 
                                        alt='sort arrow' 
                                        className={`transform ${sortConfig.direction === 'desc' ? 'rotate-180' : ''}`} 
                                    />
                                )}
                                </div>
                            </th> }
                            {isAdmin && <th 
                                className="border-b border-titanium-white text-xs font-bold px-4 py-2 text-left min-w-20 max-w-20"
                                onClick={() => handleSortClick('quarter')}
                            >
                                <div className='flex items-center hover:cursor-pointer'>
                                Quarter
                                {sortConfig.key === 'quarter' && (
                                    <img 
                                        src={upArrow} 
                                        alt='sort arrow' 
                                        className={`transform ${sortConfig.direction === 'desc' ? 'rotate-180' : ''}`} 
                                    />
                                )}
                                </div>
                            </th> }
                            <th 
                                className="border-b border-titanium-white text-xs font-bold px-4 py-2 text-left text-nowrap min-w-28 max-w-28"
                                onClick={() => handleSortClick('jobs_created')}
                            >
                                <div className='flex items-center hover:cursor-pointer'>
                                Jobs created
                                {sortConfig.key === 'jobs_created' && (
                                    <img 
                                        src={upArrow} 
                                        alt='sort arrow' 
                                        className={`transform ${sortConfig.direction === 'desc' ? 'rotate-180' : ''}`} 
                                    />
                                )}
                                </div>
                            </th>
                            <th 
                                className="border-b border-titanium-white text-xs font-bold px-4 py-2 text-end text-nowrap min-w-28 max-w-28"
                                onClick={() => handleSortClick('jobs_retained')}
                            >
                                <div className='flex items-center hover:cursor-pointer'>
                                Jobs retained
                                {sortConfig.key === 'jobs_retained' && (
                                    <img 
                                        src={upArrow} 
                                        alt='sort arrow' 
                                        className={`transform ${sortConfig.direction === 'desc' ? 'rotate-180' : ''}`} 
                                    />
                                )}
                                </div>
                            </th>
                            <th 
                                className="border-b border-titanium-white text-xs font-bold px-4 py-2 text-center min-w-32 max-w-32"
                                onClick={() => handleSortClick('jobs_created_retained_with_above_avg_wages')}
                            >
                                <div className='flex items-center hover:cursor-pointer'>
                                Jobs created/retained with above avg wages
                                {sortConfig.key === 'jobs_created_retained_with_above_avg_wages' && (
                                    <img 
                                        src={upArrow} 
                                        alt='sort arrow' 
                                        className={`transform ${sortConfig.direction === 'desc' ? 'rotate-180' : ''}`} 
                                    />
                                )}
                                </div>
                            </th>
                            <th 
                                className="border-b border-titanium-white text-xs font-bold px-4 py-2 text-center min-w-24 max-w-24"
                                onClick={() => handleSortClick('startups_supported')}
                            >
                                <div className='flex items-center hover:cursor-pointer'>
                                Startup businesses
                                {sortConfig.key === 'startups_supported' && (
                                    <img 
                                        src={upArrow} 
                                        alt='sort arrow' 
                                        className={`transform ${sortConfig.direction === 'desc' ? 'rotate-180' : ''}`} 
                                    />
                                )}
                                </div>
                            </th>
                            <th 
                                className="border-b border-titanium-white text-xs font-bold px-4 py-2 text-center text-nowrap min-w-36 max-w-36"
                                onClick={() => handleSortClick('products_exported_dollars_year')}
                            >
                                <div className='flex items-center hover:cursor-pointer'>
                                {'Exports($/year)'}
                                {sortConfig.key === 'products_exported_dollars_year' && (
                                    <img 
                                        src={upArrow} 
                                        alt='sort arrow' 
                                        className={`transform ${sortConfig.direction === 'desc' ? 'rotate-180' : ''}`} 
                                    />
                                )}
                                </div>
                            </th>
                            <th 
                                className="border-b border-titanium-white text-xs font-bold px-4 py-2 text-center min-w-40 max-w-40"
                                onClick={() => handleSortClick('public_investment_dollars')}
                            >
                                <div className='flex items-center hover:cursor-pointer'>
                                Public investment dollars
                                {sortConfig.key === 'public_investment_dollars' && (
                                    <img 
                                        src={upArrow} 
                                        alt='sort arrow' 
                                        className={`transform ${sortConfig.direction === 'desc' ? 'rotate-180' : ''}`} 
                                    />
                                )}
                                </div>
                            </th>
                            <th 
                                className="border-b border-titanium-white text-xs font-bold px-4 py-2 text-center min-w-40 max-w-40"
                                onClick={() => handleSortClick('private_investment_dollars')}
                            >
                                <div className='flex items-center hover:cursor-pointer'>
                                Private investment dollars
                                {sortConfig.key === 'private_investment_dollars' && (
                                    <img 
                                        src={upArrow} 
                                        alt='sort arrow' 
                                        className={`transform ${sortConfig.direction === 'desc' ? 'rotate-180' : ''}`} 
                                    />
                                )}
                                </div>
                            </th>
                            <th 
                                className="border-b border-titanium-white text-xs font-bold px-4 py-2 text-center min-w-36 max-w-36"
                                onClick={() => handleSortClick('direct_foreign_investment_dollars')}
                            >
                                <div className='flex items-center hover:cursor-pointer'>
                                Direct foreign investment dollars
                                {sortConfig.key === 'direct_foreign_investment_dollars' && (
                                    <img 
                                        src={upArrow} 
                                        alt='sort arrow' 
                                        className={`transform ${sortConfig.direction === 'desc' ? 'rotate-180' : ''}`} 
                                    />
                                )}
                                </div>
                            </th>
                        </tr>
                    </thead>
                    <tbody>
                        {paginatedData.map((row) => {
                            const { initiative, outcome } = row;

                            const productsExported = `$${addCommas(Number(outcome.products_exported_dollars_year || 0).toFixed(0))}`;
                            const publicInvest = `$${addCommas(Number(outcome.public_investment_dollars || 0).toFixed(0))}`;
                            const privateInvest = `$${addCommas(Number(outcome.private_investment_dollars || 0).toFixed(0))}`;
                            const foreignInvest = `$${addCommas(Number(outcome.direct_foreign_investment_dollars || 0).toFixed(0))}`;

                            return (
                                <tr key={row.rowKey} className="bg-white border-b border-solid border-color-tableGray last:border-none h-10">
                                    <td className="px-4 py-2 text-gotham-gray text-sm text-left font-medium">{initiative?.name}</td>
                                    <td className="px-4 py-2 text-gotham-gray text-sm text-left font-medium">
                                        {initiative?.category ? initiative.category.charAt(0).toUpperCase() + initiative.category.slice(1) : ''}
                                    </td>
                                    {isAdmin && <td className="px-4 py-2 text-gotham-gray text-sm text-left font-medium">{initiative?.partner_name}</td>}
                                    {isAdmin && <td className="px-4 py-2 text-gotham-gray text-sm text-left font-medium">{initiative?.quarter}</td>}
                                    <td className="px-4 py-2 text-gotham-gray text-sm text-center font-medium">{outcome.jobs_created}</td>
                                    <td className="px-4 py-2 text-gotham-gray text-sm text-center font-medium">{outcome.jobs_retained}</td>
                                    <td className="px-4 py-2 text-gotham-gray text-sm text-end font-medium">{outcome.jobs_created_retained_with_above_avg_wages}</td>
                                    <td className="px-4 py-2 text-gotham-gray text-sm text-end font-medium">{outcome.startups_supported}</td>
                                    <td className="px-4 py-2  text-gotham-gray text-sm text-end font-medium">{outcome.products_exported_dollars_year ? productsExported : `$0`}</td>
                                    <td className="px-4 py-2  text-gotham-gray text-sm text-end font-medium">{outcome.public_investment_dollars ? publicInvest : `$0`}</td>
                                    <td className="px-4 py-2  text-gotham-gray text-sm text-end font-medium">{outcome.private_investment_dollars ? privateInvest : `$0`}</td>
                                    <td className="px-4 py-2  text-gotham-gray text-sm text-end font-medium">{outcome.direct_foreign_investment_dollars ? foreignInvest : `$0`}</td>
                                </tr>
                            );
                        })}
                    </tbody>
                </table>
            </div>

            {/* Pagination Controls */}
            {pageNumbersToShow.length > 1 &&
                <div className="hidden-on-print flex justify-center mt-4">
                    <div className="border border-solid border-at-white rounded-md flex">
                        <button
                            className="text-gotham-gray font-bold text-xs border-r border-solid border-at-white px-2 py-1"
                            onClick={() => handlePageClick(Math.max(currentPage - 1, 1))}
                            disabled={currentPage === 1}
                        >
                            « Previous
                        </button>
                        {pageNumbersToShow.map((pageNumber, index) => (
                            <button
                                key={index}
                                className={`text-gotham-gray font-bold text-xs border-r border-solid border-at-white px-3 py-1 ${pageNumber === currentPage ? 'bg-titanium-white' : ''}`}
                                onClick={() => handlePageClick(typeof pageNumber === 'number' ? pageNumber : currentPage)}
                            >
                                {pageNumber}
                            </button>
                        ))}
                        <button
                            className="text-gotham-gray font-bold text-xs px-2 py-1"
                            onClick={() => handlePageClick(Math.min(currentPage + 1, totalPages))}
                            disabled={currentPage === totalPages}
                        >
                            Next »
                        </button>
                    </div>
                </div>
            }
        </div>
    );
}

export default SummaryOutcomesTable;