import { useContext, useEffect, useState } from 'react';
import SearchInput from "../../components/Filters/SearchInput";
import ReportingDropdown from "../../components/Navigation/BreDropdown";
import SelectADO from "../../components/Filters/SelectADO";
import FiscalYear from "../../components/Filters/FiscalYear";
import InitiativeTable from "../../components/Initiatives/InitiativeTable";
import Expand from "../../components/Navigation/Expand";
import InitiativeDataContext from "../../context/InitiativeDataContext";
import useFilteredData from "../../hooks/useFilteredData";
import { calculateAvailableYears, calculateFiscalYear } from "../../utils/helpers";
import AddInitiativeButton from '../../components/Initiatives/AddInitiativeButton';
import { useIframeContext } from '../../context/IframeContext';
import { useAdminContext } from '../../context/AdminContext';
import { useOrganizationContext } from '../../context/OrganizationContext';
import NoResultsMessage from '../../components/Filters/NoResultsMessage';
import InteractionDataContext from '../../context/InteractionDataContext';
import HomeButton from '../../components/Navigation/HomeButton';


function Initiatives() {
    const { isAdmin } = useAdminContext();
    const { isIframe } = useIframeContext();
    const { partnerName } = useOrganizationContext();
    const { initiatives, fetchInitiatives } = useContext(InitiativeDataContext);
    const { setInteractionsYear } = useContext(InteractionDataContext);
    const [ado, setAdo] = useState('');
    const [year, setYear] = useState('');
    const [searchTerm, setSearchTerm] = useState<string>('');
    const currFisYear = calculateFiscalYear(year); 
    const availableYears = calculateAvailableYears();
    const { filteredInitiatives } = useFilteredData(undefined, undefined, initiatives, undefined, searchTerm, undefined, year, undefined, ado);

    const filteredData = filteredInitiatives.filter((item) => item.category === 'initiative');

    const filterState = {
        year,
        ado,
    };
    
    // Save the filter state to localStorage when filters change
    const saveFiltersToLocalStorage = () => {
        localStorage.setItem('initiativeFilters', JSON.stringify(filterState));
    };

    // Load filters from localStorage when the component mounts
    useEffect(() => {
        const savedFilters = JSON.parse(localStorage.getItem('initiativeFilters') || '{}');
        isAdmin ? setAdo(savedFilters.ado || '') : setAdo(partnerName);
        setInteractionsYear(savedFilters.year || currFisYear.toString());
        setYear(savedFilters.year || currFisYear.toString());
        setSearchTerm('');
        fetchInitiatives();
    }, []);

    const filterByYear = (year: string) => {
        setYear(year);
        setInteractionsYear(year);
        filterState.year = year;
        saveFiltersToLocalStorage();
    };
    
    const filterByAdo = (partner_id: string) => {
        setAdo(partner_id);
        filterState.ado = partner_id;
        saveFiltersToLocalStorage();
    };
    
    const handleSearch = (searchTerm: string) => {
        setSearchTerm(searchTerm);
    };
    
    const handleRemoveFilters = () => {
        setYear(currFisYear.toString());
        setSearchTerm('');
        filterState.year = currFisYear.toString();
        if (isAdmin) {
            filterState.ado = '';
        }
        saveFiltersToLocalStorage();
    };

    return ( 
        <>
            <ReportingDropdown/>
            <div className="px-4 py-0 sm:py-4 md:py-4 lg:py-4">
                <div className="mx-2 my-2 relative">
                    <div className="flex flex-wrap items-end w-full justify-start">
                        <HomeButton/>
                        {isIframe && 
                        <div className="flex sm:mt-0 mt-1 mb-1 absolute md:relative top-0 right-0 md:top-auto md:right-auto md:ml-auto transform md:transform-none">
                            <Expand/>
                        </div>
                        }
                    </div>
                </div>
                <div className="mx-2 my-4 flex flex-wrap relative">
                    <div className='w-5/6 sm:w-auto'>
                        <SearchInput onSearch={handleSearch} isLarge={true}/>
                    </div>
                    {(isAdmin) && // Render SelectADO only if in admin view
                        <SelectADO filterByAdo={filterByAdo} currAdo={ado} isSummary={false}/>
                    }
                    <FiscalYear filterByYear={filterByYear} availableYears={availableYears} currYear={year}/>
                    <AddInitiativeButton />
                </div>

                { year && filteredData.length > 0 ?
                <div className="mx-2">
                    <InitiativeTable data={filteredInitiatives}/>
                </div> :
                <NoResultsMessage 
                    itemName="initiatives"
                    hasFilters={true}
                    onRemoveFilters={handleRemoveFilters}
                />
                }
            </div>
        </>
    );
}

export default Initiatives;