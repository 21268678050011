import printSvg from '../../assets/print.svg';

function PrintButton({ onClick }: { onClick?: () => void }) {
    return ( 
        <span onClick={onClick} className="hover:cursor-pointer mb-2 mr-12">
            <img src={printSvg} alt="Left arrow" className="w-7 h-7"/>
        </span>
    );
}

export default PrintButton;