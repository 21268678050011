import { useState, useEffect, ChangeEvent, useContext } from 'react';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import * as Sentry from '@sentry/react';
import { calculateAvailableYears } from '../../utils/helpers';
import validator from 'validator';
import Select from 'react-select';
import { customStyles } from '../../utils/customFormStyles';
import FormErrorModal from '../../components/Modals/FormErrorModal';
import OutcomesDataContext from '../../context/OutcomeDataContext';
import InitiativeDataContext from '../../context/InitiativeDataContext';
import BackButtonHeader from '../../components/Navigation/BackButtonHeader';

function EditOutcome() {
    const { outcomes, fetchOutcomes } = useContext(OutcomesDataContext);
    const { fetchInitiatives } = useContext(InitiativeDataContext);
    const [availableYears, setAvailableYears] = useState<string[]>([]);
    const [year, setYear] = useState<string>('');
    const [quarter, setQuarter] = useState<string>('');
    const [narrative, setNarrative] = useState<string>('');
    const [jobsCreated, setJobsCreated] = useState<string>('');
    const [selectedInitiativeId, setSelectedInitiativeId] = useState<string>('');
    const [jobsRetained, setJobsRetained] = useState<string>('');
    const [startupsSupported, setStartupsSupported] = useState<string>('');
    const [publicInvestmentDollars, setPublicInvestmentDollars] = useState<string>('');
    const [privateInvestmentDollars, setPrivateInvestmentDollars] = useState<string>('');
    const [productsExported, setProductsExported] = useState<string>('');
    const [foreignInvestmentDollars, setForeignInvestmentDollars] = useState<string>('');
    const [jobsAboveAvgWages, setJobsAboveAvgWages] = useState<string>('');
    const [isSubmitted, setIsSubmitted] = useState(false);
    const [showModal, setShowModal] = useState(false);
    const [error, setError] = useState<string | null>(null);
    const [formErrorMsg, setFormErrorMsg] = useState('');
    

    const navigate = useNavigate();
    const { id } = useParams();

    const location = useLocation();
    const { data, initiativeId, from } = location.state;

    // Handler for navigating back to Initiatives page
    const navigateBack = () => {
        fetchInitiatives();
        navigate(-1);
    };

    // Fetch ADO options and fill edit forms with current outcome data
    useEffect(() => {
        setAvailableYears(calculateAvailableYears());
        setQuarter(data.quarter);
        setYear(data.year || '');
        setNarrative(data.narrative || '');
        setJobsCreated(data.jobs_created?.toString() || '');
        setSelectedInitiativeId(initiativeId);
        setJobsRetained(data.jobs_retained?.toString() || '');
        setStartupsSupported(data.startups_supported?.toString() || '');
        setPublicInvestmentDollars(data.public_investment_dollars !== null && data.public_investment_dollars !== undefined ? data.public_investment_dollars.toString() : '');
        setPrivateInvestmentDollars(data.private_investment_dollars !== null && data.private_investment_dollars !== undefined ? data.private_investment_dollars.toString() : '');
        setProductsExported(data.products_exported_dollars_year?.toString() || '');
        setForeignInvestmentDollars(data.direct_foreign_investment_dollars?.toString() || '');
        setJobsAboveAvgWages(data.jobs_created_retained_with_above_avg_wages?.toString() || '');
    }, []);

    const yearOptions = availableYears.map(year => ({ value: year, label: year.toString() }));

    const quarterOptions= [
        { value: 'Q1', label: 'Q1' },
        { value: 'Q2', label: 'Q2' },
        { value: 'Q3', label: 'Q3' },
        { value: 'Q4', label: 'Q4' }
    ];

    // Change handlers for form fields
    const handleInputChange = (setter: React.Dispatch<React.SetStateAction<string>>) =>  (event: ChangeEvent<HTMLInputElement>) => setter(event.target.value);

    // Change handler factory for react-select components
    const createSelectChangeHandler = (setter: React.Dispatch<React.SetStateAction<string>> | React.Dispatch<React.SetStateAction<number>>) => (selectedOption: any) => {
        setter(selectedOption ? selectedOption.value : '');
    };

    const handleTextAreaChange = (setter: React.Dispatch<React.SetStateAction<string>>) => (event: ChangeEvent<HTMLTextAreaElement>) => {
        setter(event.target.value);
    };

    const validateForm = () => {
        const validations = [
            { field: jobsCreated, validator: validator.isNumeric, message: 'Please enter a valid number for Jobs Created.' },
            { field: jobsAboveAvgWages, validator: validator.isNumeric, message: 'Please enter a valid number for Jobs created/retained with above avg wages.' },
            { field: jobsRetained, validator: validator.isNumeric, message: 'Please enter a valid number for Jobs Retained.' },
            { field: publicInvestmentDollars, validator: validator.isNumeric, message: 'Please enter a valid number for Public Investment Dollars.' },
            { field: privateInvestmentDollars, validator: validator.isNumeric, message: 'Please enter a valid number for Private Investment Dollars.' },
            { field: foreignInvestmentDollars, validator: validator.isNumeric, message: 'Please enter a valid number for Direct Foreign Investment Dollars.' },
            { field: startupsSupported, validator: validator.isNumeric, message: 'Please enter a valid number for Startups Supported.' },
            { field: productsExported, validator: validator.isNumeric, message: 'Please enter a valid number for Products Exported.' }
        ];
    
        for (let { field, validator, message } of validations) {
            if (field) {
                if (validator && typeof validator === 'function' && !validator(field.toString())) {
                    // alert(message);
                    setFormErrorMsg(message);
                    setShowModal(true);
                    return false;
                }
            }
        }
        // form is valid
        return true; 
    };

    const handleSave = async () => {

        if (!validateForm()) {
            console.log('validation error, missing required form fields');
            return;
        }

        setError(null);

        const selectedYear = year ? year : '';
        const selectedQuarter = quarter ? quarter : '';

         // Filter outcomes by initiative_id and exclude the current outcome
         const initiativeOutcomes = outcomes.filter(outcome => Number(outcome.initiative_id) === Number(id) && outcome.id !== Number(id));

         // Check if an outcome with the same year and quarter already exists
         const existingOutcome = initiativeOutcomes.find(outcome => outcome.quarter === selectedQuarter && outcome.year === selectedYear);
         if (existingOutcome) {
             setFormErrorMsg(`An outcome in ${selectedQuarter} of ${selectedYear} already exists for this initiative.`);
             setShowModal(true);
             return;
         }
    
        const updatedOutcome = {
            external_id: "",
            year: year,
            narrative: narrative,
            jobs_created: Number(jobsCreated),
            initiative_id: selectedInitiativeId,
            jobs_retained: Number(jobsRetained),
            startups_supported: Number(startupsSupported),
            public_investment_dollars: Number(publicInvestmentDollars),
            private_investment_dollars: Number(privateInvestmentDollars),
            products_exported_dollars_year: Number(productsExported),
            direct_foreign_investment_dollars: Number(foreignInvestmentDollars),
            jobs_created_retained_with_above_avg_wages: Number(jobsAboveAvgWages),
            quarter: quarter
        };

        console.log('updated outcome:', updatedOutcome);

        setIsSubmitted(true);

        if (year !== '' && quarter !== '' && jobsAboveAvgWages !== '' && jobsCreated !== '' && jobsRetained !== '' && publicInvestmentDollars !== '' && privateInvestmentDollars !== '' && foreignInvestmentDollars !== '' && startupsSupported !== '' && productsExported !== '') {
            try {
                const response = await fetch(`${import.meta.env.VITE_COMMERCE_BACKEND_URL}/api/updateoutcome?outcomeId=${id}&initiativeId=${initiativeId}`, {
                    method: 'PATCH',
                    headers: {
                        'Content-Type': 'application/json',
                    },
                    body: JSON.stringify(updatedOutcome),
                });
        
                const responseData = await response.json();
        
                if (!response.ok) {
                    throw new Error(responseData.error || 'Failed to add outcome');
                }
        
                console.log('Data successfully sent to backend:', responseData);
                // Fetch updated outcomes and then return to initiative detail dashboard
                fetchOutcomes();
                navigateBack();
            } catch (error) {
                // Log the caught error to Sentry
				Sentry.captureException(error);
                console.error('Error sending data to backend:', error);
                setError('There was an issue saving the outcome. Please try again later.');
            }
        } else {
            setFormErrorMsg(`Missing required fields. Please fill out the remaining fields.`);
            setShowModal(true);
            return;
        }
    };

    const handleCancel = () => {
        // Return to outcomes dashboard
        navigateBack();
    }

    return (
        <>
            <BackButtonHeader navigateBack={navigateBack} name={from.includes('project') ? 'Project' : 'Initiative'}/>
            <div className="m-2 p-4">
                {/* Edit Outcome Details */}
                <div className="mb-8 border border-solid border-color-tableGray rounded-md">
                    <div className='bg-gotham-gray border-b border-solid border-titanium-white mb-5 rounded-t-md'>
                        <h2 className="text-white text-sm font-semibold py-3 ml-7">Edit Outcome Reporting Period</h2>
                    </div>
                    <div className='flex flex-col sm:flex-row gap-1 ml-3'>
                        {/* Select Year */}
                        <div className="ff-container">
                            <label htmlFor="selectYear" className="text-sm mb-2 sm:mb-0 sm:mr-4 lg:mr-4 font-semibold w-56">
                                Year:
                            </label>
                            <div className="relative w-full">
                                <div className='flex flex-col w-full'>
                                    <Select
                                        id="selectYear"
                                        className="w-72"
                                        value={yearOptions.find(option => option.value === year)}
                                        onChange={createSelectChangeHandler(setYear)}
                                        options={yearOptions}
                                        placeholder="Select Year"
                                        styles={customStyles(isSubmitted, year)}
                                        isClearable
                                        isDisabled={true}
                                    />
                                    <p className="text-red-500 text-sm">{`${isSubmitted && !year ? 'Year is required.' : ''}`}</p>
                                </div>
                            </div>
                        </div>

                        {/* Select Quarter */}
                        <div className="ff-container">
                            <label htmlFor="selectQuarter" className="text-sm mb-2 sm:mb-0 sm:mr-4 lg:mr-4 font-semibold w-72">
                                Quarter:
                            </label>
                            <div className="relative w-full">
                                <div className='flex flex-col w-full'>
                                    <Select
                                        id="selectQuarter"
                                        className="w-72"
                                        value={quarterOptions.find(option => option.value === quarter)}
                                        onChange={createSelectChangeHandler(setQuarter)}
                                        options={quarterOptions}
                                        styles={customStyles(isSubmitted, quarter)}
                                        placeholder="Select Quarter"
                                        isClearable={true}
                                    />
                                    <p className="text-red-500 text-sm">{`${isSubmitted && !quarter ? 'Quarter is required.' : ''}`}</p>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className='flex flex-col sm:flex-row gap-1 ml-3'>
                        <div className='flex flex-col sm:flex-row items-start sm:items-start my-4 mx-4 sm:mr-20'>
                            <label htmlFor="inputNarrative" className="text-sm mb-2 sm:mb-0 sm:mr-4 font-semibold w-44">
                                Narrative:
                            </label>
                            <div className='flex flex-col w-full'>
                                <textarea
                                    id="inputNarrative"
                                    value={narrative}
                                    placeholder='Add Narrative'
                                    onChange={handleTextAreaChange(setNarrative)}
                                    className={`text-sm pl-4 w-full md:w-[59.8rem] pr-4 py-2 border border-solid ${isSubmitted && !narrative ? 'border-red-500' : 'border-tableGray'} rounded-[4px] focus:outline-none focus:border-blue-500 h-28 appearance-none`}
                                />
                                <p className="text-red-500 text-sm">{`${isSubmitted && !narrative ? 'Narrative is required.' : ''}`}</p>
                            </div>
                        </div>
                    </div>
                </div>

                {/* Edit Outcome Details */}
                <div className="mb-8 border border-solid border-color-tableGray rounded-md">
                    <div className='bg-gotham-gray border-b border-solid border-titanium-white mb-5 rounded-t-md'>
                        <h2 className="text-white text-sm font-semibold py-3 ml-7">Edit Outcome Details</h2>
                    </div>
                    <div className='flex flex-col sm:flex-row gap-1 ml-3'>
                        {/* Jobs Created */}
                        <div className="ff-container">
                            <label htmlFor="inputJobsCreated" className="text-sm mb-2 sm:mb-0 sm:mr-4 lg:mr-4 font-semibold w-56">
                                Jobs Created:
                            </label>
                            <div className='flex flex-col w-full'>
                                <input
                                    id="inputJobsCreated"
                                    type="text"
                                    value={jobsCreated}
                                    placeholder="Enter jobs created"
                                    onChange={handleInputChange(setJobsCreated)}
                                    className={`text-sm pl-4 w-72 pr-4 py-2 border border-solid ${isSubmitted && !jobsCreated ? 'border-red-500' : 'border-tableGray'} rounded-md focus:outline-none focus:border-blue-500 h-10 appearance-none`}
                                />
                                <p className="text-red-500 text-sm">{`${isSubmitted && !jobsCreated ? 'Jobs Created is required.' : ''}`}</p>
                            </div>
                        </div>

                        {/* Jobs Created/Retained with Above Avg Wages */}
                        <div className="ff-container">
                            <label htmlFor="inputJobsAboveAvgWages" className="text-sm mb-2 sm:mb-0 sm:mr-4 lg:mr-4 font-semibold w-72">
                                Jobs created/retained with above avg wages:
                            </label>
                            <div className='flex flex-col w-full'>
                                <input
                                    id="inputJobsAboveAvgWages"
                                    type="text"
                                    value={jobsAboveAvgWages}
                                    placeholder="Enter jobs created/retained with above avg wages"
                                    onChange={handleInputChange(setJobsAboveAvgWages)}
                                    className={`text-sm pl-4 w-72 pr-4 py-2 border border-solid ${isSubmitted && !jobsAboveAvgWages ? 'border-red-500' : 'border-tableGray'} rounded-md focus:outline-none focus:border-blue-500 h-10 appearance-none`}
                                />
                                <p className="text-red-500 text-sm">{`${isSubmitted && !jobsCreated ? 'Jobs Created is required.' : ''}`}</p>
                            </div>
                        </div>
                    </div>
                    <div className='flex flex-col sm:flex-row gap-1 ml-3'>
                        {/* Jobs Retained */}
                        <div className="ff-container">
                            <label htmlFor="inputJobsRetained" className="text-sm mb-2 sm:mb-0 sm:mr-4 lg:mr-4 font-semibold w-56">
                                Jobs Retained:
                            </label>
                            <div className='flex flex-col w-full'>
                                <input
                                    id="inputJobsRetained"
                                    type="text"
                                    value={jobsRetained}
                                    placeholder="Enter jobs retained"
                                    onChange={handleInputChange(setJobsRetained)}
                                    className={`text-sm pl-4 w-72 pr-4 py-2 border border-solid ${isSubmitted && !jobsRetained ? 'border-red-500' : 'border-tableGray'} rounded-md focus:outline-none focus:border-blue-500 h-10 appearance-none`}
                                />
                                <p className="text-red-500 text-sm">{`${isSubmitted && !jobsRetained ? 'Jobs Retained is required.' : ''}`}</p>
                            </div>
                        </div>

                        {/* Public Investment Dollars */}
                        <div className="ff-container">
                            <label htmlFor="inputPublicInvestmentDollars" className="text-sm mb-2 sm:mb-0 sm:mr-4 lg:mr-4 font-semibold w-72">
                                Public Investment Dollars:
                            </label>
                            <div className='flex flex-col w-full'>
                                <input
                                    id="inputPublicInvestmentDollars"
                                    type="text"
                                    value={publicInvestmentDollars}
                                    placeholder="Enter public investment dollars"
                                    onChange={handleInputChange(setPublicInvestmentDollars)}
                                    className={`text-sm pl-4 w-72 pr-4 py-2 border border-solid ${isSubmitted && !publicInvestmentDollars ? 'border-red-500' : 'border-tableGray'} rounded-md focus:outline-none focus:border-blue-500 h-10 appearance-none`}
                                />
                                <p className="text-red-500 text-sm">{`${isSubmitted && !publicInvestmentDollars ? 'Public Investment Dollars is required.' : ''}`}</p>
                            </div>
                        </div>
                    </div>
                    <div className='flex flex-col sm:flex-row gap-1 ml-3'>
                        {/* Private Investment Dollars */}
                        <div className="ff-container">
                            <label htmlFor="inputPrivateInvestmentDollars" className="text-sm mb-2 sm:mb-0 sm:mr-4 lg:mr-4 font-semibold w-56">
                                Private Investment Dollars:
                            </label>
                            <div className='flex flex-col w-full'>
                                <input
                                    id="inputPrivateInvestmentDollars"
                                    type="text"
                                    value={privateInvestmentDollars}
                                    placeholder="Enter private investment dollars"
                                    onChange={handleInputChange(setPrivateInvestmentDollars)}
                                    className={`text-sm pl-4 w-72 pr-4 py-2 border border-solid ${isSubmitted && !privateInvestmentDollars ? 'border-red-500' : 'border-tableGray'} rounded-md focus:outline-none focus:border-blue-500 h-10 appearance-none`}
                                />
                                <p className="text-red-500 text-sm">{`${isSubmitted && !privateInvestmentDollars ? 'Private Investment Dollars is required.' : ''}`}</p>
                            </div>
                        </div>

                        {/* Direct Foreign Investment Dollars */}
                        <div className="ff-container">
                            <label htmlFor="inputForeignInvestmentDollars" className="text-sm mb-2 sm:mb-0 sm:mr-4 lg:mr-4 font-semibold w-72">
                                Direct Foreign Investment Dollars:
                            </label>
                            <div className='flex flex-col w-full'>
                                <input
                                    id="inputForeignInvestmentDollars"
                                    type="text"
                                    value={foreignInvestmentDollars}
                                    placeholder="Enter direct foreign investment dollars"
                                    onChange={handleInputChange(setForeignInvestmentDollars)}
                                    className={`text-sm pl-4 w-72 pr-4 py-2 border border-solid ${isSubmitted && !foreignInvestmentDollars ? 'border-red-500' : 'border-tableGray'} rounded-md focus:outline-none focus:border-blue-500 h-10 appearance-none`}
                                />
                                <p className="text-red-500 text-sm">{`${isSubmitted && !foreignInvestmentDollars ? 'Direct Foreign Investment Dollars is required.' : ''}`}</p>
                            </div>
                        </div>
                    </div>
                    <div className='flex flex-col sm:flex-row gap-1 ml-3'>
                        {/* Startups Supported */}
                        <div className="ff-container">
                            <label htmlFor="inputStartupsSupported" className="text-sm mb-2 sm:mb-0 sm:mr-4 lg:mr-4 font-semibold w-56">
                                Startups Supported:
                            </label>
                            <div className='flex flex-col w-full'>
                                <input
                                    id="inputStartupsSupported"
                                    type="text"
                                    value={startupsSupported}
                                    placeholder="Enter startups supported"
                                    onChange={handleInputChange(setStartupsSupported)}
                                    className={`text-sm pl-4 w-72 pr-4 py-2 border border-solid ${isSubmitted && !startupsSupported ? 'border-red-500' : 'border-tableGray'} rounded-md focus:outline-none focus:border-blue-500 h-10 appearance-none`}
                                />
                                <p className="text-red-500 text-sm">{`${isSubmitted && !startupsSupported ? 'Startups Supported is required.' : ''}`}</p>
                            </div>
                        </div>

                        {/* Products Exported Dollars Year */}
                        <div className="ff-container">
                            <label htmlFor="inputProductsExported" className="text-sm mb-2 sm:mb-0 sm:mr-4 lg:mr-4 font-semibold w-72">
                                Products Exported Dollars Year:
                            </label>
                            <div className='flex flex-col w-full'>
                                <input
                                    id="inputProductsExported"
                                    type="text"
                                    value={productsExported}
                                    placeholder="Enter products exported dollars year"
                                    onChange={handleInputChange(setProductsExported)}
                                    className={`text-sm pl-4 w-72 pr-4 py-2 border border-solid ${isSubmitted && !productsExported ? 'border-red-500' : 'border-tableGray'} rounded-md focus:outline-none focus:border-blue-500 h-10 appearance-none`}
                                />
                                <p className="text-red-500 text-sm">{`${isSubmitted && !productsExported ? 'Products Exported is required.' : ''}`}</p>
                            </div>
                        </div>
                    </div>
                    <FormErrorModal
                        isOpen={showModal}
                        onClose={() => setShowModal(false)}
                        message={formErrorMsg}
                    />
                    <div className='border-t border-solid border-color-tableGray mt-5 mb-2'>
                        {/* Display the server error message if it exists */}
                        {error && (
                        <div className="text-red-500 text-sm mt-5 ml-7">
                            {error}
                        </div>
                        )}
                        <button
                            id="save-outcome"
                            onClick={handleSave}
                            className="ml-7 mt-5 mb-3 w-36 h-9 text-center text-white bg-gotham-gray border border-solid border-gotham-gray rounded-md focus:outline-none focus:border-blue-500  hover:bg-blue-400 hover:border-blue-500"
                        > Save
                        </button>
                        <button
                            id="cancel-outcome"
                            onClick={handleCancel}
                            className="ml-7 mt-5 mb-3 w-36 h-9 text-center text-gotham-gray bg-white border border-solid border-gotham-gray rounded-md focus:outline-none focus:border-red-500  hover:bg-red-400 hover:border-red-500 hover:text-white"
                        > Cancel
                        </button>
                    </div>
                </div>
            </div>
        </>
    );
};

export default EditOutcome;