import { createContext, useState, useEffect } from 'react';
import axios from 'axios';
import { AdminProviderProps, InitiativeData, ProjectDataContextType } from '../services/types';
import * as Sentry from '@sentry/react';
import { useOrganizationContext } from './OrganizationContext';



const ProjectDataContext = createContext<ProjectDataContextType>({
    projects: [],
    fetchProjects: () => {},
});

export const ProjectDataProvider = ({ children }: AdminProviderProps) => {
    const [projects, setProjects] = useState<InitiativeData[]>([]);
    const { partnerId } = useOrganizationContext();
    const apiUrl = `${import.meta.env.VITE_COMMERCE_BACKEND_URL}/api/getprojects`;

    const fetchProjects = async () => {
        try {
            const response = await axios.get(apiUrl, { params: { partnerId }});
            if (response.status === 200) {
                const data = response.data.data;
                setProjects(data);
            } else {
                // Log the error response to Sentry
                Sentry.captureMessage(`Error fetching Projects: ${response.data.message}`, 'warning');
                console.error('Error fetching Projects:', response.data);
            }
        } catch (error) {
            // Log the caught error to Sentry
            Sentry.captureException(error);
            console.error('Error fetching Projects:', error);
        }
    };

    useEffect(() => {
        fetchProjects();
    }, []);

    return (
        <ProjectDataContext.Provider value={{ projects, fetchProjects }}>
            {children}
        </ProjectDataContext.Provider>
    );
};

export default ProjectDataContext;
