import { useLocation, useNavigate } from 'react-router-dom';
import * as Sentry from '@sentry/react';
import Select from 'react-select';
import Expand from '../../components/Navigation/Expand';
import { useContext, useState } from 'react';
import InitiativeDataContext from '../../context/InitiativeDataContext';
import useDeepCompareEffect from '../../hooks/useDeepCompareEffect';
import moveSvg from '../../assets/move-complete.svg';
import { useIframeContext } from '../../context/IframeContext';
import { customStyles } from '../../utils/customFormStyles';
import { InteractionData } from '../../services/types';
import { useOrganizationContext } from '../../context/OrganizationContext';
import InteractionDataContext from '../../context/InteractionDataContext';
import FormErrorModal from '../../components/Modals/FormErrorModal';
import { calculateFiscalYear } from '../../utils/helpers';


function MoveInteractions() {
    const { isIframe } = useIframeContext();
    const { initiatives } = useContext(InitiativeDataContext);
    const { fetchInteractions, setInteractionsYear } = useContext(InteractionDataContext);
    const { partnerName } = useOrganizationContext();
    const [initiativeList, setInitiativeList] = useState<string[]>([]);
    const [initiative, setInitiative] = useState('');
    const [initiativeId, setInitiativeId] = useState<number | null>(null);
    const [moveStatus, setMoveStatus] = useState('');
    const [showImage, setShowImage] = useState(false);
    const [showContinueButton, setShowContinueButton] = useState(false);

    const [showModal, setShowModal] = useState(false);
    const [formErrorMsg, setFormErrorMsg] = useState('');

    const currentFisYear = calculateFiscalYear('');

    const location = useLocation();
    const { selectedInteractions } = location.state;
    const navigate = useNavigate();

    const currentInitiative = initiatives.find(initiative => initiative.id === Number(selectedInteractions[0].initiative_id));
    const partnerId = Number(currentInitiative?.partner_id);

    const currentInitiatives = initiatives.filter(initiative => initiative.partner_name.trim().toLowerCase() == partnerName.trim().toLowerCase() &&
    initiative.status === 'active');
    console.log('current initiatives:', currentInitiatives);
    const initiativeNames: string[] = currentInitiatives.map(initiative => initiative.name).sort((a, b) => a.localeCompare(b));

    const initiativeOptions = initiativeList.map((initiativeOption) => ({
        value: initiativeOption,
        label: initiativeOption,
    }));

    console.log('selected initiative:', initiativeId);

    useDeepCompareEffect(() => {
        setInitiativeList(initiativeNames);
        console.log('selected Interactions:', selectedInteractions);
    }, initiatives)

    // Handler for navigating back to Interactions page
    const navigateBack = () => {
        navigate(-1);
    };

    const handleClose = () => {
        fetchInteractions();
        setInteractionsYear(currentFisYear.toString());
        navigate('/commerce-interactions');
    }

    const handleNext = async () => {

        if (!initiativeId) {
            console.error('No initiative ID provided for move');
            return;
        }

        const patchRequests = selectedInteractions.map(async (interaction: InteractionData) => {

            const url = `${import.meta.env.VITE_COMMERCE_BACKEND_URL}/api/updateinteraction?initiativeId=${interaction.initiative_id}&replaceInitiativeId=${initiativeId}`;

            console.log('init id:', initiativeId);

            const mappedInteractions = mapInteractionFields(selectedInteractions);
            const payload = { "interactions": mappedInteractions};
            console.log('payload:', payload);
            
            try {
                const response = await fetch(url, {
                    method: 'PATCH',
                    headers: {
                        'Content-Type': 'application/json',
                    },
                    body: JSON.stringify(payload)
                });

                console.log('response:', response);
        
                if (!response.ok) {
                    const responseData = await response.json();
                    throw new Error(responseData.error || 'Failed to move initiative');
                }
    
                console.log(`Interaction with ID ${interaction.id} successfully moved`);
                return true;
            } catch (error) {
                // Log the caught error to Sentry
				Sentry.captureException(error);
                console.error(`Error moving interaction with ID ${interaction.id}:`, error);
                setFormErrorMsg('There was an issue moving the interactions. Please try again later.');
                setShowModal(true);
                return false;
            }
        });
    
        // Execute all PATCH requests concurrently
        try {
            const results = await Promise.all(patchRequests);
            
            if (results.every(result => result)) {
                setMoveStatus('Interactions moved successfully');
                setShowImage(true);
                setShowContinueButton(true);
            } else {
                throw new Error('Not all interactions could be moved successfully');
            }
        } catch (error) {
            console.error('Error moving interactions:', error);
            setFormErrorMsg('There was an issue moving the interactions. Please try again later.');
            setShowModal(true);
        }
    };

    const handleInitiativeSelectChange = (selectedOption: any) => {
        const selectedInitiative = selectedOption ? selectedOption.value : '';
        setInitiative(selectedInitiative);
    
        // Find the initiative object that matches both the selected name and the partner id
        const selectedInitiativeObject = initiatives.find(initiative => 
            initiative.name === selectedInitiative && 
            Number(initiative.partner_id) === partnerId
        );
    
        // If found, update the initiative_id in newInteraction
        if (selectedInitiativeObject) {
            const initId = selectedInitiativeObject.id;
            setInitiativeId(initId);
        }
    };

    function mapInteractionFields(interactions: InteractionData[]): any[] {
        return interactions.map(({ interaction_type, contact_type, ...rest }) => {
            return {
                ...rest,
                interaction_type_id: interaction_type?.id || null,
                contact_type_id: contact_type?.id || null,
                assistance_types: rest.assistance_types.map(assistance => ({ id: assistance.id })),
                classification_types: rest.classification_types.map(classification => ({ id: classification.id })),
            };
        });
    }
    

    return (
        <div className="m-2 p-4">
            {/* Expand button */}
            {isIframe && 
                <div className='flex justify-end'>
                    <div className="flex ml-auto mb-4">
                        <Expand/>
                    </div>
                </div> 
            }

            {/* Move Interactions */}
            <div className="mb-8 border border-solid border-color-tableGray rounded-md">
                <div className='border-b border-solid border-titanium-white mb-5 bg-gotham-gray rounded-t-md'>
                    <h2 className="text-white font-semibold py-3 ml-7">Move Interactions</h2>
                </div>
                <div className='flex ml-4 sm:ml-8 h-20 items-center'>
                    {showImage && 
                        <img src={moveSvg} alt="Deleted Successfully" className="p-2 mr-4 h-10 w-10 border border-[#1FAB1F] bg-[#1FAB1F] rounded-full" />
                    }
                    <p className='font-semibold'>
                        {moveStatus || 'Are you sure that you want to move the interaction(s) to a different initiative?'}
                    </p>
                </div>
                {!showContinueButton && 
                    <div className="flex flex-col sm:flex-row items-start sm:items-center my-2 mx-8 sm:mr-20">
                        <label htmlFor="selectInitiative" className="mb-2 sm:mb-0 font-semibold w-48">
                            Select Initiative:*
                        </label>
                        <div className="relative w-72">
                            <Select
                                id="selectInitiative"
                                value={initiativeOptions.find(option => option.value === initiative)}
                                onChange={handleInitiativeSelectChange}
                                options={initiativeOptions}
                                placeholder="Select Initiative"
                                styles={customStyles()}
                                isClearable
                            />
                        </div>
                    </div>
                }
                <FormErrorModal
                    isOpen={showModal}
                    onClose={() => setShowModal(false)}
                    message={formErrorMsg}
                />
                <div className='border-t border-solid border-color-tableGray mt-5 mb-2'>
                    {!showImage && (
                        <button
                            id="next"
                            onClick={handleNext}
                            className="ml-7 mt-5 mb-3 w-36 h-9 text-center text-white bg-gotham-gray border border-solid border-gotham-gray rounded-md focus:outline-none focus:border-blue-400 hover:bg-blue-400 hover:border-blue-500"
                        >
                            Next
                        </button>
                    )}
                    {!showImage && (
                        <button
                            id="cancel"
                            onClick={navigateBack}
                            className="ml-7 mt-5 mb-3 w-36 h-9 text-center text-gotham-gray bg-white border border-solid border-gotham-gray rounded-md focus:outline-none focus:border-red-500 hover:bg-red-400 hover:border-red-500 hover:text-white"
                        >
                            Cancel
                        </button>
                    )}
                    {showContinueButton && (
                        <button
                            id="close"
                            onClick={handleClose}
                            className="ml-7 mt-5 mb-3 w-36 h-9 text-center text-white bg-gotham-gray border border-solid border-gotham-gray rounded-md focus:outline-none focus:border-blue-400 hover:bg-blue-400 hover:border-blue-500"
                        >
                            Close
                        </button>
                    )}
                </div>
            </div>
        </div>
    );
};

export default MoveInteractions;