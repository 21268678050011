import { useEffect, useState } from "react";
import { calculateAvailableYears, calculateFiscalYear } from "../../utils/helpers";
import ReportingDropdown from "../../components/Navigation/BreDropdown";
import SelectADO from "../../components/Filters/SelectADO";
import FiscalYear from "../../components/Filters/FiscalYear";
import Expand from "../../components/Navigation/Expand";
import { useAdminContext } from "../../context/AdminContext";
import { useIframeContext } from "../../context/IframeContext";
import { useOrganizationContext } from "../../context/OrganizationContext";

function Surveys() {
    const { isAdmin } = useAdminContext();
    const { isIframe } = useIframeContext();
    const { partnerName } = useOrganizationContext();

    const [ado, setAdo] = useState<string>('');
    const [year, setYear] = useState<string>('');    

    const availableYears = calculateAvailableYears();
    const currFisYear = calculateFiscalYear(year); 

    useEffect(() => {
        setYear(currFisYear.toString());
    }, []);

    useEffect(() => {
        if (!isAdmin) {
            setAdo(partnerName); 
        }
    }, [isAdmin, partnerName]);

    const filterByYear = (year: string) => setYear(year);
    const filterByAdo = (partner_id: string) => setAdo(partner_id);

    return ( 
        <div className="m-2 p-2">
            <ReportingDropdown/>
            <div className="mx-2 my-4 flex flex-wrap">
                {(isAdmin) && <SelectADO currAdo={ado} filterByAdo={filterByAdo} isSummary={true}/>}
                <FiscalYear filterByYear={filterByYear} availableYears={availableYears} currYear={year}/>
                {isIframe && 
                <div className="flex ml-auto mb-1">
                    <Expand/>
                </div>
                }
            </div>
        </div>
    );
}

export default Surveys;