import { Link } from 'react-router-dom';
import UploadInteractionsBtn from '../Interactions/UploadInteractionsBtn';
import { useOrganizationContext } from '../../context/OrganizationContext';
import { useFetchDefaultInitiative } from '../../hooks/useFetchDefaultInitiative';

interface CardProps {
    header: string;
    text: string;
    svgUrl: string;
    linkTo: string;
    height: string;
}

function LandingCard({ header, text, svgUrl, linkTo, height }: CardProps) {
    const { partnerId } = useOrganizationContext();
    const { defaultInitiative } = useFetchDefaultInitiative(partnerId);

    const [initiativeData] = defaultInitiative; // Destructure the default initiative (first element)
    const initiativeId = initiativeData?.id;
    const initiative = initiativeData?.name;

    return (
        <Link to={linkTo}>
            <div className={`bg-white border border-solid border-titanium-white rounded shadow-sm flex flex-col sm:max-w-80 sm:min-w-80 min-w-[368px] ${height} text-left`}>
                <div className="flex pb-3 items-center bg-at-white p-4">
                    <img src={svgUrl} alt={`${header} icon`} className="w-10 h-10 text-blue-500" />
                    <h3 className="text-gotham-gray text-xl font-semibold ml-1">{header}</h3>
                    {header === 'Interactions' && partnerId && initiativeData && (
                        <div
                            className='flex items-center justify-center ml-auto sm:ml-4'
                            onClick={(e) => {
                                e.preventDefault(); // Prevent the link navigation
                            }}
                        >
                            <UploadInteractionsBtn id={initiativeId} initiative={initiative} initiativeData={initiativeData} isDefault={true} />
                        </div>
                    )}
                </div>
                <hr></hr>
                <p className="text-gotham-gray text-sm w-full px-4 mt-2">{text}</p>
            </div>
        </Link>
    );
}

export default LandingCard;