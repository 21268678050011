// Create a context to manage isAdmin state - This is the view for Commerce Admin (All ADO's)
import { createContext, useContext, useEffect, useState } from 'react';
import { AdminContextType, AdminProviderProps } from '../services/types';
import { useOrganizationContext } from './OrganizationContext';

const defaultAdminContext: AdminContextType = {
    isAdmin: false,
    setIsAdmin: () => {}
};

const AdminContext = createContext<AdminContextType>(defaultAdminContext);

export const AdminProvider = ({ children }: AdminProviderProps) => {
    const [isAdmin, setIsAdmin] = useState(false);
    const { partnerId } = useOrganizationContext();

    useEffect(() => {
        // Update isAdmin based on the partnerId
        if (partnerId === -1) {
            setIsAdmin(true);
        } else {
            setIsAdmin(false);
        }
    }, [partnerId]);

    return (
        <AdminContext.Provider value={{ isAdmin, setIsAdmin }}>
            {children}
        </AdminContext.Provider>
    );
};

export const useAdminContext = () => useContext(AdminContext);
