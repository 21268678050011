import { useState, useEffect, useContext } from 'react';
import Select from 'react-select';
import { getTypeOptions } from '../../utils/formOptions';
import TypesContext from '../../context/TypesContext';

interface AssistanceTypeProps {
    filterByAssistanceType: (assistanceType: string) => void;
    currAssistanceType: string;
}

function AssistanceType({ filterByAssistanceType, currAssistanceType }: AssistanceTypeProps) {
    const { typesData } = useContext(TypesContext);
    const [assistance, setAssistance] = useState(currAssistanceType || '');
    const assistanceTypeOptions = getTypeOptions(typesData, 'assistance_types', assistance);
    
    useEffect(() => {
        setAssistance(currAssistanceType);
    }, [currAssistanceType]);

    const handleSelectChange = (selectedOption: { value: string; label: string } | null) => {
        const selectedAssistanceType = selectedOption ? selectedOption.value : '';
        setAssistance(selectedAssistanceType);
        filterByAssistanceType(selectedAssistanceType);
    };

    return ( 
        <div className="mt-2 xl:mt-0">
            <Select
                options={assistanceTypeOptions}
                value={assistanceTypeOptions.find((option: any) => option.value === assistance)}
                onChange={handleSelectChange}
                placeholder="Assistance Type"
                isClearable
                styles={{
                    control: (base) => ({
                        ...base,
                        height: '36px',
                        minHeight: '36px',
                        width: '10.5rem',
                        borderColor: '#0A5F75',
                        borderRadius: '7px',
                        borderWidth: '2px',
                        boxShadow: 'none',
                        marginRight: '1rem',
                        cursor: 'pointer',
                        '&:hover': {
                            borderColor: '#0B82A0',
                        },
                    }),
                    valueContainer: (base) => ({
                        ...base,
                        padding: '0 0.5rem',
                        minHeight: '2rem',
                        height: '2rem',
                    }),
                    placeholder: (base) => ({
                        ...base,
                        color: '#0A5F75',
                        fontSize: '0.875rem',
                        fontWeight: 'bold',
                    }),
                    singleValue: (base) => ({
                        ...base,
                        color: '#0A5F75',
                        fontSize: '0.875rem',
                    }),
                    dropdownIndicator: (base) => ({
                        ...base,
                        color: '#0A5F75',
                        padding: '0',
                    }),
                    clearIndicator: (base) => ({
                        ...base,
                        padding: '0',
                    }),
                    indicatorsContainer: (base) => ({
                        ...base,
                        padding: '0 0.25rem',
                    }),
                    option: (base, state) => ({
                        ...base,
                        fontSize: '0.875rem',
                        backgroundColor: state.isSelected ? '#4D4D4D' : state.isFocused ? '#E2E8F0' : 'white',
                        borderRadius: '4px',
                        borderBottom: '1px solid #E2E8F0',
                        color: state.isSelected ? 'white' : '#4D4D4D',
                        '&:hover': {
                            backgroundColor: '#E2E8F0',
                            color: '#0A5F75'
                        },
                    }),
                    menu: (base) => ({
                        ...base,
                        width: '15rem',
                        position: 'absolute',
                        left: '0',
                        top: '2rem',
                        zIndex: 9999,
                        boxShadow: '0 8px 16px 0 rgba(0, 0, 0, 0.15)',
                    }),
                    menuList: (base) => ({
                        ...base,
                        padding: '4px',
                        maxHeight: '25rem', // Adjust height as needed
                        overflowY: 'auto',
                        '::-webkit-scrollbar': {
                            width: '8px',
                        },
                        '::-webkit-scrollbar-track': {
                            background: '#ffffff',
                            borderRadius: '4px',
                        },
                        '::-webkit-scrollbar-thumb': {
                            background: '#E8E8E8',
                            borderRadius: '4px',
                        },
                    }),
                }}
            />
        </div>
    );
}

export default AssistanceType;