import { ChangeEvent, useState } from 'react';
import searchIcon from '../../assets/search-icon.svg';

interface SearchInputProps {
  onSearch: (searchTerm: string) => void;
  isLarge: boolean;
}

function SearchInput({ onSearch, isLarge }: SearchInputProps) {
  const [searchTerm, setSearchTerm] = useState('');

  const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
    const searchTerm = event.target.value;
    setSearchTerm(searchTerm);
    onSearch(searchTerm);
  };

  let inputClass = `w-full text-gotham-gray pl-14 pr-4 py-2 border border-solid rounded-[7px] focus:outline-none ${ isLarge ? 'h-[36px] text-sm border-button-blue border-2 focus:border-color-cai-blue' : 'h-8 text-xs border-titanium-white focus:border-blue-500'}`;

  return (
    <div className="flex items-center w-full lg:w-96 mr-5">
      <div className="relative w-full">
        <input
          type="text"
          value={searchTerm}
          onChange={handleChange}
          className={inputClass}
          placeholder="Search"
        />
        <span className={`absolute inset-y-0 left-0 pl-3 pr-3 flex items-center text-gray-400 border-r border-solid ${isLarge ? 'border-button-blue border-r-0' : 'border-titanium-white'}`}>
          <img src={searchIcon} alt="Search Icon" className="h-4 w-4" />
        </span>
      </div>
    </div>
  );
}

export default SearchInput;